/* UIQ Footer Base Styles */
.uiq-footer {
    margin-top: 1rem;
    background-color: #18665c;
    color: #ffffff;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
}

.uiq-footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    /* Ensures content does not stretch too wide */
    margin-bottom: 20px;
    /* Space between content and copyright */
}

.uiq-footer-section {
    flex: 1;
    min-width: 200px;
    /* Ensures sections are not too narrow */
    margin: 10px;
    /* text-align: center; */
    text-align: start;
    /* Centers text within sections */
}

.uiq-footer-logo {
    max-width: 150px;
}

.uiq-footer-menu,
.uiq-footer-links {
    list-style: none;
    padding: 0;
}

.uiq-footer-menu li,
.uiq-footer-links li {
    margin: 5px 0;
}

.uiq-footer-menu a,
.uiq-footer-links a {
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
}

.uiq-footer-menu a:hover,
.uiq-footer-links a:hover {
    text-decoration: underline;
}

.uiq-footer-social-media-section {
    margin-top: 10px;
}

.uiq-social-icon {
    color: #ffffff;
    font-size: 20px;
    margin: 0 10px;
    text-decoration: none;
}

.uiq-social-icon:hover {
    color: #e07b12;
    /* Hover color */
}

/* Footer Copyright Section */
.uiq-footer-copyright {
    background-color: #18665c;
    color: #ffffff;
    padding: 10px 0;
    text-align: center;
    width: 100%;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .uiq-footer-content {
        flex-direction: column;
        align-items: center;
        /* Center-aligns items on smaller screens */
    }

    .uiq-footer-section {
        margin: 10px 0;
        text-align: center;
        /* Ensures text is centered on small screens */
    }
}

.uiq-footer-section-title{
    color: #e07b12;
}

.uiq-footer-contact a{
    color: #e07b12;
    text-decoration: none;
}
.uiq-footer-contact a:hover{
    opacity: 0.8;
    text-decoration: underline;
}
.uiq-footer-contact{
    margin-top: 8px;
}
.uiq-footer-info{
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
    width: 100%;
    /* padding: 5px; */
}
.uiq-footer-info a{
    color: inherit;
    text-decoration: none;
}
.copyrightStyle{
    text-align: center;
}
.copyrightStyleLink{
    text-align: end;
}