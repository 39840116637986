@charset "utf-8";
/* CSS Document */

/* @import url('owl.carousel.css'); 
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Alegreya+SC:400,500,700,800,900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'); */


body {
    margin: 0;
    padding: 0;
    /* font-family: 'Poppins', sans-serif !important; */
    font-size: 14px;
    color: #3a3a3a;
    font-weight: 400;
    position: relative;
}

* {
    margin: 0px;
    padding: 0px;
}

.clearfix {
    zoom: 1;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
    line-height: 0;
}

.clearfix:after {
    clear: both;
}

.clr,
.clear {
    clear: both;
}

header,
footer,
aside,
article,
section,
detail,
nav,
figure {
    position: relative;

}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

ul,
li,
ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    outline: none !important;
}

a:hover,
a:focus {
    text-decoration: none !important;
    outline: none;
}

figure {
    margin: 0;
}

img {
    border: 0;
    outline: none;
    max-width: 100%;
}

input[type="text"],
input[type="password"],
select,
textarea,
input[type="button"] {
    max-width: 100%;
}

table {
    width: 100%;
}

p {
    margin: 0;
    padding: 0;
}

.fltL {
    float: left;
}

.fltR {
    float: right !important;
}

.padding_none {
    padding: 0 !important;
}

.border_none {
    border: none !important
}

.navbar-toggle {
    background-color: #fff !important;
}

.icon-bar {
    background-color: #000 !important;
}

.p_right0 {
    padding-right: 0px !important
}

.p_left0 {
    padding-left: 0px !important
}

.p_top50 {
    padding-top: 50px;
}

.p_bottom50 {
    padding-bottom: 50px;
}

.container {
    max-width: 1170px;
}

.TitleBox {
    margin: 0 0 50px;
    /* font-family: 'Alegreya SC', serif; */
}

.TitleBox h2 {
    position: relative;
    margin: 0;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    color: #464646;
    font-size: 40px;
    letter-spacing: .5px;
    line-height: 35px
}

.TitleBox h2 span {
    color: #20BFA9;
}

.TitleBox p {
    font-weight: 400;
    font-size: 16px;
    color: #444;
    line-height: 30px;
    text-align: center;
    margin-top: 15px;
    /* font-family: 'Roboto'; */
}

.Header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 15;
    padding: 10px 0
}

.Header .navbar {
    border: none;
    margin: 0;
    border-radius: 0;
    padding: 0
}

.Header .navbar .navbar-brand {
    margin: 6px 0 !important;
    width: 80px;
    height: auto;
    padding: 0
}

.Header .navbar .navbar-brand img {
    width: 100%
}

.Header .navbar-dark .navbar-toggler {
    outline: 0;
    z-index: 11;
    background-color: #fff;
    padding: 0;
    position: relative;
    width: 50px;
    height: 40px
}

.Header .navbar-dark .navbar-toggler .icon {
    display: block;
    width: 25px;
    height: 2px;
    border-radius: 1px;
    background-color: #000;
    margin: auto
}

.Header .navbar-dark .navbar-toggler .icon+.icon {
    margin-top: 5px
}

.Header .navbar-dark .navbar-toggler .icon:nth-child(1) {
    position: absolute;
    top: 18.5px;
    transform: rotate(45deg);
    left: 11px
}

.Header .navbar-dark .navbar-toggler .icon:nth-child(2) {
    display: none
}

.Header .navbar-dark .navbar-toggler .icon:nth-child(3) {
    position: absolute;
    top: 18.5px;
    transform: rotate(-45deg);
    left: 11px;
    margin: 0
}

.Header .navbar-dark .navbar-toggler.collapsed .icon {
    position: relative;
    top: 0;
    left: 0;
    transform: inherit;
    margin: auto;
    display: block
}

.Header .navbar-dark .navbar-toggler.collapsed .icon+.icon {
    margin-top: 5px
}

.Header .navbar .navbar-collapse {
    justify-content: flex-end
}

.Header .navbar .navbar-collapse .MobileLogo {
    display: none
}

.Header .navbar .navbar-right {
    margin: 0 0
}

.Header .navbar .navbar-nav li {
    margin: 0 0 0 20px;
    display: inline-block
}

.Header .navbar .navbar-nav li a {
    color: #000;
    font-size: 20px;
    padding: 10px 0;
    background-color: transparent;
    /* font-family: 'Alegreya SC', serif; */
    display: block;
    line-height: 20px;
    position: relative
}

.Header .navbar .navbar-nav li:nth-last-child(-n+2) a {
    color: #000;
    transition: 0.5s all ease-in-out;
    border: 2px solid #000;
    border-radius: 50px;
    padding: 8px 23px;
    font-size: 18px
}

.Header .navbar .navbar-nav li:nth-last-child(-n+2) a:hover {
    background-color: #000;
    color: #fff
}

.Header .navbar .navbar-nav li.dropdown {
    position: static
}

.Header .navbar .navbar-nav li.dropdown a {
    padding: 10px 15px 10px 0
}

.Header .navbar .navbar-nav li.dropdown a:before {
    content: '\f107';
    /* font-family: FontAwesome; */
    color: #000;
    position: absolute;
    top: 11px;
    right: 0;
    font-size: 15px
}

.Header .navbar .navbar-nav li.dropdown .MegaMenu {
    position: absolute;
    top: 45px;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 15px 15px 0 15px;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    width: 85%;
    left: 0;
    right: 0;
    margin: auto
}

.Header .navbar .navbar-nav li.dropdown:hover .MegaMenu {
    display: block
}

.Header .navbar .navbar-nav li.dropdown .MegaMenu .MegaMenuBox {
    float: left;
    width: 25%;
    margin: 0 0 15px 0
}

.Header .navbar .navbar-nav li.dropdown .MegaMenu .MegaMenuBox h4 {
    color: #26a9e1;
    /* font-family: 'Alegreya SC', serif; */
    font-size: 18px;
    margin: 0 0 4px
}

.Header .navbar .navbar-nav li.dropdown .MegaMenu .MegaMenuBox ol li {
    margin: 0 0 2px 0;
    display: block
}

.Header .navbar .navbar-nav li.dropdown .MegaMenu .MegaMenuBox ol li a {
    color: #000;
    padding: 0;
    font-size: 14px;
    line-height: 22px;
    border: none;
    background: transparent;
    transition: 0.4s all ease-in-out;
    display: inherit
}

.Header .navbar .navbar-nav li.dropdown .MegaMenu .MegaMenuBox ol li a:before {
    content: none
}

.Header .navbar .navbar-nav li.dropdown .MegaMenu .MegaMenuBox ol li a:hover {
    color: #26a9e1
}

.lightMode .navbar .navbar-nav li a {
    color: #fff
}

.lightMode .navbar .navbar-nav li.dropdown a:before {
    color: #fff
}

.lightMode .navbar .navbar-nav li:nth-last-child(-n+2) a {
    border-color: #fff;
    color: #fff
}

.lightMode .navbar .navbar-nav li:nth-last-child(-n+2) a:hover {
    background-color: #fff;
    color: #000
}

.Fixed {
    animation: 0.7s ease 0s normal none 1 running;
    position: fixed;
    transition: all 0.5s ease-in-out;
    background: #fff;
    top: 0;
    padding: 0 0;
    box-shadow: 0 1px 8px #ddd;
    z-index: 99
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%)
    }

    100% {
        opacity: .9;
        transform: translateY(0)
    }
}

.Fixed .navbar .navbar-brand img.Main-logo {
    display: block
}

.Fixed .navbar .navbar-brand img.Web-logo {
    display: none
}

.Fixed .navbar ul.navbar-nav {
    margin: 10px 0 10px 40px
}

.Fixed .navbar ul.navbar-right {
    margin: 6px 0
}

.Fixed .navbar ul.navbar-right li a {
    color: #000;
    font-size: 16px
}

.Fixed .navbar .navbar-nav li.dropdown a {
    padding: 10px 15px 10px 0
}

.Fixed .navbar .navbar-nav li.dropdown a:before {
    top: 11px;
    color: #000
}

.Fixed .navbar .navbar-nav li:nth-last-child(-n+2) a {
    color: #000;
    border: 2px solid #000;
    padding: 5px 20px;
    font-size: 15px;
    margin: 5px 0
}

.Fixed .navbar .navbar-nav li:nth-last-child(-n+2) a:hover {
    background-color: #000;
    color: #fff
}

.SliderArea {
    background-image: url(https://mobulous.s3.ap-south-1.amazonaws.com/Web/images/main/Slide.webp);
    min-height: 100vh;
    position: relative;
    background-size: cover;
    background-position: center
}

.SliderArea:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5)
}

.SliderArea video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover
}

.SliderText {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    padding: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 40%, rgba(0, 0, 0, .64) 100%)
}

.SliderText aside {
    position: absolute;
    width: 100%;
    top: 90px
}

.SliderText aside h1 {
    font-size: 30px;
    margin: 0 0 6px;
    /* font-family: 'Alegreya SC', serif */
}

.SliderText aside p {
    font-size: 47px;
    /* font-family: 'Alegreya SC', serif; */
    margin: 0 0 10px;
    font-weight: 700;
    letter-spacing: .5px;
    /* font-family: 'Alegreya SC', serif */
}

.SliderText article {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 25px
}

.SliderText article p.Apps {
    margin: 0 0 10px;
    font-size: 20px;
    /* font-family: 'Alegreya SC', serif; */
}

.SliderText article p.Founded {
    font-size: 17px;
    font-style: italic;
    line-height: 26px;
    margin: 0 0 20px;
    /* font-family: Roboto, sans-serif; */
}

.SliderText article ul {
    display: block
}

.SliderText article ul li {
    display: inline-block;
    margin: 0 10px
}

.SliderText article ul li a {
    border: 2px solid #fff;
    color: #fff;
    padding: 8px 30px;
    font-size: 17px;
    transition: 0.5s all ease-in-out;
    font-weight: 500;
    /* font-family: 'Alegreya SC', serif; */
    display: block;
    position: relative;
    background-color: #fff;
    /* font-family: 'Alegreya SC', serif' */
}

.SliderText article ul li a span {
    color: #000;
    z-index: 10;
    position: relative;
    transition: 0.5s all ease-in-out
}

.SliderText article ul li a:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2098D1;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out
}

.SliderText article ul li a:hover {
    color: #000
}

.SliderText article ul li a:hover span {
    color: #fff
}

.SliderText article ul li a:hover:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1)
}

.DesignArea {
    padding: 50px 0;
    background-color: #fff
}

.DesignBox {
    padding: 20px;
    border-radius: 10px;
    min-height: 250px
}

.DesignBox figure {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 10px 0;
    padding: 0
}

.DesignBox h3 {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 25px;
    color: #000;
    margin: 0 0 10px 0;
    font-weight: 600
}

.DesignBox p {
    font-size: 14px;
    line-height: 23px;
    margin: 0 0 10px 0
}

.DesignBox a {
    background-color: #3f95cc;
    color: #fff;
    padding: 12px 30px;
    display: inline-block;
    font-size: 15px;
    border-radius: 5px;
    opacity: 1;
    transition: 0.5s all ease-in-out
}

.DesignBox:hover {
    box-shadow: 0 0 10px #ddd
}

.DesignBox:hover a {
    opacity: 1
}

.WorkArea {
    padding: 0 50px 50px;
    background-color: #fff
}

.WorkArea #Works .owl-stage-outer {
    height: 500px
}

.WorkArea .WorkBox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 15px;
    overflow: hidden;
    background-color: #f7f7f7
}

.WorkArea .WorkBox .WorkLeft {
    width: 60%
}

.WorkArea .WorkBox .WorkLeft figure {
    width: 100%;
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin: 0;
    height: 100%
}

.WorkArea .WorkBox .WorkLeft figure:before {
    content: '';
    display: block;
    padding-bottom: 500px
}

.WorkArea .WorkBox .WorkLeft figure img {
    top: 50%;
    left: 50%;
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100%;
    max-width: none;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    height: 100%;
    object-fit: cover
}

.WorkArea .WorkBox .WorlRight {
    width: 40%;
    padding: 0 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}

.WorkArea .WorkBox .WorlRight figcaption {
    transition: all 0.4s ease 4.6s;
    -o-transition: all 0.4s ease 4.6s;
    -webkit-transition: all 0.4s ease 4.6s;
    transform: translateX(0);
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1;
    position: relative;
    z-index: 2
}

.WorkArea .WorkBox .WorlRight figcaption h4 {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 40px;
    color: #4a95cc;
    margin: 0 0 10px 0;
    font-weight: 600
}

.WorkArea .WorkBox .WorlRight figcaption h3 {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 25px;
    color: #000;
    margin: 0 0 10px 0;
    font-weight: 600;
    line-height: 33px
}

.WorkArea .WorkBox .WorlRight figcaption p {
    font-size: 15px;
    line-height: 27px;
    margin: 0 0 10px 0;
    /* font-family: 'Roboto'; */
    color: #000
}

.WorkArea .WorkBox .WorlRight figcaption a {
    background-color: #3f95cc;
    color: #fff;
    padding: 8px 30px;
    display: inline-block;
    font-size: 15px;
    border-radius: 20px;
    /* font-family: 'Roboto'; */
    text-transform: capitalize;
    font-weight: 400
}

.WorkArea .owl-dots .owl-dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-block;
    background: #ccc;
    margin: 0 5px;
    transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    outline: 0
}

.WorkArea .owl-dots .owl-dot.active {
    border-radius: 20px;
    width: 35px;
    background-color: #4a95cc
}

.WorkArea .WorkLink {
    text-align: center;
    margin: 35px 0 0 0
}

.WorkArea .WorkLink a {
    background-color: #3f95cc;
    color: #fff;
    padding: 12px 30px;
    display: inline-block;
    font-size: 15px;
    border-radius: 5px;
    opacity: 1;
    transition: 0.5s all ease-in-out;
    text-align: center
}

.ProjectArea {
    background-color: #f7f7f7;
    padding: 50px 0
}

.ProjectArea .ProjectLeft h4 {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 30px;
    color: #000;
    margin: 0 0 10px 0;
    font-weight: 600;
    line-height: 33px
}

.ProjectArea .ProjectLeft p {
    font-size: 15px;
    /* font-family: 'Roboto'; */
    line-height: 26px;
    margin: 0 0 15px 0
}

.ProjectArea .ProjectLeft a {
    border: 1px solid #000;
    padding: 10px 30px;
    display: inline-block;
    border-radius: 50px;
    color: #000;
    /* font-family: 'Roboto'; */
    font-size: 15px;
    font-weight: 400
}

.ProjectArea .ProjectLeft a:hover {
    background-color: #000;
    color: #fff
}

.ProjectArea .ProjectRight ul {
    display: flex;
    align-items: center;
    justify-content: center
}

.ProjectArea .ProjectRight ul li {
    padding: 0 14px;
    width: 100%
}

.ProjectArea .ProjectRight ul li aside {
    text-align: center;
    padding: 25px 10px 25px 10px;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .15);
    transition: all 0.3s ease-in-out;
    /* font-family: 'Alegreya SC', serif */
}

.ProjectArea .ProjectRight ul li aside+aside {
    margin-top: 30px
}

.ProjectArea .ProjectRight ul li aside img {
    width: 100px
}

.ProjectArea .ProjectRight ul li aside h3 {
    margin: 20px 0 10px;
    font-size: 20px;
    text-transform: capitalize;
    color: #000
}

.ProjectArea .ProjectRight ul li aside:hover {
    transform: translate3d(0, -10px, 0);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}

.LandscapeArea {
    padding: 50px 0 85px;
    position: relative;
    background-color: #fff
}

.LandscapeArea ul {
    padding: 40px 30px 0 30px;
    display: flex
}

.LandscapeArea ul li {
    width: calc(100% / 6);
    padding: 0 7px
}

.LandscapeArea ul li .LandscapeBox {
    border: 2px solid #26a9e1;
    position: relative;
    padding: 30px 15px 5px 15px;
    height: 100%;
    transition: 0.1s all ease-in-out
}

.LandscapeArea ul li .LandscapeBox figure {
    border: 3px solid #26a9e1;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin: auto;
    display: block;
    position: absolute;
    top: -33px;
    left: 0;
    right: 0
}

.LandscapeArea ul li .LandscapeBox figure img {
    width: 100%
}

.LandscapeArea ul li .LandscapeBox p.LandTitle {
    /* font-family: 'Alegreya SC', serif; */
    color: #000;
    font-size: 17px;
    line-height: 19px;
    margin: 0 0 4px 0;
    font-weight: 500
}

.LandscapeArea ul li .LandscapeBox p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    /* font-family: Roboto; */
}

.LandscapeArea ul li .LandscapeBox:hover {
    background-color: #26a9e1;
    color: #fff
}

.LandscapeArea ul li .LandscapeBox:hover h5 {
    color: #fff
}

.LandscapeArea ol {
    padding: 0 30px 0 30px;
    display: flex
}

.LandscapeArea ol li {
    width: calc(100% / 6);
    padding: 0 7px
}

.LandscapeArea ol li .WorkBox {
    border: 2px solid #26a9e1;
    position: relative;
    padding: 15px 15px 50px 15px;
    height: 100%;
    transition: 0.1s all ease-in-out
}

.LandscapeArea ol li .WorkBox figure {
    border: 3px solid #26a9e1;
    width: 155px;
    margin: auto;
    display: block;
    position: absolute;
    bottom: -33px;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 10px 0
}

.LandscapeArea ol li .WorkBox figure img {
    width: 70%;
    filter: brightness(0);
    display: block;
    margin: auto
}

.LandscapeArea ol li .WorkBox p {
    font-size: 13.5px;
    line-height: 22px;
    font-weight: 400;
    /* font-family: Roboto; */
}

.LandscapeArea ol li .WorkBox:hover {
    background-color: #26a9e1;
    color: #fff
}

.LandscapeArea ol li .WorkBox:hover h5 {
    color: #fff
}

.IdeaBox {
    padding: 0 0;
    text-align: center
}

.IdeaBox article {
    padding: 15px 0 15px 0
}

.IdeaBox article aside {
    display: inline-block;
    margin: 0 6px;
    min-width: 320px;
    text-align: center;
    padding: 10px 0
}

.IdeaBox article aside:first-child {
    border-right: 2px solid #26a9e1;
    border-bottom: 2px solid #26a9e1
}

.IdeaBox article aside:last-child {
    border-left: 2px solid #26a9e1;
    border-bottom: 2px solid #26a9e1
}

.IdeaBox article aside p.Title {
    /* font-family: 'Alegreya SC', serif; */
    color: #000;
    font-weight: 800;
    font-size: 22px;
    margin: 0
}

.IdeaBox article aside p.Text {
    font-size: 14px;
    font-weight: 400;
    /* font-family: Roboto; */
    letter-spacing: .5px
}

.BrandArea {
    background: #26a9e0;
    color: #fff;
    padding: 50px 0
}

.BrandHead h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 800;
    /* font-family: 'Alegreya SC', serif; */
    text-align: center;
    margin: 10px 0
}

.BrandHead p {
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 26px
}

.BrandBody ul {
    text-align: center;
    margin: 0;
    position: relative
}

.BrandBody ul:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 95%;
    left: 0;
    background-color: #fff;
    height: 1px
}

.BrandBody ul li {
    width: 14%;
    text-align: center;
    position: relative;
    display: inline-block
}

.BrandBody ol {
    margin: 0
}

.BrandBody ol li {
    width: 14%;
    text-align: center;
    position: relative;
    display: inline-block
}

.BrandBody ul li p,
.BrandBody ol li p {
    font-size: 15px;
    /* font-family: 'Alegreya SC', serif; */
    font-weight: 500
}

.BrandBody ul li figure,
.BrandBody ol li figure {
    margin: 0
}

.BrandBody ul li figure img,
.BrandBody ol li figure img {
    width: 40px
}

.BrandBody ul li .Line,
.BrandBody ol li .Line {
    border: 1px solid #fff;
    width: 1px;
    height: 34px;
    display: inline-block
}

.BrandBody ul li .Dots,
.BrandBody ol li .Dots {
    width: 10px;
    height: 10px;
    background: #26a9e1;
    margin: 0 auto;
    border-radius: 100%;
    border: 2px solid #fff;
    padding: 2px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -6px
}

.BrandBody ol li .Dots {
    top: -6px
}

.MatrixArea {
    padding: 50px 0 20px;
    background-color: #fff
}

.MatrixArea .MatrixBox {
    background-color: #fff;
    margin: 0 0 30px 0;
    min-height: 190px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 6px #ddd;
    cursor: pointer;
    transition: 0.5s all ease-in-out
}

.MatrixArea .MatrixBox figure {
    width: 100px;
    margin: 0 auto 0
}

.MatrixArea .MatrixBox p {
    /* font-family: 'Alegreya SC', serif; */
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px
}

.MatrixArea .MatrixBox:hover {
    transform: translate(0, -10px)
}

.AboutArea {
    padding: 50px 0;
    background-color: #f9f9f9;
    min-height: 100vh
}

.AboutArea .AboutBox {
    margin: 0 0 0
}

.AboutArea .AboutBox+.AboutBox {
    margin: 40px 0 0
}

.AboutArea .AboutBox h3 {
    padding: 0 0 5px;
    position: relative;
    margin: 0 0 20px;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    color: #000;
    font-size: 30px;
    letter-spacing: .5px;
    /* font-family: 'Alegreya SC', serif */
}

.AboutArea .AboutBox h3:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 150px;
    height: 2px;
    background-color: #2290ce;
    margin: auto
}

.AboutArea .AboutBox h3 span {
    color: #2290ce
}

.AboutArea .AboutBox p {
    font-weight: 400;
    font-size: 16px;
    color: #000;
    line-height: 35px;
    text-align: left;
    /* font-family: 'Roboto'; */
    margin: 0 0 10px
}

.ClientArea {
    padding: 50px 0 50px;
    background-color: #fff
}

.ClientArea .ClientBox {
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px #ddd;
    transform: scale(.9);
    min-height: 450px
}

.ClientArea .ClientBox figure {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #27a4dc;
    padding: 0;
    margin: 0 auto 10px
}

.ClientArea .ClientBox figure img {
    border-radius: 50%;
    border: 5px solid #fff
}

.ClientArea .ClientBox p.Name {
    margin: 0 0 6px;
    font-size: 19px;
    font-weight: 600;
    color: #000;
    /* font-family: 'Alegreya SC', serif */
}

.ClientArea .ClientBox p.Title {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    color: #269fd8;
    margin: 0 0 10px;
    /* font-family: 'Alegreya SC', serif */
}

.ClientArea .ClientBox p {
    font-size: 13px;
    line-height: 28px;
    /* font-family: Roboto; */
}

.ClientArea .ClientBox p.Rates {
    color: #ffbc00;
    font-size: 18px;
    margin: 10px 0 0 0
}

.ClientArea .ClientBox p.Rates i {
    margin: 0 3px
}

.ClientArea .owl-item.center .ClientBox {
    transform: scale(1);
    background-image: linear-gradient(45deg, #1b75bb, #29aae0)
}

.ClientArea .owl-item.center .ClientBox figure {
    border-color: transparent
}

.ClientArea .owl-item.center .ClientBox p.Name,
.ClientArea .owl-item.center .ClientBox p.Title {
    background: none;
    -webkit-text-fill-color: #fff
}

.ClientArea .owl-item.center .ClientBox p {
    color: #fff
}

.ClientArea .owl-nav .owl-prev,
.ClientArea .owl-nav .owl-next {
    font-size: 55px !important;
    line-height: 35px !important;
    color: #696969 !important;
    outline: 0;
    position: absolute;
    top: 0;
    height: 40px;
    bottom: 0;
    margin: auto !important
}

.ClientArea .owl-nav .owl-prev:hover,
.ClientArea .owl-nav .owl-next:hover {
    color: #26a0d9 !important
}

.ClientArea .owl-nav .owl-prev {
    left: -20px
}

.ClientArea .owl-nav .owl-next {
    right: -20px
}

.AwardsArea {
    background-color: #f9f9f9;
    padding: 50px 0 30px
}

.AwardsArea .AwardsBox {
    background-color: #fff;
    padding: 30px 20px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 220px
}

.AwardsArea .AwardsBox:after {
    content: '';
    position: absolute;
    top: 0;
    width: 0%;
    left: 0;
    height: 0%;
    background-color: #478fce;
    right: 0;
    bottom: 0;
    margin: auto;
    -webkit-transition: all 0.35s ease 0.105s
}

.AwardsArea .AwardsBox figure {
    margin: 0
}

.AwardsArea .AwardsBox figure .Rating {
    text-align: center;
    font-size: 18px;
    margin: 0 0 10px 0;
    color: #f6b826
}

.AwardsArea .AwardsBox figure p {
    /* font-family: 'Alegreya SC', serif; */
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    color: #000
}

.AwardsArea .AwardsBox figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    -webkit-transition: all 1.5s ease 0.2s;
    opacity: 0;
    z-index: 9
}

.AwardsArea .AwardsBox figcaption p {
    text-align: center;
    font-size: 17px;
    color: #fff;
    line-height: 28px;
    /* font-family: 'Alegreya SC', serif */
}

.AwardsArea .AwardsBox:hover:after {
    width: 100%;
    height: 100%
}

.AwardsArea .AwardsBox:hover figcaption {
    opacity: 1
}

.FeaturedArea {
    background: #f9f9f9 url(https://mobulous.s3.ap-south-1.amazonaws.com/Web/images/main/World.png) no-repeat center center;
    padding: 50px 0
}

.FeaturedArea h2 {
    padding-bottom: 15px
}

.FeaturedArea h2:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 110px;
    height: 5px;
    background-color: #26a9e1;
    right: 0;
    margin: auto
}

.FeaturedArea .FeaturedBox {
    position: relative;
    height: 100px;
    min-width: 100%;
    width: 100%;
    display: inline-block;
    float: left;
    content: "";
    z-index: 0;
    overflow: hidden
}

.FeaturedArea .FeaturedBox:after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 9356px;
    display: inline-block;
    content: "";
    z-index: 1;
    background-image: url(https://mobulous.s3.ap-south-1.amazonaws.com/Web/images/main/Media.png);
    -webkit-background-size: 4618px 100px;
    -moz-background-size: 4618px 100px;
    -o-background-size: 4618px 100px;
    background-size: 4618px 100px;
    -webkit-animation: slideshow 30s linear infinite;
    -moz-animation: slideshow 30s linear infinite;
    animation: slideshow 30s linear infinite
}

@-webkit-keyframes slideshow {
    form {
        -webkit-background-position: 0;
        -moz-background-position: 0;
        -o-background-position: 0;
        background-position: 0;
    }

    to {
        -webkit-background-position: -4618px;
        -moz-background-position: -4618px;
        -o-background-position: -4618px;
        background-position: -4618px;
    }
}

@-moz-keyframes slideshow {
    form {
        -webkit-background-position: 0;
        -moz-background-position: 0;
        -o-background-position: 0;
        background-position: 0;
    }

    to {
        -webkit-background-position: -4618px;
        -moz-background-position: -4618px;
        -o-background-position: -4618px;
        background-position: -4618px;
    }
}

@-o-keyframes slideshow {
    form {
        -webkit-background-position: 0;
        -moz-background-position: 0;
        -o-background-position: 0;
        background-position: 0;
    }

    to {
        -webkit-background-position: -4618px;
        -moz-background-position: -4618px;
        -o-background-position: -4618px;
        background-position: -4618px;
    }
}

@keyframes slideshow {
    form {
        -webkit-background-position: 0;
        -moz-background-position: 0;
        -o-background-position: 0;
        background-position: 0;
    }

    to {
        -webkit-background-position: -4618px;
        -moz-background-position: -4618px;
        -o-background-position: -4618px;
        background-position: -4618px;
    }
}


.FeaturedArea .FeaturedFirst+.FeaturedSecond {
    margin: 100px 0 0 0
}

.FeaturedArea .FeaturedSecond .FeaturedClient {
    position: relative;
    height: 100px;
    min-width: 100%;
    width: 100%;
    display: inline-block;
    float: left;
    content: "";
    z-index: 0;
    overflow: hidden
}

.FeaturedArea .FeaturedSecond .FeaturedClient:after {
    position: absolute;
    left: 0;
    top: 0;
    height: 110px;
    width: 9356px;
    display: inline-block;
    content: "";
    z-index: 1;
    background-image: url(https://mobulous.s3.ap-south-1.amazonaws.com/Web/images/main/Client.png);
    -webkit-background-size: 4618px 100px;
    -moz-background-size: 4618px 100px;
    -o-background-size: 4618px 100px;
    background-size: 3200px 100px;
    -webkit-animation: slideshow2 30s linear infinite;
    -moz-animation: slideshow2 30s linear infinite;
    animation: slideshow2 30s linear infinite
}

/*
@-webkit-keyframes slideshow2 {
    form{
        -webkit-background-position:0;
        -moz-background-position:0;
        -o-background-position:0;
        background-position:0;
    }
    to{
        -webkit-background-position:4618px;
        -moz-background-position:4618px;
        -o-background-position:4618px;
        background-position:4618px; 
    }
}
*/
@keyframes slideshow2 {
    form {
        -webkit-background-position: 0;
        -moz-background-position: 0;
        -o-background-position: 0;
        background-position: 0;
    }

    to {
        -webkit-background-positin: 4618px;
        -moz-background-positin: 4618px;
        -o-background-positin: 4618px;
        background-positin: 4618px;
    }
}

@-webkit-keyframes slideshow2 {
    form {
        -webkit-background-position: 0;
        -moz-background-position: 0;
        -o-background-position: 0;
        background-position: 0;
    }

    to {
        -webkit-background-position: 4618px;
        -moz-background-position: 4618px;
        -o-background-position: 4618px;
        background-position: 4618px;
    }
}

@-moz-keyframes slideshow2 {
    form {
        -webkit-background-position: 0;
        -moz-background-position: 0;
        -o-background-position: 0;
        background-position: 0;
    }

    to {
        -webkit-background-position: 4618px;
        -moz-background-position: 4618px;
        -o-background-position: 4618px;
        background-position: 4618px;
    }
}

@-o-keyframes slideshow2 {
    form {
        -webkit-background-position: 0;
        -moz-background-position: 0;
        -o-background-position: 0;
        background-position: 0;
    }

    to {
        -webkit-background-position: 4618px;
        -moz-background-position: 4618px;
        -o-background-position: 4618px;
        background-position: 4618px;
    }
}


.FeedbackArea {
    padding: 50px 0;
    background-color: #fff
}

.FeedbackArea .FeedbackRight h3 {
    margin: 0 0 15px;
    text-align: left;
    text-transform: capitalize;
    font-weight: 600;
    color: #464646;
    font-size: 25px;
    letter-spacing: .5px;
    line-height: 25px;
    /* font-family: 'Alegreya SC', serif; */
}

.FeedbackArea .FeedbackRight ul li {
    width: 49%;
    margin: 0 0 15px 0;
    float: left
}

.FeedbackArea .FeedbackRight ul li:nth-child(2n) {
    float: right
}

.FeedbackArea .FeedbackRight ul li:nth-last-child(-n+2) {
    margin: 0
}

.FeedbackArea .FeedbackRight ul li .Domain {
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 7px
}

.FeedbackArea .FeedbackRight ul li .Domain .Icon {
    width: 55px;
    margin: 0 10px 0 0;
    border-radius: 5px;
    overflow: hidden
}

.FeedbackArea .FeedbackRight ul li .Domain .Title {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 16px;
    font-weight: 500
}

.Footer {
    background-color: #222;
    padding: 50px 0
}

.Footer .FooterLeft aside {
    margin: 20px 0 0 0
}

.Footer .FooterLeft aside h6 {
    margin: 0 0 10px;
    text-transform: capitalize;
    font-weight: 600;
    color: #888;
    font-size: 25px;
    letter-spacing: .5px;
    /* font-family: 'Alegreya SC', serif; */
    line-height: 25px
}

.Footer .FooterLeft aside p {
    margin: 0;
    text-transform: capitalize;
    font-weight: 600;
    color: #888;
    font-size: 16px;
    /* font-family: 'Alegreya SC', serif; */
    line-height: 23px
}

.Footer .FooterRight {
    display: flex
}

.Footer .FooterRight .Foots {
    width: 100%
}

.Footer .FooterRight .Foots h4 {
    margin: 0 0 15px;
    text-transform: capitalize;
    font-weight: 600;
    color: #888;
    font-size: 25px;
    letter-spacing: .5px;
    /* font-family: 'Alegreya SC', serif; */
    line-height: 25px
}

.Footer .FooterRight .Foots ul li {
    position: relative;
    padding: 0 0 0 20px;
    margin: 0 0 10px 0
}

.Footer .FooterRight .Foots ul li:before {
    content: '\f105';
    /* font-family: 'FontAwesome'; */
    color: #999;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 15px;
    font-weight: 600
}

.Footer .FooterRight .Foots ul li a {
    color: #999;
    font-size: 14px;
    /* font-family: 'Roboto'; */
}

.Footer .FooterRight .Foots ul li a:hover {
    color: #fff
}

.CopyRight {
    background-color: #151414;
    padding: 20px 0
}

.CopyRight ul {
    margin: 0
}

.CopyRight ul li {
    margin: 0 10px 0 0;
    display: inline-block
}

.CopyRight ul li a {
    width: 40px;
    height: 40px;
    color: #888;
    border: 2px solid #888;
    display: block;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
    line-height: 36px;
    transition: 0.5s all ease-in-out
}

.CopyRight ul li a:hover {
    background-color: #888;
    color: #fff
}

.CopyRight p {
    color: #888;
    font-size: 14px;
    /* font-family: 'Alegreya SC', serif; */
    line-height: 23px;
    font-weight: 600;
    letter-spacing: .5px;
    text-align: right;
    margin: 8px 0
}

/* ********************************* About Us CSS ********************************* */


.BannerArea {
    position: relative;
    min-height: 100vh;
    background-size: cover;
    padding: 0;
    display: flex;
    align-items: center
}

.BannerArea:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000c7
}

.BannerArea h1 {
    text-align: center;
    color: #fff;
    position: relative;
    /* font-family: 'Alegreya SC', serif; */
    font-size: 90px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0 0 20px 0
}

.BannerArea h3 {
    text-align: center;
    color: #fff;
    position: relative;
    /* font-family: 'Alegreya SC', serif; */
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0 0 30px 0;
    line-height: 50px
}

.BannerArea .PortfolioBanner {
    width: 90%;
    margin: auto;
    position: relative;
    display: block
}

.BannerArea h5 {
    position: relative;
    text-align: center;
    color: #fff;
    font-size: 17px;
    line-height: 32px;
    /* font-family: 'Roboto'; */
    font-weight: 400;
    margin: 0 0 30px 0
}

.BannerArea .HappyBox {
    text-align: center;
    color: #fff;
    margin: 30px 0 0 0
}

.BannerArea .HappyBox h6 {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 60px
}

.BannerArea .HappyBox p {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 21px
}

.MissonArea {
    padding: 50px 0
}

.MissonArea .MissonBox {
    border: 1px solid #3a75bc;
    width: 60%
}

.MissonArea .MissonBox .MissonContent {
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0 0 2rem rgb(0 0 0 / 10%);
    padding: 45px;
    margin: 30px 0 -30px 30px;
    min-height: 340px
}

.MissonArea .MissonBox .MissonContent h2 {
    /* font-family: 'Alegreya SC', serif; */
    margin: 0 0 10px 0;
    font-weight: 600;
    font-size: 40px
}

.MissonArea .MissonBox .MissonContent p {
    font-size: 15px;
    line-height: 31px;
    /* font-family: 'Roboto'; */
}

.MissonArea .MissonBox+.MissonBox {
    margin-left: auto;
    margin-top: 40px
}

.MissonArea .MissonBox+.MissonBox .MissonContent {
    margin: -40px 0 40px -40px
}

.MissonArea ul {
    display: flex;
    align-items: center
}

.MissonArea ul li {
    width: 50%;
    padding: 0 10px
}

.MissonArea ul li picture {
    display: block;
    border-radius: 10px;
    overflow: hidden
}

.MissonArea ul li picture img {
    transition: 0.5s all ease-in-out
}

.MissonArea ul li picture+picture {
    margin: 20px 0 0 0
}

.MissonArea ul li picture:hover img {
    transform: scale(1.1)
}

.EngagementArea .EngagementLeft {
    background-color: #26a9e1;
    padding: 50px 50px
}

.EngagementArea .EngagementLeft ul li {
    display: flex;
    align-items: center;
    margin: 0 0 50px 0
}

.EngagementArea .EngagementLeft ul li:last-child {
    margin: 0
}

.EngagementArea .EngagementLeft ul li figure {
    margin: 0 20px 0 0;
    width: 40px;
    height: 40px
}

.EngagementArea .EngagementLeft ul li figure img {
    width: 100%;
    height: 100%
}

.EngagementArea .EngagementLeft ul li figcaption h5 {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 28px;
    color: #fff
}

.EngagementArea .EngagementLeft ul li figcaption p {
    font-size: 18px;
    color: #fff;
    /* font-family: 'Alegreya SC', serif; */
    font-weight: 500
}

.EngagementArea .EngagementRight {
    background-color: #f7f7f7;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 100px
}

.EngagementBox {
    padding: 30px 0 10px
}

.EngagementBox .Count {
    position: relative;
    font-size: 40px;
    color: #000;
    z-index: 9;
    display: inline-block;
    font-weight: 600;
    font-family: Roboto;
    line-height: 40px
}

.EngagementBox .Count:after {
    content: '';
    position: absolute;
    top: -22px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: right;
    color: #000;
    font-size: 35px;
    padding: 0 5px 0 0;
    line-height: 40px;
    z-index: -1;
    right: -15px;
    background-color: #55aae1
}

.EngagementBox h2 {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 23px;
    margin: 10px 0 10px 0
}

.EngagementBox h3 {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 30px;
    margin: 0 0 10px 0
}

.EngagementBox p {
    font-size: 15px;
    line-height: 31px;
    color: #000;
    /* font-family: 'Roboto'; */
}

.EngagementBox ul {
    padding: 0 0 0 25px
}

.EngagementBox ul li {
    /* font-family: 'Roboto'; */
    font-size: 15px;
    margin: 0 0 10px 0;
    list-style: disc
}

.EngagementSlider .owl-dots .owl-dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-block;
    background: #ccc;
    margin: 0 5px;
    transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    outline: 0
}

.EngagementSlider .owl-dots .owl-dot.active {
    border-radius: 20px;
    width: 35px;
    background-color: #4a95cc
}

.ValueArea {
    padding: 50px 0 20px 0
}

.ValueArea .valueBox {
    margin: 0 0 50px 0
}

.ValueArea .valueBox figcaption {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 10px 0
}

.ValueArea .valueBox figcaption figure {
    margin: 0 10px 0 0;
    width: 70px;
    height: 70px
}

.ValueArea .valueBox figcaption h3 {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 24px;
    position: relative;
    padding: 0 0 10px 0
}

.ValueArea .valueBox figcaption h3:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 60px;
    height: 3px;
    background-color: #55aae1;
    left: 0;
    right: 0;
    margin: auto
}

.ValueArea .valueBox p {
    text-align: center;
    font-size: 14px;
    line-height: 27px
}

/* ********************************* Team CSS ********************************* */


.LeaderArea {
    padding: 50px 0 0 0
}

.LeaderArea .LeaderBody {
    background-color: #d8f7f7 !important;
    padding: 60px 0 0
}

.LeaderBox {
    display: flex;
    margin: 0 0 70px 0
}

.LeaderBox figure {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    border: 0 solid #fff
}

.LeaderBox figcaption {
    width: calc(100% - 150px);
    padding: 0 0 0 20px
}

.LeaderBox figcaption h4 {
    /* font-family: 'Alegreya SC', serif; */
    color: #000;
    margin: 0 0 5px 0
}

.LeaderBox figcaption h5 {
    font-size: 22px;
    text-transform: capitalize;
    color: #ff7173;
    /* font-family: 'Alegreya SC', serif; */
    font-weight: 600;
    margin: 0 0 7px 0
}

.LeaderBox figcaption p {
    color: #151414;
    /* font-family: 'Roboto'; */
    line-height: 23px
}

.LeaderBox figcaption ul {
    display: flex;
    margin: 5px 0 0 0
}

.LeaderBox figcaption ul li {
    margin: 0 15px 0 0
}

.LeaderBox figcaption ul li a {
    font-size: 20px;
    color: #20BFA9;
}

.LeaderBox figcaption ul li a:hover {
    color: #099ddb;
}


.TeamArea {
    padding: 50px 0 0
}

.TeamBox {
    margin: 0 0 40px 0;
    text-align: center
}

.TeamBox figure {
    display: inline-block
}

.TeamBox figure img {
    border-radius: 10px
}

.TeamBox figcaption {
    padding: 15px 0 0 0
}

.TeamBox figcaption h4 {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 25px;
    font-weight: 500;
    margin: 0 0 5px 0
}

.TeamBox figcaption p {
    /* font-family: 'Roboto'; */
    font-weight: 400;
    font-size: 16px
}

.MembersArea {
    background-color: #f7f6f6;
    padding: 50px 0 0
}

.MembersArea .MembersMiddle {
    height: 100%
}

.MembersArea .MembersMiddle aside {
    height: 40%;
    background-color: #55aae1;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    text-align: center;
    margin: 0 0 70px 0
}

.MembersArea .MembersMiddle aside h4 {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 60px;
    color: #fff
}

.MembersArea .MembersMiddle article {
    height: calc(50% - 120px);
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    text-align: center
}

.MembersArea .MembersMiddle article p {
    /* font-family: 'Roboto'; */
    font-size: 17px;
    line-height: 33px
}

.TeamSlider .TitleBox h2 {
    padding: 0 0 20px 0;
    margin: 0 0 20px 0;
    position: relative
}

.TeamSlider .TitleBox h2:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 150px;
    height: 4px;
    background-color: #55aae1;
    left: 0;
    right: 0;
    margin: auto
}

.TeamSlider .TeamSliderBody {
    display: flex
}

.TeamSlider .TeamSliderBody .Slide {
    height: 650px;
    overflow: hidden
}

.TeamSlider .TeamSliderBody .Slide.Slide1 {
    width: 25%
}

.TeamSlider .TeamSliderBody .Slide.Slide2 {
    width: 50%
}

.TeamSlider .TeamSliderBody .Slide aside {
    height: 275px;
    background: #3bd4ff;
    display: flex;
    align-items: center;
    justify-content: center
}

.TeamSlider .TeamSliderBody .Slide aside h4 {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 40px;
    color: #fff
}

.TeamSlider .TeamSliderBody .Slide article {
    height: calc(100% - 275px)
}

.OwlSlider {
    height: 100%
}

.OwlSlider .owl-stage-outer {
    height: 100%
}

.OwlSlider .owl-stage-outer .owl-stage {
    height: 100%
}

.OwlSlider .owl-stage-outer .owl-stage .owl-item {
    height: 100%
}

.OwlSlider .owl-stage-outer .owl-stage .owl-item .item {
    height: 100%
}

.OwlSlider .owl-stage-outer .owl-stage .owl-item .item .image {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out
}

.OwlSlider .owl-stage-outer .owl-stage .owl-item .item .image:hover {
    filter: inherit
}

/* ********************************* Portfolio CSS ********************************* */


.WedoArea {
    padding: 115px 0 0
}

.WedoArea .WedoHead {
    margin: 0 0 50px 0
}

.WedoArea .WedoHead .WedoLeft h3 {
    font-size: 70px;
    letter-spacing: -1.5px;
    color: #000;
    margin-bottom: 20px;
    position: relative;
    /* font-family: 'Alegreya SC', serif */
}

.WedoArea .WedoHead .WedoLeft p {
    font-size: 20px;
    color: #000;
    line-height: 32px;
    font-weight: 400;
    /* font-family: 'Roboto' */
}

.WedoArea .WedoHead .WedoRight h6 {
    font-size: 22px;
    color: #000;
    margin-bottom: 15px;
    position: relative;
    /* font-family: 'Alegreya SC', serif */
}

.WedoArea .WedoHead .WedoRight ul {
    display: flex
}

.WedoArea .WedoHead .WedoRight ul li {
    margin: 0 10px 0 0
}

.WedoArea .WedoHead .WedoRight ul li a {
    /* font-family: 'Alegreya SC', serif; */
    width: 45px;
    height: 45px;
    border-radius: 3px;
    border: solid 1px #cecece;
    display: block;
    text-align: center;
    -webkit-transition: all .3s;
    transition: all .3s;
    font-size: 20px;
    color: #000;
    line-height: 43px
}

.WedoArea .WedoHead .WedoRight ul li a:hover {
    -webkit-box-shadow: 0 16px 14px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 10px 14px 0 rgb(0 0 0 / 10%);
    background-color: #2088c9;
    border: solid 1px transparent;
    color: #fff
}

.WedoArea .WedoBody {
    margin: 0 0 50px 0
}

.WedoArea .WedoBody .WedoBodyLeft h3 {
    /* font-family: 'Alegreya SC', serif; */
    font-size: 40px;
    margin: 0 0 10px 0
}

.WedoArea .WedoBody .WedoBodyLeft p {
    font-family: Roboto;
    line-height: 32px;
    font-size: 16px
}

.WedoArea .WedoBody .WedoBodyRight {
    padding: 40px 0;
    overflow: hidden
}

.WedoArea .WedoBody .WedoBodyRight h1 {
    font-family: 'Alegreya SC', serif;
    font-size: 80px;
    letter-spacing: 1px;
    color: #b9b9b9;
    line-height: 70px
}

.WedoArea .WedoLeft h3::after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 0;
    color: #000;
    background: transparent;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse
}

.WedoArea .WedoLeft h3::before {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 #2e2e4e;
    top: 0;
    color: #000;
    background: transparent;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse
}

@-webkit-keyframes noise-anim {
    0% {
        clip: rect(76px, 9999px, 32px, 0)
    }

    5% {
        clip: rect(51px, 9999px, 70px, 0)
    }

    10% {
        clip: rect(22px, 9999px, 100px, 0)
    }

    15% {
        clip: rect(36px, 9999px, 80px, 0)
    }

    20% {
        clip: rect(6px, 9999px, 82px, 0)
    }

    25% {
        clip: rect(32px, 9999px, 32px, 0)
    }

    30% {
        clip: rect(60px, 9999px, 1px, 0)
    }

    35% {
        clip: rect(90px, 9999px, 52px, 0)
    }

    40% {
        clip: rect(49px, 9999px, 66px, 0)
    }

    45% {
        clip: rect(88px, 9999px, 73px, 0)
    }

    50% {
        clip: rect(20px, 9999px, 23px, 0)
    }

    55% {
        clip: rect(7px, 9999px, 51px, 0)
    }

    60% {
        clip: rect(20px, 9999px, 64px, 0)
    }

    65% {
        clip: rect(92px, 9999px, 76px, 0)
    }

    70% {
        clip: rect(95px, 9999px, 71px, 0)
    }

    75% {
        clip: rect(73px, 9999px, 32px, 0)
    }

    80% {
        clip: rect(17px, 9999px, 55px, 0)
    }

    85% {
        clip: rect(32px, 9999px, 22px, 0)
    }

    90% {
        clip: rect(90px, 9999px, 18px, 0)
    }

    95% {
        clip: rect(22px, 9999px, 64px, 0)
    }

    100% {
        clip: rect(25px, 9999px, 18px, 0)
    }
}

@keyframes noise-anim {
    0% {
        clip: rect(76px, 9999px, 32px, 0)
    }

    5% {
        clip: rect(51px, 9999px, 70px, 0)
    }

    10% {
        clip: rect(22px, 9999px, 100px, 0)
    }

    15% {
        clip: rect(36px, 9999px, 80px, 0)
    }

    20% {
        clip: rect(6px, 9999px, 82px, 0)
    }

    25% {
        clip: rect(32px, 9999px, 32px, 0)
    }

    30% {
        clip: rect(60px, 9999px, 1px, 0)
    }

    35% {
        clip: rect(90px, 9999px, 52px, 0)
    }

    40% {
        clip: rect(49px, 9999px, 66px, 0)
    }

    45% {
        clip: rect(88px, 9999px, 73px, 0)
    }

    50% {
        clip: rect(20px, 9999px, 23px, 0)
    }

    55% {
        clip: rect(7px, 9999px, 51px, 0)
    }

    60% {
        clip: rect(20px, 9999px, 64px, 0)
    }

    65% {
        clip: rect(92px, 9999px, 76px, 0)
    }

    70% {
        clip: rect(95px, 9999px, 71px, 0)
    }

    75% {
        clip: rect(73px, 9999px, 32px, 0)
    }

    80% {
        clip: rect(17px, 9999px, 55px, 0)
    }

    85% {
        clip: rect(32px, 9999px, 22px, 0)
    }

    90% {
        clip: rect(90px, 9999px, 18px, 0)
    }

    95% {
        clip: rect(22px, 9999px, 64px, 0)
    }

    100% {
        clip: rect(25px, 9999px, 18px, 0)
    }
}

@-webkit-keyframes noise-anim-2 {
    0% {
        clip: rect(85px, 9999px, 6px, 0)
    }

    5% {
        clip: rect(49px, 9999px, 81px, 0)
    }

    10% {
        clip: rect(54px, 9999px, 63px, 0)
    }

    15% {
        clip: rect(76px, 9999px, 70px, 0)
    }

    20% {
        clip: rect(94px, 9999px, 89px, 0)
    }

    25% {
        clip: rect(3px, 9999px, 88px, 0)
    }

    30% {
        clip: rect(31px, 9999px, 31px, 0)
    }

    35% {
        clip: rect(18px, 9999px, 2px, 0)
    }

    40% {
        clip: rect(55px, 9999px, 53px, 0)
    }

    45% {
        clip: rect(38px, 9999px, 19px, 0)
    }

    50% {
        clip: rect(3px, 9999px, 9px, 0)
    }

    55% {
        clip: rect(32px, 9999px, 67px, 0)
    }

    60% {
        clip: rect(56px, 9999px, 55px, 0)
    }

    65% {
        clip: rect(71px, 9999px, 63px, 0)
    }

    70% {
        clip: rect(99px, 9999px, 63px, 0)
    }

    75% {
        clip: rect(75px, 9999px, 91px, 0)
    }

    80% {
        clip: rect(93px, 9999px, 20px, 0)
    }

    85% {
        clip: rect(45px, 9999px, 99px, 0)
    }

    90% {
        clip: rect(74px, 9999px, 92px, 0)
    }

    95% {
        clip: rect(22px, 9999px, 43px, 0)
    }

    100% {
        clip: rect(21px, 9999px, 96px, 0)
    }
}

@keyframes noise-anim-2 {
    0% {
        clip: rect(85px, 9999px, 6px, 0)
    }

    5% {
        clip: rect(49px, 9999px, 81px, 0)
    }

    10% {
        clip: rect(54px, 9999px, 63px, 0)
    }

    15% {
        clip: rect(76px, 9999px, 70px, 0)
    }

    20% {
        clip: rect(94px, 9999px, 89px, 0)
    }

    25% {
        clip: rect(3px, 9999px, 88px, 0)
    }

    30% {
        clip: rect(31px, 9999px, 31px, 0)
    }

    35% {
        clip: rect(18px, 9999px, 2px, 0)
    }

    40% {
        clip: rect(55px, 9999px, 53px, 0)
    }

    45% {
        clip: rect(38px, 9999px, 19px, 0)
    }

    50% {
        clip: rect(3px, 9999px, 9px, 0)
    }

    55% {
        clip: rect(32px, 9999px, 67px, 0)
    }

    60% {
        clip: rect(56px, 9999px, 55px, 0)
    }

    65% {
        clip: rect(71px, 9999px, 63px, 0)
    }

    70% {
        clip: rect(99px, 9999px, 63px, 0)
    }

    75% {
        clip: rect(75px, 9999px, 91px, 0)
    }

    80% {
        clip: rect(93px, 9999px, 20px, 0)
    }

    85% {
        clip: rect(45px, 9999px, 99px, 0)
    }

    90% {
        clip: rect(74px, 9999px, 92px, 0)
    }

    95% {
        clip: rect(22px, 9999px, 43px, 0)
    }

    100% {
        clip: rect(21px, 9999px, 96px, 0)
    }
}


.WedoArea .WedoFooter .WedoTop {
    margin: 0 0 50px 0
}

.WedoArea .WedoFooter .WedoTop ul {
    display: flex;
    justify-content: space-between
}

.WedoArea .WedoFooter .WedoTop ul li span {
    display: block;
    width: 80px;
    margin: auto
}

.WedoArea .WedoFooter .WedoTop ul li p {
    text-align: center;
    font-family: 'Alegreya SC', serif;
    margin: 10px 0 0 0;
    font-size: 18px;
    font-weight: 500
}

.WedoArea .WedoFooter .WedoBottom .Tabs li {
    list-style: none;
    display: inline-block;
    font-size: 18px;
    padding: 10px 40px;
    border: 1px solid #27a4dc;
    border-radius: 30px;
    margin-right: 8px;
    color: #27a4dc;
    cursor: pointer;
    transition: all 0.2s ease-in-out
}

.WedoArea .WedoFooter .WedoBottom .Tabs li.active {
    background-color: #27a4dc;
    color: #fff
}

.WedoArea .WedoFooter .WedoBottom .content {
    padding: 25px 25px 15px 25px;
    border: 1px solid #ccc;
    margin-top: 30px;
    display: none;
    position: relative
}

.WedoArea .WedoFooter .WedoBottom .content:before {
    content: '';
    position: absolute;
    border-top: 20px solid transparent;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    border-bottom: 20px solid #fff;
    top: -40px;
    z-index: 1;
    left: 17%
}

.WedoArea .WedoFooter .WedoBottom .content:after {
    content: '';
    position: absolute;
    border-top: 20px solid transparent;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    top: -41px;
    border-bottom: 20px solid #ccc;
    left: 17%
}

.WedoArea .WedoFooter .WedoBottom #category:before,
.WedoArea .WedoFooter .WedoBottom #category:after {
    left: 35%
}

.WedoArea .WedoFooter .WedoBottom .content ul li {
    list-style: none;
    display: inline-block;
    padding: 10px 25px;
    border: 1px solid #ccc;
    font-size: 16px;
    color: #000;
    margin: 0 10px 10px 0;
    cursor: pointer
}

.PortfolioArea {
    padding: 50px 0 0
}

.PortfolioBody.col-md-6 {
    display: none
}

.PortfolioBox {
    position: relative;
    overflow: hidden;
    margin: 0 0 50px 0
}

.PortfolioBox figure {
    overflow: hidden;
    height: 420px
}

.PortfolioBox figure img {
    transition: 0.5s all ease-in-out;
    width: 100%;
    height: 100%
}

.PortfolioBox figcaption {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    transition: 0.5s all ease-in-out;
    transform: translate(0, 100%)
}

.PortfolioBox figcaption h5 {
    font-family: 'Alegreya SC', serif;
    color: #fff;
    font-size: 40px;
    margin: 0 0 10px 0
}

.PortfolioBox figcaption h6 {
    color: #fff;
    font-size: 15px;
    font-family: 'Roboto';
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .5px
}

.PortfolioBox:hover figure {
    background-color: #0a1f48
}

.PortfolioBox:hover figure img {
    opacity: .4;
    transform: scale(1.1)
}

.PortfolioBox:hover figcaption {
    transform: translate(0, 0)
}

.PortfolioFooter {
    text-align: center
}

.PortfolioFooter a {
    padding: 10px 30px;
    border: 2px solid #55aae0;
    display: inline-block;
    color: #55aae0;
    font-family: 'Roboto';
    border-radius: 50px;
    font-size: 17px
}

.PortfolioFooter a:hover {
    background-color: #55aae0;
    color: #fff
}

.GalleryArea {
    background-color: #000;
    padding: 50px 15px 30px;
    min-height: 500px
}

.GalleryArea .GalleryHead {
    text-align: center;
    margin: 0 0 50px 0
}

.GalleryArea .GalleryHead ul {
    width: 80%;
    margin: auto
}

.GalleryArea .GalleryHead ul li {
    display: inline-block;
    padding: 10px 25px;
    font-family: 'Roboto';
    color: #ccc;
    transition: 0.5s all ease-in-out;
    cursor: pointer
}

.GalleryArea .GalleryHead ul li.active {
    background-color: #55aae0;
    color: #fff;
    border-radius: 5px
}

.GalleryBox {
    position: relative;
    margin: 0 0 30px 0;
    overflow: hidden;
    cursor: pointer
}

.GalleryBox .CaseStudy {
    width: 40px;
    height: 40px;
    color: #fff;
    display: block;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
    line-height: 36px;
    transition: 0.5s all ease-in-out;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
    background-color: #55aae0;
    transform: scale(0)
}

.GalleryBox figure {
    background-color: #000
}

.GalleryBox figure img {
    transition: 0.5s all ease-in-out;
    width: 100%
}

.GalleryBox figcaption {
    position: absolute;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    padding: 15px 15px;
    transform: translate(0, 110%);
    transition: 0.5s all ease-in-out;
    cursor: pointer
}

.GalleryBox:hover .CaseStudy {
    transform: scale(1)
}

.GalleryBox:hover figure img {
    opacity: .5
}

.GalleryBox:hover figcaption {
    transform: translate(0, 0%)
}

.GalleryBox figcaption ul li:first-child {
    font-family: 'Alegreya SC', sans-serif;
    color: #000;
    font-weight: 500;
    font-size: 17px;
    display: block;
    margin: 0 0 5px 0
}

.GalleryBox figcaption ul li {
    display: inline-block;
    margin: 0 9px 0 0
}

.GalleryBox figcaption ul li a {
    display: block
}

/* ********************************* Portfolio Details Page CSS ********************************* */


.DetailsArea {
    padding: 100px 0 0 0
}

.DetailsArea .DetailsHead {
    margin: 0 0 50px;
    font-family: 'Alegreya SC', serif
}

.DetailsArea .DetailsHead h2 {
    position: relative;
    margin: 0 0 15px 0;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    color: #464646;
    font-size: 40px;
    letter-spacing: .5px;
    line-height: 35px
}

.DetailsArea .DetailsHead h2 span {
    color: #26a9e1
}

.DetailsArea .DetailsHead p {
    font-weight: 400;
    font-size: 16px;
    color: #000;
    line-height: 30px;
    text-align: center;
    font-family: 'Roboto';
    margin: 0 0 40px 0
}

.DetailsArea .DetailsBody .DetailsLeft h3 {
    font-family: 'Alegreya SC', serif;
    font-weight: 600;
    margin: 0 0 10px 0;
    font-size: 32px
}

.DetailsArea .DetailsBody .DetailsLeft p {
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 30px;
    font-weight: 400
}

.DetailsArea .DetailsBody .DetailsLeft p+p {
    margin-top: 10px
}

.DetailsArea .DetailsBody .DetailsLeft aside {
    margin: 20px 0 0 0
}

.DetailsArea .DetailsBody .DetailsLeft aside h4 {
    font-family: 'Alegreya SC', serif;
    font-weight: 600;
    margin: 0 0 15px 0;
    font-size: 25px;
    line-height: 20px
}

.DetailsArea .DetailsBody .DetailsLeft aside .CNBC {
    display: inline-block;
    width: 100px
}

.DetailsArea .DetailsBody .DetailsLeft aside ul {
    padding: 0 0 0 15px;
    list-style: disc
}

.DetailsArea .DetailsBody .DetailsLeft aside ul li {
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    list-style: disc;
    margin: 0 0 10px 0
}

.DetailsArea .DetailsBody .DetailsRight {
    padding: 0 0 0 30px
}

.DetailsArea .DetailsBody .DetailsRight h3 {
    font-family: 'Alegreya SC', serif;
    font-weight: 600;
    margin: 0;
    font-size: 32px
}

.DetailsArea .DetailsBody .DetailsRight aside {
    border-top: 1px solid #ddd;
    margin: 20px 0 0 0;
    padding: 20px 0 0 0
}

.DetailsArea .DetailsBody .DetailsRight aside h4 {
    font-family: 'Alegreya SC', serif;
    font-weight: 600;
    margin: 0 0 15px 0;
    font-size: 25px;
    line-height: 20px
}

.DetailsArea .DetailsBody .DetailsRight aside p {
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 30px;
    font-weight: 400
}

.DetailsArea .DetailsBody .DetailsRight aside ul {
    display: flex
}

.DetailsArea .DetailsBody .DetailsRight aside ul li {
    margin: 0 20px 0 0
}

.DetailsArea .DetailsBody .DetailsRight aside ul li:last-child {
    margin: 0
}

.DetailsArea .DetailsBody .DetailsRight aside ul li span.Icon {
    margin: auto;
    display: block;
    text-align: center
}

.DetailsArea .DetailsBody .DetailsRight aside ul li span.Icon img {
    width: 35px
}

.DetailsArea .DetailsBody .DetailsRight aside ul li span.Icon img.Full {
    width: 100%
}

.DetailsArea .DetailsBody .DetailsRight aside ul li span.Text {
    font-size: 17px;
    line-height: 20px;
    font-weight: 500;
    display: block;
    text-align: center;
    margin: 9px 0 0 0;
    font-family: 'Alegreya SC', serif
}

.DetailsArea .DetailsBody .DetailsRight aside ul li span.Icon a {
    display: block
}

/* ********************************* Single Services CSS ********************************* */


.ServiceArea {
    padding: 50px 0 0
}

.ServiceArea .ServiceBox {
    text-align: center;
    width: 200px;
    margin: 0 auto 60px
}

.ServiceArea .ServiceBox figure {
    border-radius: 10px;
    background-color: #f7f7f7;
    width: 180px;
    height: 180px;
    display: inline-flex;
    align-items: center;
    justify-content: center
}

.ServiceArea .ServiceBox figure:before {
    content: '';
    width: 75px;
    height: 75px;
    display: block;
    border-radius: 50%;
    top: 2rem;
    right: 2rem;
    position: absolute;
    z-index: 0;
    transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in
}

.ServiceArea .ServiceBox.Service2 {
    margin: 60px auto 60px
}

.ServiceArea .ServiceBox.Service4 {
    margin: 60px auto 60px
}

.ServiceArea .ServiceBox.Service1 figure:before {
    background-color: #f8cc33
}

.ServiceArea .ServiceBox.Service2 figure:before {
    background-color: #87f0e6
}

.ServiceArea .ServiceBox.Service3 figure:before {
    background-color: #ff7173
}

.ServiceArea .ServiceBox.Service4 figure:before {
    background-color: #55aae1
}

.ServiceArea .ServiceBox figure img {
    width: 90px;
    filter: brightness(0)
}

.ServiceArea .ServiceBox figcaption {
    -webkit-transform: translate(-30px, -20px);
    text-align: left;
    margin: 0 -50px 0 0
}

.ServiceArea .ServiceBox figcaption h5 {
    font-family: 'Alegreya SC', serif;
    font-size: 23px;
    font-weight: 600
}

.ServiceArea .ServiceBox figcaption p {
    font-size: 15px;
    font-family: 'Roboto';
    font-weight: 400
}

.ServiceAppArea .ServiceAppBody .ServiceImages {
    background-color: #000
}

.ServiceAppArea .ServiceAppBody .ServiceImages img {
    opacity: .7
}

.ServiceAppArea .ServiceAppBody .ServiceContent {
    height: 100%;
    padding: 0 50px
}

.ServiceAppArea .ServiceAppBody .ServiceContent h3 {
    font-family: 'Alegreya SC', serif;
    font-size: 36px;
    margin: 0 0 15px 0;
    font-weight: 600;
    color: #000
}

.ServiceAppArea .ServiceAppBody .ServiceContent p {
    font-size: 15px;
    font-family: 'Roboto';
    line-height: 28px;
    margin: 0 0 15px 0
}

.ServiceAppArea .ServiceAppBody .ServiceContent a {
    background-color: #3f95cc;
    color: #fff;
    padding: 10px 25px;
    display: inline-block;
    font-size: 15px;
    border-radius: 5px;
    opacity: 1;
    transition: 0.5s all ease-in-out;
    font-family: 'Roboto';
    text-transform: capitalize;
    font-weight: 400
}

.TechnologyArea {
    background-color: whitesmoke;
    padding: 50px 0 30px
}

.TechnologyArea .TechnologyBody ul {
    display: flex;
    flex-wrap: wrap
}

.TechnologyArea .TechnologyBody ul li {
    width: 20%;
    padding: 0 10px
}

.TechnologyBox {
    background-color: #fff;
    margin: 0 0 30px 0;
    min-height: 180px;
    padding: 25px 15px;
    border-radius: 10px;
    box-shadow: 0 0 6px #ddd;
    cursor: pointer;
    transition: 0.5s all ease-in-out
}

.TechnologyBox:hover {
    transform: translate(0, -10px)
}

.TechnologyBox figure {
    width: 80px;
    margin: 0 auto 15px
}

.TechnologyBox h5 {
    font-family: 'Alegreya SC', serif;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px
}

/* ********************************* Mobile App Development CSS ********************************* */

.MobileAppArea {
    position: relative;
    background: #f9fbff none repeat scroll 0 0;
    padding: 80px 0
}

.MobileAppArea .MobileAppLeft {
    padding: 100px 0
}

.MobileAppArea .MobileAppLeft h1 {
    color: #000;
    font-size: 46px;
    font-weight: 700;
    font-family: 'Alegreya SC', serif;
    margin: 0 0 10px 0
}

.MobileAppArea .MobileAppLeft p {
    font-size: 16px;
    line-height: 30px;
    margin: 0 0 20px 0;
    font-family: 'Roboto'
}

.MobileAppArea .MobileAppLeft a {
    padding: 10px 20px;
    background-color: #26a9e1;
    display: inline-flex;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto;
    letter-spacing: .5px;
    align-items: center
}

.MobileAppArea .MobileAppLeft a span {
    width: 18px;
    height: 18px;
    display: inline-block;
    background-color: #26a9e1;
    border-radius: 50%;
    border: 4px solid #fff;
    margin: 0 10px 0 0
}

.MobileAppArea .MobileAppRight {
    text-align: center;
    z-index: 9;
    position: relative
}

.MobileAppArea .MobileAppRight img {
    width: 250px
}

.MobileAppArea .MobileAppRight.Website img {
    width: 90%
}

.MobileAppArea .MobileAppShape {
    position: absolute;
    left: 46%;
    width: 725px;
    height: 705px;
    background: url(https://mobulous.s3.ap-south-1.amazonaws.com/Web/images/main/Mobile-shape.png) no-repeat center center / auto;
    z-index: 0;
    bottom: 0;
    margin: auto;
    top: 40px
}

.ConsultationArea {
    padding: 50px 0
}

.ConsultationArea .ConsultationLeft h2 {
    color: #383838;
    font-size: 30px;
    font-weight: 800;
    font-family: 'Alegreya SC', serif;
    padding: 0 0 10px 0;
    letter-spacing: .5px;
    margin: 0 0 15px 0;
    position: relative
}

.ConsultationArea .ConsultationLeft h2:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 110px;
    height: 5px;
    background-color: #26a9e1
}

.ConsultationArea .ConsultationLeft p {
    margin: 0 0 15px 0;
    font-family: Roboto;
    line-height: 28px;
    font-size: 15px
}

.ConsultationArea .ConsultationLeft h4 {
    font-family: 'Alegreya SC', serif;
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 10px 0;
    color: #26a9e1
}

.ConsultationArea .ConsultationLeft ul {
    padding: 0 0 0 20px
}

.ConsultationArea .ConsultationLeft ul li {
    font-size: 16px;
    font-family: Roboto;
    list-style: disc;
    margin: 0 0 10px
}

.ConsultationArea .ConsultationRight {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 46px 0 rgba(0, 0, 0, .06);
    border: 1px solid #26a9e1;
    margin: 0 0 0 50px;
    padding: 35px 25px
}

.ConsultationArea .ConsultationRight h3 {
    color: #383838;
    font-size: 30px;
    font-weight: 900;
    font-family: 'Alegreya SC', serif;
    letter-spacing: .5px;
    margin: 0 0 5px 0;
    position: relative;
    text-align: center
}

.ConsultationArea .ConsultationRight p {
    font-size: 17px;
    font-family: Roboto;
    text-align: center
}

.ConsultationArea .ConsultationRight form {
    margin: 30px 0 0 0
}

.ConsultationArea .ConsultationRight form .form-group {
    position: relative
}

.ConsultationArea .ConsultationRight form .form-group .Icon {
    position: absolute;
    top: 0;
    width: 35px;
    height: 45px;
    text-align: center;
    font-size: 15px;
    padding: 13px 0
}

.ConsultationArea .ConsultationRight form .form-group .form-control {
    box-shadow: none;
    border-radius: 0;
    height: 45px;
    padding: 11px 0 10px 40px;
    font-family: Roboto
}

.ConsultationArea .ConsultationRight form .form-group textarea.form-control {
    height: auto;
    padding: 10px 10px
}

.ConsultationArea .ConsultationRight form .checkbox {
    margin: 0 0 15px 0
}

.ConsultationArea .ConsultationRight form .checkbox label {
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    color: #4f4e4e;
    padding: 0 0 0 25px
}

.ConsultationArea .ConsultationRight form .checkbox label input {
    margin: 0;
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px
}

.ConsultationArea .ConsultationRight form button {
    border: none;
    padding: 10px 0;
    transition: 0.5s all ease-in-out;
    font-weight: 600;
    position: relative;
    background-color: #2088c9;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .5px;
    outline: 0;
    width: 100%;
    text-align: center
}

.MobileSuccessArea {
    background-color: #f7f7f7;
    padding: 50px 0
}

.MobileSuccessArea ul {
    display: flex
}

.MobileSuccessArea ul li {
    padding: 0 15px;
    width: 100%
}

.MobileSuccessArea ul li figure {
    width: 150px;
    height: 150px;
    margin: 0 auto 0
}

.MobileSuccessArea ul li p {
    font-family: Roboto;
    text-align: center;
    font-size: 16px;
    line-height: 28px
}

.MobileServiceArea {
    padding: 50px 0 30px
}

.MobileServiceArea h2 {
    color: #383838;
    font-size: 35px;
    font-weight: 800;
    font-family: 'Alegreya SC', serif;
    padding: 0 0 15px 0;
    letter-spacing: .5px;
    margin: 0 0 50px 0;
    position: relative;
    text-align: center
}

.MobileServiceArea h2:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 110px;
    height: 5px;
    background-color: #26a9e1;
    right: 0;
    margin: auto
}

.MobileServiceArea h2+p {
    font-size: 15px;
    font-family: Roboto;
    color: #666;
    line-height: 29px;
    margin: -20px auto 50px auto;
    text-align: center;
    width: 85%
}

.MobileServiceArea .MobileServiceBox {
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0 1px 16px 0 rgb(0 0 0 / 6%);
    transition: all 0.3s linear 0s;
    padding: 20px;
    margin: 0 0 30px 0;
    display: block;
    text-align: center;
    height: calc(100% - 30px);
}

.MobileServiceArea .MobileServiceBox:hover {
    box-shadow: 0 1px 19px 0 #a8abad80
}

.MobileServiceArea .MobileServiceBox figure {
    width: 100px;
    height: 100px;
    margin: 0 auto 20px
}

.MobileServiceArea .MobileServiceBox figure img {
    max-width: 100%;
    max-height: 100%;
    width: auto
}

.MobileServiceArea .MobileServiceBox p.ServiceTitle {
    color: #383838;
    font-size: 25px;
    font-weight: 800;
    font-family: 'Alegreya SC', serif;
    letter-spacing: .5px;
    margin: 0 auto 15px;
    position: relative;
    text-align: center
}

.MobileServiceArea .MobileServiceBox p {
    font-size: 14px;
    font-family: Roboto;
    color: #666;
    line-height: 29px
}

.TalkArea {
    background-image: url(https://mobulous.s3.ap-south-1.amazonaws.com/website/images/Slider-1.png);
    padding: 40px 0;
    position: relative;
    background-position: 0 -230px
}

.TalkArea:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 78%)
}

.TalkArea .TalkBox {
    max-width: 75%;
    margin: auto;
    display: flex;
    position: relative;
    z-index: 9
}

.TalkArea .TalkBox figure {
    width: 215px;
    height: 215px;
    border-radius: 50%;
    border: 5px solid #fff;
    overflow: hidden
}

.TalkArea .TalkBox figure img {
    max-height: 100%;
    min-height: 100%
}

.TalkArea .TalkBox figcaption {
    width: 75%;
    padding: 40px 0 40px 50px
}

.TalkArea .TalkBox figcaption p {
    font-size: 24px;
    color: #fff;
    font-family: Roboto;
    font-weight: 500;
    margin: 0 0 15px
}

.TalkArea .TalkBox figcaption a {
    padding: 10px 40px;
    transition: 0.5s all ease-in-out;
    font-weight: 600;
    position: relative;
    background-color: #2088c9;
    border: 2px solid #2088c9;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    font-family: Roboto;
    border-radius: 5px
}

.TalkArea .TalkBox figcaption a:hover {
    background-color: #fff;
    color: #2088c9
}

.ExploreArea {
    padding: 50px 0
}

.ExploreArea h2 {
    color: #383838;
    font-size: 35px;
    font-weight: 800;
    font-family: 'Alegreya SC', serif;
    padding: 0 0 15px 0;
    letter-spacing: .5px;
    margin: 0 0 50px 0;
    position: relative;
    text-align: center
}

.ExploreArea h2:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 110px;
    height: 5px;
    background-color: #26a9e1;
    right: 0;
    margin: auto
}

.ExploreArea .ExploreBox {
    margin: 0 0 30px 0
}

.ExploreArea .ExploreBox figure {
    overflow: hidden;
    margin: 0 0 15px 0;
    background-color: #000
}

.ExploreArea .ExploreBox figure img {
    transition: all 0.3s linear 0s;
    transform: scale(1) rotate(0);
    opacity: .9;
    width: 100%
}

.ExploreArea .ExploreBox p.ExploreTitle {
    margin: 0 0 15px 0;
    padding: 0 0 9px 0;
    position: relative
}

.ExploreArea .ExploreBox p.ExploreTitle:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background-color: #26a9e1
}

.ExploreArea .ExploreBox p.ExploreTitle a {
    font-family: 'Alegreya SC', serif;
    font-weight: 600;
    font-size: 21px;
    color: #000;
    letter-spacing: .5px
}

.ExploreArea .ExploreBox p.ExploreTitle a:hover {
    color: #2088c9
}

.ExploreArea .ExploreBox p.ExploreTitle span {
    float: right;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    background-color: #2088c9;
    color: #fff;
    line-height: 40px;
    margin: -5px 0 0 0
}

.ExploreArea .ExploreBox p {
    font-size: 15px;
    font-family: Roboto;
    color: #666;
    line-height: 28px
}

.ExploreArea .ExploreBox:hover figure img {
    transform: scale(1.15) rotate(5deg);
    opacity: 1
}

.ExploreArea .ExploreFooter {
    text-align: center
}

.ExploreArea .ExploreFooter h2 {
    font-family: 'Alegreya SC', serif;
    font-weight: 600;
    font-size: 30px;
    margin: 0 0 20px 0
}

.ExploreArea .ExploreFooter a {
    padding: 10px 40px;
    transition: 0.5s all ease-in-out;
    font-weight: 600;
    position: relative;
    background-color: #2088c9;
    border: 2px solid #2088c9;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    font-family: Roboto;
    border-radius: 5px
}

.ExploreArea .ExploreFooter a:hover {
    background-color: #fff;
    color: #2088c9
}

.StatisticsWorkArea {
    position: relative;
    overflow: hidden;
    padding: 50px 0 50px 0
}

.StatisticsWorkArea .StatisticsWorkBox {
    position: relative
}

.StatisticsWorkArea .StatisticsWorkBox:before {
    content: '';
    height: calc(100% - 60px);
    width: 2000px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    background: #eee;
    position: absolute;
    z-index: -1
}

.StatisticsWorkArea .StatisticsWorkBox ul {
    border: 10px solid #eee;
    padding: 10px;
    text-align: center;
    z-index: 0;
    background: #fff;
    margin: 0;
    display: flex
}

.StatisticsWorkArea .StatisticsWorkBox ul li {
    padding: 0 15px
}

.StatisticsWorkArea .StatisticsWorkBox ul li figure {
    margin: 0 0 10px 0
}

.StatisticsWorkArea .StatisticsWorkBox ul li p {
    font-weight: 600;
    font-size: 15px
}

.CustomizedArea {
    padding: 0 0 50px 0
}

.CustomizedArea h2 {
    color: #383838;
    font-size: 35px;
    font-weight: 800;
    font-family: 'Alegreya SC', serif;
    padding: 0 0 15px 0;
    letter-spacing: .5px;
    margin: 0 0 50px 0;
    position: relative;
    text-align: center
}

.CustomizedArea h2:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 110px;
    height: 5px;
    background-color: #26a9e1;
    right: 0;
    margin: auto
}

.CustomizedArea .CustomizedSlider .CustomizedBox figure {
    height: 325px;
    overflow: hidden
}

.CustomizedArea .CustomizedSlider .CustomizedBox figcaption {
    border-radius: 8px;
    background-color: rgba(255, 255, 255, .9);
    box-shadow: 0 0 29.1px .9px rgba(0, 0, 0, .2);
    height: 80px;
    margin: -40px 20px 20px;
    z-index: 1;
    position: relative;
    overflow: hidden
}

.CustomizedArea .CustomizedSlider .CustomizedBox figcaption a {
    font-family: 'Alegreya SC', serif;
    color: #000;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    display: block;
    letter-spacing: .5px;
    padding: 16px
}

.CustomizedArea .CustomizedSlider .CustomizedBox figcaption a:hover {
    background-color: #2088c9;
    color: #fff
}

.CustomizedArea .CustomizedSlider .owl-nav .owl-prev,
.CustomizedArea .CustomizedSlider .owl-nav .owl-next {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    font-size: 25px;
    color: #26a9e1;
    border: 3px solid #e1e1e1;
    outline: 0
}

.CustomizedArea .CustomizedSlider .owl-nav .owl-prev:hover,
.CustomizedArea .CustomizedSlider .owl-nav .owl-next:hover {
    background-color: #26a9e1;
    border-color: #fff;
    color: #fff
}

.CustomizedArea .CustomizedSlider .owl-nav .owl-prev {
    left: -25px
}

.CustomizedArea .CustomizedSlider .owl-nav .owl-next {
    right: -25px
}

.CustomizedArea .CustomizedSlider a.Quote {
    padding: 10px 0;
    transition: 0.5s all ease-in-out;
    font-weight: 600;
    position: relative;
    background-color: #2088c9;
    border: 2px solid #2088c9;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: block;
    font-family: Roboto;
    border-radius: 5px;
    max-width: 200px;
    text-align: center;
    margin: 20px auto 0
}

.CustomizedArea .CustomizedSlider a.Quote:hover {
    background-color: #fff;
    color: #2088c9
}

.IndustriesArea {
    background-color: #f7f7f7;
    padding: 50px 0 10px
}

.IndustriesArea h2 {
    color: #383838;
    font-size: 35px;
    font-weight: 800;
    font-family: 'Alegreya SC', serif;
    padding: 0 0 15px 0;
    letter-spacing: .5px;
    margin: 0 0 50px 0;
    position: relative;
    text-align: center
}

.IndustriesArea h2:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 110px;
    height: 5px;
    background-color: #26a9e1;
    right: 0;
    margin: auto
}

.IndustriesArea .IndustriesBox {
    margin: 0 0 40px 0
}

.IndustriesArea .IndustriesBox figure {
    position: relative;
    background-color: #000;
    border-bottom: 5px solid #2088c9;
    overflow: hidden;
    height: 240px
}

.IndustriesArea .IndustriesBox figure img {
    opacity: .85;
    transition: 0.5s all ease-in-out;
    width: 100%
}

.IndustriesArea .IndustriesBox figure span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    height: 60px
}

.IndustriesArea .IndustriesBox figure span img {
    opacity: 1
}

.IndustriesArea .IndustriesBox figcaption {
    text-align: center;
    padding: 10px 0 0 0
}

.IndustriesArea .IndustriesBox figcaption p {
    font-family: 'Alegreya SC', serif;
    font-weight: 600;
    font-size: 22px;
    color: #000;
    letter-spacing: .5px
}

.IndustriesArea .IndustriesBox:hover figure img {
    transform: scale(1.1)
}

.IndustriesArea .IndustriesBox:hover figure span img {
    transform: scale(1)
}

.ProcessArea {
    border-bottom: 2px solid #26a9e1;
    padding: 50px 0 0 0;
    margin: 0 0 50px 0
}

.ProcessArea h2 {
    color: #383838;
    font-size: 35px;
    font-weight: 800;
    font-family: 'Alegreya SC', serif;
    padding: 0 0 15px 0;
    letter-spacing: .5px;
    margin: 0 0 50px 0;
    position: relative;
    text-align: center
}

.ProcessArea h2:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 110px;
    height: 5px;
    background-color: #26a9e1;
    right: 0;
    margin: auto
}

.ProcessArea article {
    display: flex
}

.ProcessArea article aside {
    position: relative;
    width: 100%;
    padding: 0 15px 0 0
}

.ProcessArea article aside:before {
    position: absolute;
    left: 34px;
    top: 0;
    height: 100%;
    width: 2px;
    content: "";
    background: #26a9e1;
    z-index: -1
}

.ProcessArea article aside figure {
    height: 60px;
    width: 60px;
    text-align: center;
    border-radius: 50%;
    background: #26a9e1;
    display: inline-block;
    position: relative;
    padding: 15px 0
}

.ProcessArea article aside figure span {
    position: absolute;
    top: -10px;
    left: -8px;
    width: 30px;
    height: 30px;
    background-color: #26a9e1;
    border: 3px solid #fff;
    border-radius: 50%;
    color: #fff;
    font-family: 'Alegreya SC', serif;
    font-weight: 600;
    font-size: 16px
}

.ProcessArea article aside figure img {
    max-width: 30px;
    max-height: 30px
}

.ProcessArea article aside p {
    display: inline-block;
    width: calc(100% - 75px);
    padding-left: 5px;
    vertical-align: middle;
    font-family: 'Alegreya SC', serif;
    font-weight: 600;
    color: #000;
    font-size: 15px
}

.ProcessArea article aside ul {
    padding: 20px 0 0 30px;
    margin: 0
}

.ProcessArea article aside ul li {
    position: relative;
    padding: 0 0 11px 25px;
    text-align: left;
    font-family: Roboto;
    color: #000;
    font-size: 14px
}

.ProcessArea article aside ul li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #26a9e1
}

.TechnologiesArea {
    padding: 50px 0 50px;
    background-color: #f7f7f7;
    text-align: center
}

.TechnologiesArea h2 {
    color: #383838;
    font-size: 35px;
    font-weight: 800;
    font-family: 'Alegreya SC', serif;
    padding: 0 0 15px 0;
    letter-spacing: .5px;
    margin: 0 0 10px 0;
    position: relative;
    text-align: center
}

.TechnologiesArea h2:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 110px;
    height: 5px;
    background-color: #26a9e1;
    right: 0;
    margin: auto
}

.TechnologiesArea h2+p {
    font-size: 15px;
    font-family: Roboto;
    color: #000;
    line-height: 28px;
    margin: 0 0 50px 0
}

.TechnologiesArea .TechnologiesBox {
    text-align: center;
    margin: 0 0 30px 0;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 0px 5px #ddd;
    border-radius: 15px;
    height: calc(100% - 30px);
}

.TechnologiesArea .TechnologiesBox:hover {}

.TechnologiesArea .TechnologiesBox figure {
    width: 100px;
    height: 100px;
    margin: 0 auto 20px
}

.TechnologiesArea .TechnologiesBox figure img {
    width: 100%
}

.TechnologiesArea .TechnologiesBox p.TechnologieTitle {
    font-family: 'Alegreya SC', serif;
    font-weight: 600;
    color: #000;
    letter-spacing: .5px;
    font-size: 22px;
    margin: 0 auto 15px
}

.TechnologiesArea .TechnologiesBox p {
    font-size: 14px;
    font-family: Roboto;
    color: #000;
    line-height: 28px;
    margin: 0
}

.TechnologiesArea a {
    padding: 10px 40px;
    transition: 0.5s all ease-in-out;
    font-weight: 600;
    position: relative;
    background-color: #2088c9;
    border: 2px solid #2088c9;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    font-family: Roboto;
    border-radius: 5px;
    text-align: center;
    margin: 20px auto 0
}

.TechnologiesArea a:hover {
    background-color: #fff;
    color: #2088c9
}

.ValuableArea {
    padding: 50px 0 30px
}

.ValuableArea h2 {
    color: #383838;
    font-size: 35px;
    font-weight: 800;
    font-family: 'Alegreya SC', serif;
    padding: 0 0 15px 0;
    letter-spacing: .5px;
    margin: 0 0 40px 0;
    position: relative;
    text-align: center
}

.ValuableArea h2:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 110px;
    height: 5px;
    background-color: #26a9e1;
    right: 0;
    margin: auto
}

.ValuableArea .ValuableBox {
    position: relative;
    z-index: 0;
    height: 265px;
    width: 265px;
    text-align: center;
    margin: 15px auto;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center
}

.ValuableArea .ValuableBox:before {
    content: '';
    position: absolute;
    border: 2px solid;
    top: 15px;
    left: 15px;
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    opacity: 0;
    transform: scale(1.1);
    transition: all 0.3s linear 0s;
    pointer-events: none;
    border-radius: 5px
}

.ValuableArea .ValuableBox figure {
    display: inline-block;
    vertical-align: middle;
    width: 240px;
    margin: 0
}

.ValuableArea .ValuableBox figure img {
    width: 100%
}

.ValuableArea .ValuableBox:hover:before {
    opacity: 1;
    transform: scale(1)
}

.Valuable1 {
    background-color: rgb(51 111 79 / 15%);
    color: rgb(51 111 78);
}

.Valuable2 {
    background-color: rgb(164 30 29 / 10%);
    color: rgb(164 30 29);
}

.Valuable3 {
    background-color: rgb(85 178 100 / 10%);
    color: rgb(84 177 97);
}

.Valuable4 {
    background-color: rgb(237 151 48 / 10%);
    color: rgb(248 219 184);
}

.Valuable5 {
    background-color: rgb(231 76 43 / 10%);
    color: rgb(231 76 43);
}

.Valuable6 {
    background-color: rgba(221, 177, 2, .1);
    color: rgb(221, 177, 2)
}

.Valuable7 {
    background-color: rgb(29 57 151 / 10%);
    color: rgb(29 57 151);
}

.Valuable8 {
    background-color: rgb(180 183 14 / 10%);
    color: rgb(180 183 14);
}


.MobileFaqArea {
    padding: 0 0 30px 0
}

.MobileFaqArea h3 {
    color: #383838;
    font-size: 35px;
    font-weight: 800;
    font-family: 'Alegreya SC', serif;
    padding: 0 0 15px 0;
    letter-spacing: .5px;
    margin: 0 0 40px 0;
    position: relative;
    text-align: center
}

.MobileFaqArea h3:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 110px;
    height: 5px;
    background-color: #26a9e1;
    right: 0;
    margin: auto
}

.MobileFaqArea .MobileFaqBox .panel-group .panel {
    border: none;
    background-color: rgb(248, 248, 248)
}

.MobileFaqArea .MobileFaqBox .panel-group .panel+.panel {
    margin: 15px 0 0 0
}

.MobileFaqArea .MobileFaqBox .panel-group .panel .panel-heading {
    padding: 0;
    background-color: rgb(248, 248, 248);
    cursor: pointer
}

.MobileFaqArea .MobileFaqBox .panel-group .panel .panel-heading p {
    position: relative;
    font-size: 19px;
    font-weight: 700;
    color: #444;
    line-height: 1.2;
    display: block;
    padding: 20px 20px 20px 80px;
    font-family: 'Alegreya SC', serif
}

.MobileFaqArea .MobileFaqBox .panel-group .panel .panel-heading[data-toggle~="collapse"].collapsed p:before {
    content: "\f067"
}

.MobileFaqArea .MobileFaqBox .panel-group .panel .panel-heading[data-toggle~="collapse"] p:before {
    position: absolute;
    left: 20px;
    top: 13px;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    content: "\f068";
    font-family: fontAwesome;
    background: #2088c9;
    line-height: 35px;
    color: #FFF;
    text-align: center;
    font-size: 16px
}

.MobileFaqArea .MobileFaqBox .panel-group .panel .panel-body {
    padding: 0 20px 20px 25px
}

.MobileFaqArea .MobileFaqBox .panel-group .panel .panel-body p {
    font-size: 15px;
    font-family: Roboto;
    color: #000;
    line-height: 28px
}

.MobileFaqArea .MobileFaqBox .panel-group .panel .panel-body ul {
    padding: 10px 0 0 20px
}

.MobileFaqArea .MobileFaqBox .panel-group .panel .panel-body ul li {
    font-family: 'Roboto';
    font-size: 15px;
    margin: 0 0 10px 0;
    list-style: disc
}

.VideoClientArea {
    background-color: #f8f8f8;
    overflow: hidden;
    padding: 40px 0 40px 0
}

.VideoClientArea h4 {
    position: relative;
    margin: 0 0 50px 0;
    text-align: center
}

.VideoClientArea h4 span {
    font-family: 'Alegreya SC', serif;
    background-color: #ddd;
    z-index: 9;
    position: relative;
    font-weight: 600;
    padding: 10px 40px;
    display: inline-block;
    border-radius: 50px;
    font-size: 25px;
    letter-spacing: .5px
}

.VideoClientArea h4:after {
    content: '';
    position: absolute;
    left: -1500px;
    top: 50%;
    height: 2px;
    width: 3000px;
    background: #DDD;
    z-index: 1
}

.VideoClientArea .VideoClientBox {
    text-align: center;
    margin: 0 0 30px 0
}

.VideoClientArea .VideoClientBox:before {
    content: '';
    position: absolute;
    height: 100px;
    width: 239px;
    left: 50%;
    top: 50px;
    transform: translate(-50%, 0) rotate(-45deg);
    background: #2089c9;
    border-radius: 50px;
    pointer-events: none
}

.VideoClientArea .VideoClientBox a {
    display: inline-block;
    height: 200px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    background: #2089c9;
    border: 5px solid #2089c9;
    cursor: pointer
}

.VideoClientArea .VideoClientBox a img {
    width: 100%;
    height: 100%
}

.VideoClientArea .VideoClientBox a span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    margin: auto;
    color: #fff;
    font-size: 22px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center
}

.VideoClientArea .VideoClientBox a:hover span {
    background-color: #2089c9
}

.VideoClientArea .VideoClientBox p {
    margin: 15px 0 0 0;
    font-size: 22px;
    font-weight: 600;
    font-family: 'Alegreya SC', serif
}

.section {
    background-image: url(https://mobulous.s3.ap-south-1.amazonaws.com/Web/images/main/background.jpg);
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden
}

.FeatureSlide {
    background-size: cover;
    min-height: 100%;
    background-position: inherit;
    background-attachment: fixed
}

.FeatureSlide .FeatureContent {
    padding: 100px 30px 0
}

.FeatureSlide .FeatureContent .FeatureHead {
    display: flex;
    align-items: center;
    margin: 0 0 15px 0
}

.FeatureSlide .FeatureContent .FeatureHead .Icon {
    width: 90px;
    margin: 0 20px 0 0;
    border-radius: 15px;
    overflow: hidden
}

.FeatureSlide .FeatureContent .FeatureHead h3 {
    font-family: 'Alegreya SC', serif;
    color: #fff;
    font-size: 49px
}

.FeatureSlide .FeatureContent .FeatureHead h3 span {
    font-size: 25px
}

.FeatureSlide .FeatureContent .FeatureBody p {
    color: #fff;
    font-size: 18px;
    font-family: 'Roboto';
    line-height: 35px
}

.FeatureSlide .FeatureContent .FeatureBody article {
    margin: 15px 0 0 0
}

.FeatureSlide .FeatureContent .FeatureBody article h4 {
    font-family: 'Alegreya SC', serif;
    color: #fff;
    font-size: 40px;
    margin: 0 0 15px 0
}

.FeatureSlide .FeatureContent .FeatureBody article ul li {
    display: inline-block;
    width: 35%;
    margin: 0 10px 10px 0
}

.FeatureSlide .FeatureContent .FeatureBody article ul li a {
    display: block
}

.FeatureDomain, .FeatureDomain .fp-tableCell, .FeatureFooter, .FeatureFooter .fp-tableCell {
    height: auto !important
}

.ContactArea {
    padding: 0 0 50px 0
}

.ContactArea .ContactHead {
    padding: 150px 0 300px
}

.ContactArea .ContactHead h3 {
    text-align: center;
    color: #fff;
    position: relative;
    font-family: 'Alegreya SC', serif;
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0 0 20px 0;
    line-height: 40px
}

.ContactArea .ContactHead p {
    position: relative;
    text-align: center;
    color: #fff;
    font-size: 17px;
    line-height: 32px;
    font-family: 'Roboto';
    font-weight: 400;
    margin: 0 0 30px 0
}

.ContactArea .container-fluid {
    padding: 0 50px
}

.ContactArea .ContactBody {
    width: 100%;
    background: #fff;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    z-index: 99;
    margin: -250px auto 0;
    padding: 15px;
    max-width: 1450px
}

.ContactArea .ContactBody .ContactLeft h4 {
    font-family: 'Alegreya SC', serif;
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 10px 10px
}

.ContactArea .ContactBody .ContactRight h3 {
    font-family: 'Alegreya SC', serif;
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 10px 0
}

.ContactArea .ContactBody .ContactRight .ContactTop {
    margin: 0 0 20px 0
}

.ContactArea .ContactBody .ContactRight .ContactTop p {
    font-size: 15px;
    font-family: 'Roboto';
    font-weight: 400;
    line-height: 25px
}

.ContactArea .ContactBody .ContactRight .ContactMiddle article {
    display: flex;
    justify-content: space-between
}

.ContactArea .ContactBody .ContactRight .ContactMiddle article aside {
    width: 49%
}

.ContactArea .ContactBody .ContactRight .ContactMiddle article aside h4 {
    font-family: 'Alegreya SC', serif;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 10px 0;
    line-height: 20px
}

.ContactArea .ContactBody .ContactRight .ContactMiddle article aside p {
    position: relative;
    padding: 0 0 0 45px;
    font-size: 15px;
    font-family: 'Roboto';
    font-weight: 400;
    line-height: 25px;
    min-height: 40px;
    margin: 0 0 20px 0
}

.ContactArea .ContactBody .ContactRight .ContactMiddle article aside p span {
    width: 35px;
    height: 35px;
    display: block;
    text-align: center;
    background: #099ddb;
    color: #fff;
    line-height: 35px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.ContactArea .ContactBody .ContactRight .ContactFooter ul li {
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 400;
    line-height: 35px
}

.EstimateArea .EstimateHead {
    padding: 150px 0 300px;
    text-align: center
}

.EstimateArea .EstimateHead h3 {
    color: #fff;
    position: relative;
    font-family: 'Alegreya SC', serif;
    font-size: 45px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0 0 15px 0;
    line-height: 40px
}

.EstimateArea .EstimateHead h4 {
    color: #fff;
    position: relative;
    font-family: 'Alegreya SC', serif;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0 0 10px 0;
    line-height: 30px
}

.EstimateArea .EstimateHead p {
    position: relative;
    text-align: center;
    color: #fff;
    font-size: 17px;
    line-height: 32px;
    font-family: 'Roboto';
    font-weight: 400
}

.EstimateArea .EstimateBody {
    width: 100%;
    background: #fff;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    z-index: 99;
    margin: -250px auto 100px;
    padding: 15px;
    max-width: 1450px
}

/* ********************************* Artificial Intelligence and Machine Learning CSS ********************************* */

.Ai_Title h1 {
    color: #000;
    font-weight: 700;
    font-family: 'Alegreya SC', serif;
    letter-spacing: .5px;
    margin: 0 0 20px 0;
    position: relative;
    text-align: center;
    font-size: 40px;
    line-height: 50px;
    padding: 0 0 15px 0
}

.Ai_Title h1 span {
    color: #2088c9
}

.Ai_Title h1:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100px;
    height: 5px;
    background-color: #2088c9;
    border-radius: 50px;
    margin: auto
}

.Ai_Title p {
    font-size: 16px;
    font-family: 'Roboto';
    line-height: 31px;
    text-align: center
}

.AIBannerArea {
    padding: 150px 0 70px;
    background-size: cover
}

.AIBannerArea .AIBannerBox h3 {
    color: #fff;
    font-size: 37px;
    margin: 0 0 20px;
    font-weight: 500;
    line-height: 45px;
    font-family: 'Alegreya SC', serif
}

.AIBannerArea .AIBannerBox span.typewrite {
    display: block;
    font-weight: 800;
    font-family: 'Alegreya SC', serif;
    font-size: 40px
}

.AIBannerArea .AIBannerBox p {
    color: #fff;
    font-size: 18px;
    font-family: Roboto;
    margin: 0 0 20px 0;
    line-height: 30px
}

.AIBannerArea .AIBannerBox ul li {
    display: inline-block;
    margin: 0 10px 0 0
}

.AIBannerArea .AIBannerBox ul li a {
    padding: 10px 20px;
    display: block;
    border: 2px solid #fff;
    border-radius: 50px;
    color: #fff;
    font-size: 16px
}

.AIBannerArea .AIBannerBox ul li a:hover {
    background-color: #26a9e1;
    border-color: #26a9e1;
    color: #fff
}

.AIBannerArea .AIBannerBox ul li:first-child a {
    background-color: #26a9e1;
    border-color: #26a9e1
}

.ShappingArea {
    padding: 50px 0
}

.ShappingArea .ShappingLeft h1 {
    color: #383838;
    font-size: 38px;
    font-weight: 700;
    font-family: 'Alegreya SC', serif;
    letter-spacing: .5px;
    margin: 0 0 15px 0;
    position: relative;
    line-height: 38px
}

.ShappingArea .ShappingLeft p {
    margin: 0 0 15px 0;
    font-family: Roboto;
    line-height: 30px;
    font-size: 17px;
    color: #000
}

.ShappingArea .ShappingLeft h2 {
    font-family: 'Alegreya SC', serif;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 10px 0;
    color: #26a9e1
}

.ShappingArea .ShappingLeft ol {
    padding: 0 0 0 20px
}

.ShappingArea .ShappingLeft ol li {
    margin: 0 0 7px 0;
    font-family: Roboto;
    line-height: 30px;
    font-size: 17px;
    color: #000;
    list-style: disc
}

.ShappingArea .ShappingLeft ul {
    display: flex
}

.ShappingArea .ShappingLeft ul li {
    border: 1px solid #ddd;
    margin-left: -1px;
    position: relative;
    width: 25%
}

.ShappingArea .ShappingLeft ul li .Front {
    padding: 30px 20px;
    font-size: 17px;
    float: left;
    line-height: 21px;
    word-break: break-word
}

.ShappingArea .ShappingLeft ul li .Front h3 {
    margin: 0 0 30px 0
}

.ShappingArea .ShappingLeft ul li .Front h3 span {
    font-family: 'Roboto';
    font-size: 50px;
    border-bottom: 3px solid #3e3e3e;
    display: inline-block
}

.ShappingArea .ShappingLeft ul li .Front p {
    margin: 0;
    font-size: 16px;
    line-height: 24px
}

.ShappingArea .ShappingLeft ul li .Back {
    position: absolute;
    background-color: #26a9e1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 10px;
    display: none;
    transition: 0.5s all ease-in-out
}

.ShappingArea .ShappingLeft ul li .Back p {
    margin: 0;
    font-size: 14px;
    text-align: center;
    line-height: 26px;
    color: #fff
}

.ShappingArea .ShappingLeft ul li:hover .Back {
    display: flex
}

.ShappingArea .ShappingRight {
    background-image: url(https://www.signitysolutions.com/images/formBg.jpg);
    margin: 0;
    position: relative;
    background-size: cover
}

.ExpertArea {
    padding: 0 0 50px 0
}

.ExpertArea .ExpertBody {
    box-shadow: 0 0 30px rgb(0 0 0 / 20%);
    background: #fff;
    margin: 50px 0 50px 0
}

.ExpertArea .ExpertBody ul.nav-tabs {
    background: #fff;
    margin: 0;
    position: relative;
    overflow: hidden;
    padding: 2.4375em;
    border: none
}

.ExpertArea .ExpertBody ul.nav-tabs li {
    float: none;
    margin: 0;
    display: block;
    width: 100%
}

.ExpertArea .ExpertBody ul.nav-tabs li a {
    margin-bottom: 0;
    font-size: 17px;
    font-weight: 500;
    margin: 0;
    padding: 4px 15px;
    border: none;
    font-family: 'Roboto';
    color: #1f1f1f;
    border-radius: 0;
    background-color: transparent
}

.ExpertArea .ExpertBody ul.nav-tabs li a span {
    display: inline-block;
    width: 50px;
    height: 55px;
    vertical-align: middle;
    transform: scale(.7)
}

.ExpertArea .ExpertBody ul.nav-tabs li a.active {
    background: #2088c9;
    box-shadow: 4.5px 7.794px 29px 0 rgb(0 0 0 / 10%);
    color: #fff
}

.ExpertArea .ExpertBody ul.nav-tabs li a.active:after {
    content: '';
    position: absolute;
    z-index: 98;
    width: auto;
    height: auto;
    right: -32px;
    top: 16px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #0c253e
}

.ExpertArea .ExpertBody ul.nav-tabs li a.active span {
    filter: brightness(0) invert(1)
}

.ExpertArea .ExpertBody .tab-content-box {
    background: #0c253e url(https://www.signitysolutions.com/images/new_blue_BG.jpg) top center no-repeat !important;
    background-size: cover
}

.ExpertArea .ExpertBody .tab-content {
    min-height: 403px;
    background-image: url(https://www.signitysolutions.com/images/ArtificialIntelligence.png);
    background-repeat: no-repeat;
    background-position: 50% 10px;
    background-size: 25%;
    padding: 2.4375em;
    padding-top: 175px;
    text-align: center;
    margin: 0;
    position: relative
}

.ExpertArea .ExpertBody .tab-content .tab-pane .ExpertContent {
    color: #fff
}

.ExpertArea .ExpertBody .tab-content .tab-pane .ExpertContent h3 {
    font-family: 'Alegreya SC', serif;
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 10px 0;
    letter-spacing: .5px
}

.ExpertArea .ExpertBody .tab-content .tab-pane .ExpertContent p {
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 32px;
    margin: 0 0 20px 0
}

.ExpertArea .ExpertBody .tab-content .tab-pane .ExpertContent a {
    padding: 10px 40px;
    display: inline-block;
    border: 2px solid #fff;
    border-radius: 50px;
    color: #fff;
    font-size: 15px
}

.ExpertArea .ExpertBody .tab-content .tab-pane .ExpertContent a:hover {
    background-color: #2088c9
}


.AskBox {
    background-image: url(https://www.signitysolutions.com/images/new_blue_BG.jpg);
    text-align: center;
    padding: 60px 15px;
    color: #fff;
    font-family: 'Roboto'
}

.AskBox h3 {
    font-family: 'Alegreya SC', serif;
    font-size: 40px;
    font-weight: 600;
    margin: 0 0 10px 0;
    letter-spacing: .5px
}

.AskBox p {
    margin: 0 0 20px 0;
    font-size: 15px;
    font-family: 'Roboto';
    line-height: 31px
}

.AskBox a {
    padding: 10px 40px;
    display: inline-block;
    border: 2px solid #fff;
    border-radius: 50px;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin: 0 6px
}

.AskBox a:hover {
    background-color: #2088c9;
    color: #fff
}

.TransformArea {
    background-color: #f7f7f7;
    padding: 50px 0
}

.TransformArea .row {
    display: flex;
    margin: 0
}

.TransformArea .TransformLeft {
    background-image: url(https://www.signitysolutions.com/images/ai_carousel-bg.jpg);
    height: 100%;
    background-size: cover;
    overflow: hidden
}

.TransformArea .TransformLeft #myCarousel {
    background: rgba(244, 244, 244, .95);
    border-radius: 0;
    position: absolute;
    bottom: 0;
    left: 40px;
    right: 40px;
    height: 240px
}

.TransformArea .TransformLeft #myCarousel .Transformcontent {
    padding: 35px;
    height: 100%
}

.TransformArea .TransformLeft #myCarousel .Transformcontent h2 {
    font-family: 'Alegreya SC', serif;
    font-size: 22px;
    color: #000;
    font-weight: 600;
    letter-spacing: .5px;
    margin: 0 0 5px 0
}

.TransformArea .TransformLeft #myCarousel .Transformcontent h3 {
    font-family: 'Alegreya SC', serif;
    font-size: 23px;
    color: #2088c9;
    font-weight: 600;
    letter-spacing: .5px;
    margin: 0 0 5px 0
}

.TransformArea .TransformLeft #myCarousel .Transformcontent p {
    font-family: 'Roboto';
    font-size: 15px;
    color: #000;
    line-height: 26px
}

.TransformArea .TransformLeft #myCarousel .carousel-indicators {
    left: 0;
    right: 0;
    margin: auto;
    bottom: 10px
}

.TransformArea .TransformLeft #myCarousel .carousel-indicators li {
    margin: 0 3px;
    width: 12px;
    height: 12px;
    background-color: silver;
    border: none;
    border-radius: 15px
}

.TransformArea .TransformLeft #myCarousel .carousel-indicators li.active {
    background: #2088c9
}

.TransformArea .TransformRight {
    padding: 0 0 0 20px
}

.TransformArea .TransformRight h1 {
    color: #383838;
    font-weight: 700;
    font-family: 'Alegreya SC', serif;
    letter-spacing: .5px;
    margin: 0 0 15px 0;
    position: relative;
    font-size: 40px;
    line-height: 50px
}

.TransformArea .TransformRight h1 span {
    color: #2088c9
}

.TransformArea .TransformRight p {
    font-size: 16px;
    font-family: 'Roboto';
    line-height: 26px;
    margin: 0 0 15px 0;
    color: #000
}

.TransformArea .TransformRight ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0
}

.TransformArea .TransformRight ul li {
    border: 1px solid #ddd;
    margin-left: -1px;
    margin-top: -1px;
    position: relative;
    width: 33.33%;
    flex-grow: 1
}

.TransformArea .TransformRight ul li .Front {
    padding: 30px 20px;
    font-size: 17px;
    float: left;
    line-height: 21px;
    word-break: break-word;
    width: 100%
}

.TransformArea .TransformRight ul li .Front h3 {
    margin: 0 0 20px 0
}

.TransformArea .TransformRight ul li .Front h3 span {
    font-family: 'Roboto';
    font-size: 45px;
    border-bottom: 3px solid #3e3e3e;
    display: inline-block
}

.TransformArea .TransformRight ul li .Front p {
    margin: 0;
    font-size: 16px;
    line-height: 24px
}

.TransformArea .TransformRight ul li .Front p strong {
    display: block
}

.TransformArea .TransformRight ul li .Back {
    position: absolute;
    background-color: #26a9e1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 15px;
    display: none;
    transition: 0.5s all ease-in-out
}

.TransformArea .TransformRight ul li .Back p {
    margin: 0;
    font-size: 14px;
    text-align: center;
    line-height: 27px;
    color: #fff
}

.TransformArea .TransformRight ul li:hover .Back {
    display: flex
}

.InfuseArea {
    background-image: url(https://www.signitysolutions.com/images/blue_bg.jpg);
    background-size: cover;
    padding: 50px 0
}

.InfuseArea .Ai_Title h1 {
    color: #fff
}

.InfuseArea .Ai_Title p {
    color: #fff
}

.InfuseArea .InfuseBox {
    background: #fff;
    box-shadow: 0 0 23px rgb(0 0 0 / 10%);
    text-align: center;
    padding: 40px 30px;
    border-radius: 15px;
    transition: .6s all;
    position: relative;
    margin: 50px 0 0 0;
    min-height: 250px
}

.InfuseArea .InfuseBox .step {
    width: 45px;
    height: 45px;
    border-radius: 85px;
    background: #ddd;
    border: 2px solid #ddd;
    justify-content: center;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 25px;
    position: absolute;
    left: 30px;
    top: 20px;
    font-weight: 700;
    font-family: 'Roboto'
}

.InfuseArea .InfuseBox figure {
    width: 110px;
    height: 110px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center
}

.InfuseArea .InfuseBox h3 {
    font-family: 'Alegreya SC', serif;
    margin: 0 0 10px 0;
    color: #000;
    font-size: 20px;
    line-height: 25px
}

.InfuseArea .InfuseBox p {
    color: #000;
    font-size: 16px;
    font-family: 'Roboto';
    line-height: 26px;
    text-align: center
}

.InfuseArea .InfuseBox:hover {
    background-color: #2088c9;
    color: #000
}

.InfuseArea .InfuseBox:hover .step {
    background-color: #2088c9
}

.InfuseArea .InfuseBox:hover figure img {
    filter: brightness(0) invert(1)
}

.InfuseArea .InfuseBox:hover h3,
.InfuseArea .InfuseBox:hover p {
    color: #fff
}


.Ai_ServiceArea {
    padding: 50px 0
}

.Ai_ServiceArea .Ai_ServiceHead {
    background: #f7f7f7;
    padding: 20px 20px 0 20px;
    border: 1px solid #ddd;
    margin: 40px 0 50px 0;
    border-radius: 5px
}

.Ai_ServiceArea .Ai_ServiceHead .nav-tabs {
    border: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px
}

.Ai_ServiceArea .Ai_ServiceHead .nav-tabs li {
    width: 33.33%;
    flex-grow: 1;
    padding: 0 15px;
    margin: 0 0 20px 0
}

.Ai_ServiceArea .Ai_ServiceHead .nav-tabs li a {
    margin: 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    text-align: center;
    color: #000;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    padding: 20px 15px;
    min-height: 161px;
    display: flex;
    align-items: center;
    justify-content: center
}

.Ai_ServiceArea .Ai_ServiceHead .nav-tabs li a figure {
    width: 70px;
    margin: 0 auto 15px
}

.Ai_ServiceArea .Ai_ServiceHead .nav-tabs li a.active {
    background-color: #2088c9;
    border-color: #2088c9;
    color: #fff
}

.Ai_ServiceArea .Ai_ServiceHead .nav-tabs li a.active figure {
    filter: brightness(0) invert(1)
}

.Ai_ServiceArea .Ai_ServiceHead .Ai_ServiceContent h3 {
    color: #383838;
    font-weight: 700;
    font-family: 'Alegreya SC', serif;
    letter-spacing: .5px;
    margin: 0 0 15px 0;
    position: relative;
    font-size: 40px;
    line-height: 50px
}

.Ai_ServiceArea .Ai_ServiceHead .Ai_ServiceContent p {
    font-size: 16px;
    font-family: 'Roboto';
    line-height: 28px;
    margin: 0 0 0 0;
    text-align: left
}

.CommitmentArea {
    padding: 50px 0;
    background-color: #f7f7f7
}

.CommitmentArea .CommitmentBox {
    margin: 50px 0 0 0;
    background-color: #fff;
    padding: 40px;
    box-shadow: 0 0 23px rgb(0 0 0 / 10%);
    border: 0;
    position: relative;
    text-align: center;
    min-height: 225px;
    border-radius: 20px;
    overflow: hidden
}

.CommitmentArea .CommitmentBox figure {
    width: 70px;
    height: 70px;
    margin: 0 auto 20px
}

.CommitmentArea .CommitmentBox h4 {
    font-size: 19px;
    font-family: 'Alegreya SC', serif;
    line-height: 26px
}

.CommitmentArea .CommitmentBox figcaption {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2088c9;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    display: none
}

.CommitmentArea .CommitmentBox figcaption p {
    color: #fff;
    font-size: 16px;
    font-family: 'Roboto';
    line-height: 29px
}

.CommitmentArea .CommitmentBox:hover figcaption {
    display: flex
}

.DevelopArea {
    padding: 50px 0
}

.DevelopArea ul {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin: 20px auto 30px
}

.DevelopArea ul li {
    background-color: #fff;
    width: 185px;
    height: 165px;
    box-shadow: 1px 1px 8px rgb(0 0 0 / 15%);
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    margin: 20px;
    text-align: center;
    padding: 15px;
    cursor: pointer;
    align-items: center !important;
    flex-grow: 3
}

.DevelopArea ul li figure {
    height: 80px;
    align-items: center;
    display: inline-flex
}

.DevelopArea ul li p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    color: #1f1f1f;
    font-family: 'Alegreya SC', serif
}

.DevelopArea ul li:hover {
    transform: scale(1.2)
}

.Ai_AboutArea {
    padding: 50px 0;
    background-color: #f1f1f1;
    margin: 0 0 50px 0
}

.Ai_AboutArea .Ai_AboutBox {
    margin: 50px 0 0 0;
    background-color: #fff;
    padding: 35px 15px;
    box-shadow: 0 0 23px rgb(0 0 0 / 10%);
    border: 0;
    text-align: center;
    border-radius: 10px;
    transition: 0.5s all ease-in-out;
    min-height: 220px
}

.Ai_AboutArea .Ai_AboutBox figure {
    width: 75px;
    height: 75px;
    margin: 0 auto 20px
}

.Ai_AboutArea .Ai_AboutBox figure img {
    width: 100%;
    max-height: 75px
}

.Ai_AboutArea .Ai_AboutBox h4 {
    font-size: 17px;
    font-family: 'Alegreya SC', serif;
    line-height: 21px;
    margin: 0 0 15px 0
}

.Ai_AboutArea .Ai_AboutBox a {
    padding: 11px 30px;
    display: inline-block;
    background-color: #26a9e1;
    border-radius: 50px;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin: 0 5px
}

.Ai_AboutArea .Ai_AboutBox:hover {
    box-shadow: none
}

.Ai_AboutArea .Ai_AboutBox a:hover {
    background-color: #0a8dc5
}

.FoundArea {
    min-height: 100vh;
    height: 100vh;
    background-size: cover;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    position: relative
}

.FoundArea:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000a1
}

.FoundArea>div {
    position: relative
}

.FoundArea img {
    width: 260px;
    margin: auto;
    display: block
}

.FoundArea h3 {
    font-family: 'Alegreya SC', serif;
    color: #fff;
    margin: 30px 0 20px 0;
    font-size: 50px;
    line-height: 37px
}

.FoundArea p {
    color: #fff;
    font-size: 19px;
    margin: 0 0 15px;
    font-family: 'Alegreya SC', serif;
    font-weight: 500;
    letter-spacing: .5px
}

.FoundArea a {
    background-color: #2a53b6;
    padding: 10px 40px;
    display: inline-block;
    border-radius: 50px;
    font-family: 'Alegreya SC', serif;
    color: #fff;
    font-size: 22px;
    border: 2px solid #2a53b6;
    transition: 0.5s all ease-in-out
}

.FoundArea a:hover {
    background-color: #1e1e1e;
    border-color: #1e1e1e
}


.ModalBox .modal-dialog a.CloseModal {
    position: absolute;
    right: -40px;
    width: 40px;
    height: 40px;
    background-color: #ffffffd1;
    text-align: center;
    font-size: 30px;
    color: #000;
    top: 0
}

.ModalBox .modal-dialog a.CloseModal:hover {
    background-color: #fff
}

.ModalBox #ContactModal .modal-dialog {
    width: 500px
}

.ModalBox #ContactModal .modal-dialog .modal-content {
    box-shadow: none;
    border-radius: 0;
    border: none
}

.ModalBox #ContactModal .modal-dialog .modal-content .modal-body {
    padding: 0
}

.ModalBox #ContactModal .modal-dialog .Contact {
    padding: 30px
}

.ModalBox #ContactModal .modal-dialog .Contact h4 {
    margin: 0 0 20px 0;
    color: #000;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Alegreya SC', serif;
    text-align: center
}

.ModalBox .VideoModal .modal-dialog {
    max-width: 800px
}

.ModalBox .VideoModal .modal-dialog .modal-content {
    box-shadow: none;
    border-radius: 0;
    border: none
}

.ModalBox .VideoModal .modal-dialog .modal-content .modal-body {
    padding: 0
}

.ModalBox .VideoModal .modal-dialog .modal-content .modal-body iframe {
    width: 100%;
    height: 550px;
    margin: 0 0 -6px 0
}

.ThankyouArea {
    background-image: url(https://mobulous.s3.ap-south-1.amazonaws.com/Web/images/main/Slide.webp);
    min-height: 100vh;
    position: relative;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center
}

.ThankyouArea:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000d4
}

.ThankyouArea .ThankyouBox {
    position: relative;
    text-align: center
}

.ThankyouArea .ThankyouBox img {
    width: 260px;
    margin: auto;
    display: block
}

.ThankyouArea .ThankyouBox h3 {
    font-family: 'Alegreya SC', serif;
    color: #fff;
    margin: 30px 0 30px 0;
    font-size: 60px;
    line-height: 37px;
    font-weight: 700
}

.ThankyouArea .ThankyouBox h4 {
    color: #fff;
    font-size: 25px;
    margin: 25px 0 15px;
    font-family: 'Alegreya SC', serif;
    font-weight: 500;
    letter-spacing: .5px;
    line-height: 33px
}

.ThankyouArea .ThankyouBox p {
    color: #fff;
    font-size: 19px;
    margin: 0 0 15px;
    font-family: 'Alegreya SC', serif;
    font-weight: 500;
    letter-spacing: .5px
}

.ThankyouArea .ThankyouBox a {
    background-color: #2a53b6;
    padding: 10px 40px;
    display: inline-block;
    border-radius: 50px;
    font-family: 'Alegreya SC', serif;
    color: #fff;
    font-size: 22px;
    border: 2px solid #2a53b6;
    transition: 0.5s all ease-in-out
}


/* ************************** Case Study Page CSS ************************** */


.BannerArea.TeamBannerArea:before {
    background-color: rgb(0 0 0 / 20%)
}

.StudyLogo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    padding: 25px 0 0
}

.StudyLogo a {
    display: inline-block;
    width: 100px
}

.StudySlideArea {
    background: #54b160;
    padding: 50px 0 0 0
}

.StudySlideArea .StudySlideLeft {
    padding: 80px 0
}

.StudySlideArea .StudySlideLeft h3 {
    font-family: 'Alegreya SC', serif;
    font-size: 55px;
    font-weight: 700;
    margin: 0 0 20px 0;
    color: #fff
}

.StudySlideArea .StudySlideLeft h4 {
    margin: 0 0 20px 0;
    font-family: 'Roboto';
    font-size: 19px;
    line-height: 40px;
    color: #fff;
    font-weight: 400
}

.StudySlideArea .StudySlideLeft ul {
    margin: 0 0 20px 0
}

.StudySlideArea .StudySlideLeft ul li {
    display: inline-block;
    margin: 0 10px 12px 0
}

.StudySlideArea .StudySlideLeft ul li a {
    border-radius: 7px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    text-align: center;
    color: #FFF;
    display: inline-flex;
    font-family: 'Roboto';
    align-items: center;
    justify-content: center;
    width: 220px
}

.StudySlideArea .StudySlideLeft ul li a i {
    font-size: 22px;
    margin: 0 10px 0 0
}

.StudySlideArea .StudySlideLeft p {
    margin: 0 0 20px 0;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 500;
    color: #fff
}

.StudyFeatureArea {
    padding: 50px 0 10px
}

.StudyFeatureArea .StudyFeatureBox {
    margin: 0 0 50px 0
}

.StudyFeatureArea .StudyFeatureBox span.Count {
    font-size: 36px;
    font-family: 'Roboto';
    font-weight: 600;
    opacity: .25
}

.StudyFeatureArea .StudyFeatureBox h4 {
    font-family: 'Alegreya SC', serif;
    font-size: 25px;
    margin: 0 0 10px 0
}

.StudyFeatureArea .StudyFeatureBox p {
    font-family: 'Roboto';
    font-size: 15px;
    line-height: 30px
}

.StudyContentArea {
    padding: 0 0 50px 0
}

.StudyContentArea .StudyContentBody {
    width: 90%;
    margin: auto
}

.StudyContentArea .StudyContentBody+.StudyContentBody {
    border-top: 1px solid #ddd;
    margin: 50px auto 0;
    padding: 50px 0 0 0
}

.StudyContentArea .StudyContentBody .StudyContentLeft h3 {
    font-family: 'Alegreya SC', serif;
    font-size: 45px;
    margin: 0 0 25px 0;
    font-weight: 600
}

.StudyContentArea .StudyContentBody .StudyContentLeft p {
    font-size: 20px;
    line-height: 37px;
    font-family: 'Roboto'
}

.StudyContentArea .StudyContentBody .StudyContentRight {
    width: 85%
}

.StudyJobArea {
    padding: 50px 0 20px 0
}

.StudyJobArea .StudyJobHead {
    margin: 0 0 40px 0
}

.StudyJobArea .StudyJobHead h3 {
    font-family: 'Alegreya SC', serif;
    font-size: 45px;
    font-weight: 600
}

.StudyJobArea .StudyJobHead p {
    font-family: 'Roboto';
    font-size: 18px;
    line-height: 30px
}

.StudyJobArea .StudyJobBody .StudyJobBox {
    border: 1px solid #DFDFDF;
    border-radius: 10px;
    padding: 20px;
    transition: all 0.4s ease-in-out;
    position: relative;
    margin: 0 0 30px 0;
    display: flex;
    height: calc(100% - 30px)
}

.StudyJobArea .StudyJobBody .StudyJobBox:hover {
    box-shadow: 0 0 30px rgb(10 4 60 / 10%);
    border-color: #f1f1f1
}

.StudyJobArea .StudyJobBody .StudyJobBox figure {
    height: 24px;
    width: 24px;
    margin: 7px 0 0 0
}

.StudyJobArea .StudyJobBody .StudyJobBox figure.JobDot1 {
    background-color: #20BFA9
}

.StudyJobArea .StudyJobBody .StudyJobBox figure.JobDot2 {
    background-color: #e96f34
}

.StudyJobArea .StudyJobBody .StudyJobBox figure.JobDot3 {
    background-color: #4687fc
}

.StudyJobArea .StudyJobBody .StudyJobBox figure.JobDot4 {
    background-color: #f4cd25
}

.StudyJobArea .StudyJobBody .StudyJobBox figure.JobDot5 {
    background-color: #9447f5
}

.StudyJobArea .StudyJobBody .StudyJobBox figure.JobDot6 {
    background-color: #a98a6c
}

.StudyJobArea .StudyJobBody .StudyJobBox figure.JobDot7 {
    background-color: #c4d944
}

.StudyJobArea .StudyJobBody .StudyJobBox figure.JobDot8 {
    background-color: #e849c1
}

.StudyJobArea .StudyJobBody .StudyJobBox figcaption {
    width: calc(100% - 24px);
    padding: 0 0 0 16px
}

.StudyJobArea .StudyJobBody .StudyJobBox figcaption h4 {
    font-family: 'Alegreya SC', serif;
    font-size: 23px;
    margin: 0 0 10px 0
}

.StudyJobArea .StudyJobBody .StudyJobBox figcaption p {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 500;
    color: #1D263A;
    opacity: .7
}

.StudyTrustArea {
    padding: 50px 0
}

.StudyTrustArea .StudyTrustLeft h3 {
    font-family: 'Alegreya SC', serif;
    font-size: 50px;
    margin: 0 0 10px 0;
    font-weight: 600
}

.StudyTrustArea .StudyTrustLeft p {
    font-family: 'Roboto';
    font-size: 18px;
    line-height: 35px
}

.StudyTrustArea .StudyTrustRight ul {
    display: flex;
    justify-content: space-between
}

.StudyTrustArea .StudyTrustRight ul li {
    width: 49%
}

.StudyTrustArea .StudyTrustRight ul li aside {
    box-shadow: 0 6px 0 rgb(23 135 252 / 65%);
    border-radius: 25px;
    padding: 45px 40px;
    background-color: #55aae1;
    height: 100%
}

.StudyTrustArea .StudyTrustRight ul li:nth-child(2) aside {
    box-shadow: 0 6px 0 rgb(33 48 83 / 65%);
    background-color: #213053
}

.StudyTrustArea .StudyTrustRight ul li aside h3 {
    color: #fff;
    font-family: 'Roboto';
    font-size: 50px;
    font-weight: 600;
    margin: 0 0 20px 0
}

.StudyTrustArea .StudyTrustRight ul li aside p {
    color: #fff;
    font-size: 17px;
    font-family: 'Roboto';
    line-height: 27px
}

.StudyTrustArea .StudyTrustRight ul li aside h6 {
    margin: 0 0 10px 0
}

.StudyTrustArea .StudyTrustRight ul li aside h6 i {
    color: #FF7139;
    font-size: 18px;
    margin: 0 3px
}

.StudyScreenArea {
    background-color: #54b160;
    padding: 50px 0 80px
}

.StudyScreenArea .StudyScreenHead {
    text-align: center;
    margin: 0 0 75px 0
}

.StudyScreenArea .StudyScreenHead h3 {
    font-family: 'Alegreya SC', serif;
    font-size: 45px;
    font-weight: 600;
    color: #fff;
    margin: 0 0 10px 0
}

.StudyScreenArea .StudyScreenHead p {
    color: #fff;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400
}

.StudyScreenArea .StudyScreenBody {
    position: relative
}

.StudyScreenArea .StudyScreenBody .StudyScreenFrame {
    position: absolute;
    left: 0;
    top: -18px;
    z-index: 11;
    right: 0;
    margin: auto;
    width: 305px
}

.StudyScreenArea .StudyScreenBody .StudyScreenBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    max-height: 720px;
    width: 275px
}

.StudyScreenArea .StudyScreenBody .owl-carousel .owl-nav .owl-prev,
.StudyScreenArea .StudyScreenBody .owl-carousel .owl-nav .owl-next {
    position: absolute;
    right: 25%;
    top: 49%;
    height: 50px;
    width: 50px;
    line-height: 45px;
    border-radius: 50%;
    border: 2px solid #55aae1;
    transition: 0.5s all ease-in-out;
    font-size: 25px;
    outline: 0;
    background: #55aae1;
    color: #fff
}

.StudyScreenArea .StudyScreenBody .owl-carousel .owl-nav .owl-prev {
    left: 25%;
    background: #fff;
    color: #000
}

.StudyScreenArea .StudyScreenBody .owl-carousel .owl-dots {
    text-align: center;
    margin: 30px 0 0 0
}

.StudyScreenArea .StudyScreenBody .owl-carousel .owl-dots .owl-dot {
    cursor: pointer;
    display: inline-block;
    width: 6px;
    height: 6px;
    font-size: 0;
    background: #fff;
    margin: 0 7px;
    border-radius: 15px;
    transition: 0.5s all ease-in-out;
    outline: 0
}

.StudyScreenArea .StudyScreenBody .owl-carousel .owl-dots .owl-dot.active {
    width: 35px
}

.StudyCompaniesArea {
    padding: 50px 0
}

.StudyCompaniesArea h3 {
    font-family: 'Alegreya SC', serif;
    font-size: 40px;
    font-weight: 700;
    margin: 0 0 40px 0;
    text-align: center
}

.StudySlideArea.ZimbleStudySlide {
    background-color: #E7F8FF
}

.StudySlideArea.ZimbleStudySlide h3 {
    color: #000
}

.StudySlideArea.ZimbleStudySlide h4 {
    color: #000
}

.StudyScreenArea.StudyZimbleScreen {
    background-color: #E7F8FF
}

.StudyScreenArea.StudyZimbleScreen .StudyScreenHead h3 {
    color: #000
}

.StudyScreenArea.StudyZimbleScreen .StudyScreenHead p {
    color: #000
}

.StudyCompaniesArea.ZimbleStudyCompanies {
    background-color: #E7F8FF;
    margin: 0 0 50px 0
}

.StudyCompaniesArea.ZimbleStudyCompanies h3 {
    color: #000
}

.StudySlideArea.AIPLStudySlide {
    background-color: #e6303336
}

.StudySlideArea.AIPLStudySlide h3 {
    color: #000
}

.StudySlideArea.AIPLStudySlide h4 {
    color: #000
}

.StudySlideArea.AIPLStudySlide .StudySlideLeft p {
    color: #000
}

.StudyScreenArea.StudyAIPLScreen {
    background-color: #e6303336
}

.StudyScreenArea.StudyAIPLScreen .StudyScreenHead h3 {
    color: #000
}

.StudyScreenArea.StudyAIPLScreen .StudyScreenHead p {
    color: #000
}

/* ************************** New CSS (10 Oct 2021)  **************************  */


.ContactArea .ContactBody.EstimateContact {
    width: 55%
}

.SoftwareToolArea {
    text-align: center;
    padding: 150px 0 70px
}

.SoftwareToolArea h1 {
    font-family: 'Alegreya SC', serif;
    font-size: 85px;
    font-weight: 700;
    color: #424242;
    margin: 0 0 20px 0
}

.SoftwareToolArea p {
    font-size: 21px;
    color: #8A94A6;
    line-height: 38px;
    font-family: Roboto
}

.SoftwareTrustArea {
    background-color: #FAFBFC;
    padding: 50px 0
}

.SoftwareTrustArea h2 {
    font-family: 'Alegreya SC', serif;
    font-size: 40px;
    font-weight: 700;
    color: #424242;
    margin: 0 0 45px 0;
    text-align: center
}

.SoftwareTrustArea ul {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.SoftwareTrustArea ul li img {
    max-height: 48px
}

.SoftwareVideoBox {
    padding: 50px 0
}

.SoftwareVideoBox:nth-child(even) {
    background-color: #fafbfc
}

.SoftwareVideoBox h3 {
    font-family: 'Alegreya SC', serif;
    font-size: 40px;
    font-weight: 700;
    color: #424242;
    margin: 0 0 30px 0;
    text-align: center
}

.SoftwareVideoBox .SoftwareVideoHead {
    margin: 0 0 25px 0
}

.SoftwareVideoBox .SoftwareVideoHead ul {
    display: flex;
    flex-wrap: wrap
}

.SoftwareVideoBox .SoftwareVideoHead ul li {
    width: 33.33%;
    margin: 0 0 20px 0;
    font-size: 17px;
    font-weight: 500;
    font-family: 'Roboto';
    display: flex;
    align-items: center
}

.SoftwareVideoBox .SoftwareVideoHead ul li span.Check {
    width: 33px;
    height: 33px;
    background-color: #eafaf3;
    border-radius: 50%;
    margin: 0 15px 0 0;
    position: relative
}

.SoftwareVideoBox .SoftwareVideoHead ul li span.Check:after {
    content: '';
    left: 12px;
    top: 6px;
    width: 9px;
    height: 17px;
    border: solid #2dca8c;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(55deg);
    -ms-transform: rotate(55deg);
    transform: rotate(55deg);
    position: absolute
}

.SoftwareVideoBox .SoftwareVideoBody {
    border: 5px solid #d1cece
}

.SoftwareMoveArea {
    padding: 50px 0 0 0
}

.SoftwareMoveArea .SoftwareMoveHead {
    margin: 0 0 50px 0;
    text-align: center
}

.SoftwareMoveArea .SoftwareMoveHead h4 {
    font-family: 'Alegreya SC', serif;
    font-size: 40px;
    font-weight: 700;
    color: #424242;
    margin: 0 0 30px 0
}

.SoftwareMoveArea .SoftwareMoveHead p {
    font-size: 21px;
    color: #8A94A6;
    line-height: 38px;
    font-family: Roboto
}

.SoftwareMoveArea .SoftwareMoveHead p span {
    display: inline-block;
    width: 50px;
    margin: 0 20px
}

.SoftwareMoveArea .SoftwareMoveBody {
    text-align: center
}

.SoftwareMoveArea .SoftwareMoveBody h4 {
    font-family: 'Alegreya SC', serif;
    font-size: 40px;
    font-weight: 700;
    color: #424242;
    margin: 0 0 0 0
}

.SoftwareMoveArea .SoftwareMoveBox {
    -webkit-box-shadow: 0 10px 30px 0 rgb(21 10 82 / 20%);
    box-shadow: 0 7px 20px 0 rgb(21 10 82 / 20%);
    padding: 60px 15px 40px;
    border-radius: 4px;
    background-color: #fff;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    text-align: center;
    cursor: pointer;
    margin: 0 0 50px 0;
    min-height: 400px;
    border-radius: 15px
}

.SoftwareMoveArea .SoftwareMoveBox:hover {
    box-shadow: 0 2px 5px 0 rgb(21 10 82 / 20%)
}

.SoftwareMoveArea .SoftwareMoveBox figure {
    width: 70px;
    height: 70px;
    margin: 0 auto 50px;
    line-height: 70px;
    position: relative;
    border-radius: 50%;
    color: #fff;
    font-size: 40px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-around
}

.SoftwareMoveArea .SoftwareMoveBox figure:before {
    width: 125px;
    height: 125px;
    position: absolute;
    content: '';
    top: -27px;
    left: -28px;
    border-radius: 50%
}

.SoftwareMoveArea .SoftwareMoveBox figure:after {
    width: 100px;
    height: 100px;
    position: absolute;
    content: '';
    top: -14px;
    left: -15px;
    border-radius: 50%
}

.MoveRed figure {
    background-color: #f1d2cd
}

.MoveRed figure:before {
    background-color: rgb(255 227 222 / 20%)
}

.MoveRed figure:after {
    background-color: rgb(255 227 222 / 50%)
}

.MoveBlue figure {
    background-color: #E5F1FF
}

.MoveBlue figure:before {
    background-color: rgb(229 241 255 / 20%)
}

.MoveBlue figure:after {
    background-color: rgb(229 241 255 / 50%)
}

.MoveGreen figure {
    background-color: #EAFAF3
}

.MoveGreen figure:before {
    background-color: rgb(234 250 243 / 20%)
}

.MoveGreen figure:after {
    background-color: rgb(234 250 243 / 50%)
}

.MoveOrange figure {
    background-color: #f3e5ca
}

.MoveOrange figure:before {
    background-color: rgb(255 242 216 / 20%)
}

.MoveOrange figure:after {
    background-color: hsl(40deg 100% 92% / 50%)
}

.SoftwareMoveArea .SoftwareMoveBox figcaption h5 {
    font-family: 'Alegreya SC', serif;
    font-size: 21px;
    margin: 0 0 10px 0;
    font-weight: 600
}

.SoftwareMoveArea .SoftwareMoveBox figcaption p {
    font-size: 15px;
    font-family: 'Roboto';
    line-height: 32px;
    color: #4a4949
}

.RatingBox {
    display: flex;
    margin: 0 -20px
}

.RatingBox .RatingHead {
    width: 95px
}

.RatingBox .RatingHead i {
    color: #cfae0f;
    font-size: 11px
}

.RatingBox .RatingBody {
    width: calc(100% - 25px)
}

.RatingBox .RatingBody h6 {
    font-size: 14px;
    color: #bbb;
    font-family: 'Roboto';
    font-weight: 400;
    line-height: 19px
}

.RatingBox .RatingBody p {
    font-size: 12px;
    color: #bbb;
    font-family: 'Roboto';
    font-weight: 400;
    line-height: 19px;
    margin: 0;
    text-align: left
}

.RatingBox .RatingBody p span {
    font-weight: 600
}

.NewTitleBox {
    margin: 0 0 50px 0
}

.NewTitleBox h2 {
    margin: 0 0 10px 0 !important
}

.NewTitleBox p {
    margin: 0 auto !important;
    text-align: center
}


/* ********************************* Mobile App Development Header CSS ********************************* */


.HeaderAppArea {
    min-height: 100vh;
    background-size: cover;
    padding: 100px 0;
    position: relative;
    background-position: center center
}

.HeaderAppArea .HeaderAppLeft {
    position: relative;
    z-index: 1
}

.HeaderAppArea .HeaderAppLeft ul {
    margin: 0 0 15px 0
}

.HeaderAppArea .HeaderAppLeft ul li {
    display: inline-block;
    margin: 0 30px 0 0;
    position: relative
}

.HeaderAppArea .HeaderAppLeft ul li+li:before {
    content: '\f105';
    position: absolute;
    left: -20px;
    top: 0;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    font-family: 'FontAwesome'
}

.HeaderAppArea .HeaderAppLeft ul li a {
    font-size: 15px;
    color: #fff;
    font-family: 'Roboto';
    font-weight: 500
}

.HeaderAppArea .HeaderAppLeft h1 {
    color: #fff;
    font-size: 42px;
    font-weight: 700;
    font-family: 'Alegreya SC', serif;
    margin: 0 0 10px 0;
    position: relative;
    z-index: 1
}

.HeaderAppArea .HeaderAppLeft p {
    font-size: 14px;
    line-height: 27px;
    margin: 0 0 15px 0;
    font-family: 'Roboto';
    color: #fff;
    position: relative;
    z-index: 1
}

.HeaderAppArea .HeaderAppLeft a.Contact {
    background-color: #fff;
    color: #1ea1d8;
    padding: 12px 30px 12px 60px;
    display: inline-block;
    border-radius: 8px;
    font-family: 'Alegreya SC', serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: .5px;
    position: relative;
    cursor: pointer
}

.HeaderAppArea .HeaderAppLeft a.Contact span {
    position: absolute;
    top: 8px;
    left: 13px;
    width: 35px;
    filter: brightness(0)
}

.HeaderAppArea .HeaderAppRight {
    text-align: center;
    padding: 30px 0 0 0
}

.HeaderAppArea .HeaderAppRight img {
    position: relative;
    z-index: 1;
    animation: UPDown 6s infinite
}

.MobileAppIcon .MobileIcon {
    position: absolute;
    z-index: 0
}

.MobileAppIcon .MobileIcon1 {
    top: 60px;
    left: 50%;
    animation: Scale 5s infinite
}

.MobileAppIcon .MobileIcon2 {
    top: 27%;
    left: 38%;
    width: 60px;
    animation: Scale 5.5s infinite
}

.MobileAppIcon .MobileIcon3 {
    top: 50%;
    left: 50%;
    width: 120px;
    animation: Scale 6s infinite
}

.MobileAppIcon .MobileIcon4 {
    top: 29%;
    right: 35%;
    width: 40px;
    animation: Scale 6.5s infinite
}

.MobileAppIcon .MobileIcon5 {
    top: 20%;
    right: 30px;
    width: 60px;
    animation: Scale 5s infinite
}

.MobileAppIcon .MobileIcon6 {
    left: 60px;
    animation: Scale 5.5s infinite
}

.MobileLayer {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 180px;
    background-size: cover
}

.MobileLayer span.Layer {
    position: absolute
}

.MobileLayer span.Layer img {
    width: auto
}

.MobileLayer span.Layer1 {
    bottom: 18%;
    width: 60px;
    left: 6%;
    animation: Rotate 9s linear 0s infinite running
}

.MobileLayer span.Layer2 {
    top: -45px;
    left: 24%;
    animation: Rotate 15s linear 0s infinite running
}

.MobileLayer span.Layer3 {
    left: 48%;
    top: -23px;
    width: 90px;
    animation: Rotate 8s linear 0s infinite running
}

.MobileLayer span.Layer4 {
    left: 70%;
    top: 45px;
    width: 72px;
    animation: Rotate 7.5s linear 0s infinite running
}

.MobileLayer span.Layer5 {
    right: 8%;
    bottom: 14%;
    width: 60px;
    animation: Rotate 9s linear 0s infinite running
}

.MobileLayer span.Layer6 {
    right: 27%;
    bottom: 65px;
    animation: Rotate 8s linear 0s infinite running
}

.MobileLayer span.Layer7 {
    right: 15%;
    bottom: 38px;
    width: 70px;
    animation: Rotate 8.5s linear 0s infinite running
}

.MobileLayer span.Layer8 {
    right: 5%;
    bottom: 25px;
    width: 50px;
    animation: Rotate 9s linear 0s infinite running
}

/* ********************************* Website Design Header CSS ********************************* */


.WebsiteAppArea {
    padding: 135px 0 100px;
    overflow: hidden
}

.WebsiteAppArea .HeaderAppLeft ul li+li:before,
.WebsiteAppArea .HeaderAppLeft ul li a,
.WebsiteAppArea .HeaderAppLeft h1,
.WebsiteAppArea .HeaderAppLeft p {
    color: #000
}

.WebsiteAppArea .HeaderAppLeft a.Contact {
    background: #55aae1;
    color: #fff
}

.WebsiteAppArea .HeaderAppLeft a.Contact span {
    filter: brightness(0) invert(1)
}

.WebsiteDesignLayer {
    position: absolute;
    width: 100%;
    text-align: center
}

.WebsiteDesignLayer img {
    animation: WebsiteRotate 15s linear 0s infinite running;
    width: 70%
}

.WebsiteIcon .WebsiteIcon {
    position: absolute;
    z-index: 0
}

.WebsiteIcon .WebsiteIcon1 {
    top: 60px;
    left: 40%;
    animation: Scale 5s infinite
}

.WebsiteIcon .WebsiteIcon2 {
    right: 120px;
    left: auto;
    bottom: 80px;
    top: auto
}

.WebsiteIcon .WebsiteIcon3 {
    top: 54%;
    left: 53%;
    width: 120px;
    animation: Scale 6s infinite
}

.WebsiteIcon .WebsiteIcon4 {
    left: 60px;
    animation: Scale 5.5s infinite
}

.WebsiteIcon .WebsiteIcon5 {
    top: 20%;
    right: 30px;
    width: 60px;
    animation: Scale 5s infinite
}

/* ********************************* Hire Developer Header CSS ********************************* */


.HeaderHireArea {
    overflow: hidden;
    padding: 140px 0 0 0
}

.HeaderHireArea .HireShape1 {
    position: absolute;
    top: 15%;
    left: -15%;
    width: 400px
}

.HeaderHireArea .HireShape2 {
    position: absolute;
    top: 4%;
    left: 35%;
    width: 250px
}

.HeaderHireArea .HireShape3 {
    position: absolute;
    right: -13%;
    top: 20%;
    width: 300px
}

.HeaderHireArea .HireShape1 img {
    animation: WebsiteRotate 15s linear 0s infinite running
}

.HeaderHireArea .HireShape2 img {
    animation: Scale 5s infinite
}

.HeaderHireArea .HireShape3 img {
    animation: WebsiteRotate 15s linear 0s infinite running
}

.HireIcons .HireIcon {
    position: absolute
}

.HireIcons .HireIcon1 {
    left: 20%;
    bottom: 40px;
    width: 50px;
    animation: Scale 4s infinite
}

.HireIcons .HireIcon2 {
    top: 100px;
    left: 24%;
    width: 50px;
    animation: Scale 5s infinite
}

.HireIcons .HireIcon3 {
    left: 40%;
    top: 60%;
    width: 50px;
    animation: Scale 6s infinite
}

.HireIcons .HireIcon4 {
    right: 45%;
    bottom: 70px;
    width: 50px;
    animation: Scale 7s infinite
}

.HireIcons .HireIcon5 {
    right: 12.5%;
    top: 80px;
    width: 60px;
    animation: Scale 8s infinite
}

.HireIcons .HireIcon6 {
    right: 60px;
    bottom: 40px;
    width: 60px;
    animation: Scale 9s infinite
}

/* ********************************* Ios App Development Header CSS ********************************* */


.IOSShape1 {
    position: absolute;
    top: 20%;
    left: -15%;
    width: 400px
}

.IOSShape2 {
    position: absolute;
    bottom: 0%;
    left: 25%;
    width: 215px
}

.IOSShape3 {
    position: absolute;
    left: 40%;
    top: 10%;
    width: 130px
}

.IOSShape4 {
    position: absolute;
    right: 3%;
    bottom: 30px;
    width: 140px
}

.IOSShape5 {
    position: absolute;
    top: 80px;
    right: 20%;
    width: 100px
}

.IOSShape1 img {
    animation: WebsiteRotate 15s linear 0s infinite running
}

.IOSShape2 img {
    animation: Scale 7s infinite
}

.IOSShape3 img {
    animation: Scale 4s infinite
}

.IOSShape4 img {
    animation: Scale 7s infinite
}

.IOSShape5 img {
    animation: Scale 4s infinite
}

.IOSIcons .IOSIcon {
    position: absolute;
    width: 50px
}

.IOSIcons .IOSIcon:after {
    content: '';
    background-image: url('https://mobulous.s3.ap-south-1.amazonaws.com/Web/images/mobile/Header/IOSCircle.png');
    animation: WebsiteRotate 5s linear 0s infinite running;
    width: 86px;
    height: 86px;
    position: absolute;
    left: -18px;
    top: -18px;
    background-size: cover
}

/*
.IOSIcons .IOSIcon1{ position: absolute; right: 44%; top: 9%; }
.IOSIcons .IOSIcon2{ right: 44%; top: 32%; }
.IOSIcons .IOSIcon3{ position: absolute; right: 44%; top: 60%; }
.IOSIcons .IOSIcon4{ position: absolute; right: 44%; top: 88%; }*/


.IOSIcons .IOSIcon1 {
    position: absolute;
    right: 36%;
    top: 15%
}

.IOSIcons .IOSIcon2 {
    right: 47%;
    top: 45%
}

.IOSIcons .IOSIcon3 {
    position: absolute;
    left: 10%;
    bottom: 5%
}

.IOSIcons .IOSIcon4 {
    position: absolute;
    right: 47%;
    bottom: 30px
}

.IOSIcons .IOSIcon1 img {
    animation: Scale 3s infinite
}

.IOSIcons .IOSIcon2 img {
    animation: Scale 4s infinite
}

.IOSIcons .IOSIcon3 img {
    animation: Scale 5s infinite
}

.IOSIcons .IOSIcon4 img {
    animation: Scale 6s infinite
}


/* ********************************* Android App Development Header CSS ********************************* */

.AndroidShape {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.AndroidShape figure {
    height: 216px;
    background-size: cover;
}

.AndroidShape .AndroidIcon {
    position: absolute;
    bottom: 2px;
    width: 60px;
    background-color: #fff;
    border-radius: 50%;
    z-index: 9;
}

.AndroidShape .AndroidIcon1 {
    animation: IconRotate 10s infinite;
}

.AndroidShape .AndroidIcon2 {
    animation: IconRotate 15s infinite;
}

.AndroidShape .AndroidIcon3 {
    animation: IconRotate 20s infinite;
}

.AndroidShape .AndroidIcon4 {
    animation: IconRotate 25s infinite;
}

.AndroidShape .AndroidIcon5 {
    animation: IconRotate 30s infinite;
}

.AndroidShape .AndroidIcon6 {
    animation: IconRotate 35s infinite;
}

.AndroidLogo {
    position: absolute;
    width: 110px;
    animation: Android 25s infinite;
    z-index: 9;
}

/* ********************************* Website Development Header CSS ********************************* */

.DevelopmentIcon {
    position: absolute;
    z-index: 0;
}

.DevelopmentIcon1 {
    top: 7%;
    left: 15%;
    width: 90px;
    animation: Scale 3s infinite;
}

.DevelopmentIcon2 {
    right: 20px;
    top: 56%;
    animation: Scale 4s infinite;
}

.DevelopmentIcon3 {
    left: 23%;
    bottom: 20px;
    width: 115px;
    animation: Scale 5s infinite;
}

/* ********************************* SKills Header CSS ********************************* */

.SkiilsAppArea {
    background-position: 0 0px;
    padding-top: 175px;
}

.HeaderAppArea.SkiilsAppArea .HeaderAppRight {
    padding: 0;
}

.SkillsIcon {
    position: absolute;
}

.SkillsIcon1 {
    position: absolute;
    top: 20%;
    left: 30%;
    width: 110px;
    animation: Scale 6s infinite;
}

.SkillsIcon2 {
    left: 41%;
    bottom: 20%;
    width: 90px;
    animation: Scale 4s infinite;
}

.SkillsIcon3 {
    left: 30%;
    bottom: 5%;
    animation: Scale 5s infinite;
}

/* ********************************* Add On Header CSS ********************************* */

.AddonAppArea {
    background-position: 0 100%;
    padding: 165px 0 100px;
}

.AddonAppArea .HeaderAppRight {
    padding: 0;
    margin: -50px 0 0 0;
}

/* ********************************* Key Frame Animation CSS ********************************* */

@keyframes Android {
    0% {
        left: 40%;
        top: 13%;
        transform: rotate(0deg)
    }

    25% {
        left: 0;
        top: 50%;
        transform: rotate(90deg)
    }

    50% {
        left: 50%;
        top: 75%;
        transform: rotate(270deg)
    }

    75% {
        left: 75%;
        top: -5%;
        transform: rotate(360deg)
    }

    100% {
        left: 100%;
        top: 100%;
        transform: rotate(0deg)
    }
}

@keyframes IconRotate {
    0% {
        left: 0;
        bottom: -5px;
        transform: rotate(0deg)
    }

    100% {
        left: 100%;
        bottom: 210px;
        transform: rotate(360deg)
    }
}

@keyframes UPDown {
    0%, 100% {
        transform: translate(0px, 25px);
    }

    50% {
        transform: translate(0, 0);
    }
}

@keyframes Scale {
    0%, 100% {
        transform: scale(.6);
    }

    50% {
        transform: scale(1);
    }
}

@keyframes Rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes WebsiteRotate {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}
























.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 40px;
    margin-bottom: 1rem;
    list-style: none;
}


.ourTeam {
    margin-top: 4rem;
    margin-bottom: 10rem;
}

.jay-img-size img {
    width: 350px;
    height: 350px;
    background-position: center;
    background-size: cover;
    object-fit: cover;
    
}