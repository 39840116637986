/* src/CompanyInfo.css */

.company-info {
  font-family: Arial, sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.company-info__header {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-info__banner-container {
  position: relative;
  width: 100%;
}

.company-info__banner {
  width: 100%;
  height: 300px; /* Adjusted height for a more professional look */
  object-fit: cover;
}

.company-info__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Black overlay with opacity */
  z-index: 1;
}

.company-info__logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 2;
}

.company-info__logo {
  width: 150px;
  height: auto;
  margin-bottom: 10px;
}

.company-info__name {
  font-size: 2rem;
  margin: 0;
  color: #f08614;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Improve text visibility */
}

.company-info__details {
  padding: 20px;
  max-width: 800px;
  text-align: center;
  background-color: #f9f9f9; /* Light background for readability */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.company-info__details h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #333;
}

.company-info__details p {
  margin: 0.5rem 0;
  line-height: 1.6;
}

.company-info__details a {
  color: #18665c; /* Brand color */
  text-decoration: none;
}

.company-info__details a:hover {
  text-decoration: underline;
}

.company-info__social {
  margin: 20px 0;
  text-align: center;
}

.company-info__social a {
  color: #18665c;
  font-size: 1.5rem;
  margin: 0 15px;
  text-decoration: none;
}

.company-info__social i {
  transition: color 0.3s;
}

.company-info__social a:hover i {
  color: #f08614; /* Brand color */
}

.company-info__back-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #f08614; /* Brand color */
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.company-info__back-button:hover {
  background-color: #e07b12; /* Slightly darker shade for hover */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .company-info__banner {
    height: 200px; /* Adjust height for smaller screens */
  }

  .company-info__logo {
    width: 120px;
  }

  .company-info__name {
    font-size: 1.5rem;
  }

  .company-info__details h2 {
    font-size: 1.5rem;
  }

  .company-info__social i {
    font-size: 1.2rem;
  }

  .company-info__details {
    padding: 15px;
  }

  .company-info__back-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .company-info__banner {
    height: 150px; /* Further adjust height for very small screens */
  }

  .company-info__logo {
    width: 100px;
  }

  .company-info__name {
    font-size: 1.25rem;
  }

  .company-info__details h2 {
    font-size: 1.25rem;
  }

  .company-info__social i {
    font-size: 1rem;
  }

  .company-info__details {
    padding: 10px;
  }

  .company-info__back-button {
    padding: 6px 12px;
    font-size: 0.85rem;
  }
}
