.service {
    margin-top: 4rem;
}

.cardModelBox img {
    width: 390px;
    height: 222px;
    background-position: center;
    background-size: cover;
    border-radius: 15px;
}

.shadow-make {
    box-shadow: 0 0 22px #0000001a !important;
    border-radius: 15px;
}

