.Header .navbar .navbar-nav li .dropdown-toggle {
    display: none;
}

@media screen and (min-width: 1700px) {}

@media screen and (max-width: 1920px) and (min-width: 1700px) {}

@media screen and (max-width: 1075px) {

    .MenuOpen {
        overflow: hidden
    }

    .MenuOpen:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000a1;
        z-index: 10
    }

    .Header {
        background-color: #00000052;
        padding: 5px 0
    }

    .Header .navbar {
        justify-content: space-between
    }

    .Fixed {
        position: absolute;
    }

    .Header .navbar-dark .navbar-toggler {
        display: block;
    }

    .Header .navbar .navbar-collapse {
        display: block !important;
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        width: 300px;
        height: 100%;
        padding: 15px 15px;
        justify-content: inherit;
        flex-basis: inherit;
        overflow: auto;
        box-shadow: 0 1px 8px #ddd;
        -webkit-transition: -webkit-transform ease 400ms;
        transition: -webkit-transform ease 400ms;
        -o-transition: transform ease 400ms;
        transition: transform ease 400ms;
        transition: transform ease 400ms, -webkit-transform ease 400ms;
        -webkit-transform: translateX(-200%);
        -ms-transform: translateX(-200%);
        transform: translateX(-200%)
    }

    .Header .navbar .navbar-collapse.MenuShow {
        transform: translateX(0%)
    }

    .Header .navbar .navbar-collapse .MobileLogo {
        display: inline-block;
        width: 125px;
        margin: 0 0 15px 0;
    }

    .Header .navbar .navbar-right {
        display: block;
        flex-direction: inherit
    }

    .Header .navbar .navbar-nav li {
        margin: 0 0 10px 0;
        display: block
    }

    .Header .navbar .navbar-nav li a {
        color: #000;
        padding: 10px 0px;
        font-size: 16px;
    }

    .Header .navbar .navbar-nav li.dropdown a:before {
        color: #000
    }

    .Header .navbar .navbar-nav li.dropdown a {
        padding: 10px 15px 10px 0px;
    }

    .Header .navbar .navbar-nav li:nth-last-child(-n+2) a {
        display: inline-block;
        min-width: 170px;
        font-size: 17px;
        padding: 10px 20px;
        color: #000;
        border: 2px solid #000
    }

    .Header .navbar .navbar-nav li.dropdown .MegaMenu {
        display: block
    }

    .Header .navbar .navbar-nav li.dropdown .MegaMenu .MegaMenuBox {
        width: 100%
    }

    .Header .navbar .navbar-nav li.dropdown .MegaMenu {
        display: none;
        position: relative;
        top: 0;
        width: 100%;
        border: none;
        box-shadow: none;
        padding: 0 0 0 15px;
        margin: 10px 0 -20px 0;
        float: none;
    }

    .Header .navbar .navbar-nav li.dropdown .MegaMenu .MegaMenuBox h4 {
        margin: 0 0 10px 0;
    }

    .Header .navbar .navbar-nav li.dropdown .MegaMenu .MegaMenuBox ol li a {
        padding: 5px 0;
    }

    .Header .navbar .navbar-nav li.dropdown:hover .MegaMenu {
        display: none;
    }

    .Header .navbar .navbar-nav li.dropdown a:before {
        color: #000;
        display: none;
    }

    .Header .navbar .navbar-nav li.dropdown.show .MegaMenu.show {
        display: block;
    }

    .Header .navbar .navbar-nav li.dropdown {
        position: relative;
    }

    .Header .navbar .navbar-nav li .dropdown-toggle {
        display: block;
        position: absolute;
        right: 0;
        padding: 10px 10px !important;
        top: 0;
    }

    .Header .navbar .navbar-nav li .dropdown-toggle:after {
        display: none;
    }

}

@media screen and (max-width: 992px) {

    .ProjectArea .ProjectRight {
        margin-top: 30px;
    }

    .LandscapeArea h2 {
        font-size: 25px;
        padding: 0 10px;
        margin: 0 0 40px
    }

    .LandscapeArea ul {
        padding: 0 10px;
        display: block
    }

    .LandscapeArea ul li {
        width: calc(100% / 3);
        float: left;
        margin: 0 0 50px
    }

    .LandscapeArea ul li .LandscapeBox {
        padding: 30px 10px 10px 10px;
        min-height: 200px;
        text-align: center
    }

    .LandscapeArea ul li .LandscapeBox p.LandTitle {
        font-size: 14px;
        line-height: 17px;
        margin: 0 0 2px 0
    }

    .LandscapeArea ul li .LandscapeBox p {
        font-size: 12.5px;
        line-height: 20px
    }

    .LandscapeArea ol {
        padding: 0 10px;
        display: block
    }

    .LandscapeArea ol li {
        width: calc(100% / 3);
        float: left
    }

    .LandscapeArea ol li .WorkBox {
        padding: 15px 15px 50px 15px;
        min-height: 180px;
        margin: 0 0 60px 0
    }

    .LandscapeArea ol li .WorkBox figure {
        width: 105px
    }

    .LandscapeArea ol li .WorkBox p {
        font-size: 13.5px;
        line-height: 22px
    }

    .LandscapeArea ul li:nth-last-child(-n+4) {
        margin: 0
    }

    .IdeaBox {
        padding: 0 0;
        text-align: center
    }

    .IdeaBox article aside {
        display: inline-block;
        margin: 0 6px;
        min-width: 160px;
        text-align: center;
        padding: 15px 0 15px 0
    }

    .IdeaBox article aside:last-child {
        border-left: 2px solid #26a9e1;
        border-bottom: 2px solid #26a9e1
    }

    .IdeaBox article aside p.Title {
        font-size: 19px
    }

    .IdeaBox article aside p.Text {
        font-size: 13px
    }

}

@media screen and (max-width: 990px) and (min-width: 768px) {
    .SoftwareMoveArea .col-sm-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .SoftwareMoveArea .SoftwareMoveBox {
        min-height: 350px;
    }
}

@media screen and (max-width: 768px) {

    .SliderText aside {
        padding: 0 15px;
        top: 80px
    }

    .SliderText aside h1 {
        font-size: 27px;
    }

    .SliderText aside p {
        font-size: 35px;
    }

    .SliderText article {
        padding: 20px 20px;
    }

    .SliderText article p.Apps {
        font-size: 23px;
        line-height: 36px;
        margin: 0 0 10px 0;
    }

    .SliderText article p.Founded {
        font-size: 18px;
        line-height: 39px;
        margin: 0 0 20px;
    }

    .SliderText article p.Founded br {
        display: none;
    }

    .SliderText article ul {
        margin: 0
    }

    .SliderText article ul li {
        margin: 0 5px 10px;
        min-width: 300px;
        text-align: center;
    }

    .SliderText article ul li a {
        padding: 11px 30px;
        font-size: 17px;
    }

    .WorkArea {
        padding: 0 0 50px
    }

    .WorkArea #Works .owl-stage-outer {
        height: auto
    }

    .WorkArea .WorkBox {
        display: block
    }

    .WorkArea .WorkBox .WorkLeft {
        width: 100%
    }

    .WorkArea .WorkBox .WorlRight {
        width: 100%;
        padding: 30px 40px
    }

    .MatrixArea .MatrixBox p br {
        display: none
    }

    .AwardsArea .AwardsBox {
        min-height: 255px
    }

    .ClientArea .owl-nav .owl-prev {
        left: -10px
    }

    .ClientArea .owl-nav .owl-next {
        right: -10px
    }

    .FeedbackArea .FeedbackRight ul li {
        width: 100%;
        margin: 0 0 5px 0;
        float: none !important
    }

    .FeedbackArea .FeedbackRight ul li:nth-last-child(-n+2) {
        margin: 0 0 5px 0
    }

    .Footer .col-sm-4,
    .Footer .col-sm-8 {
        max-width: 100%;
        flex: 0 0 100%
    }

    .Footer .FooterRight {
        display: flex;
        margin: 20px 0 0 0
    }

    .CopyRight .col-sm-6 {
        max-width: 100%;
        flex: 0 0 100%
    }

    .CopyRight ul {
        text-align: center
    }

    .CopyRight p {
        text-align: center;
        margin: 15px 0 0 0
    }

    .BannerArea {
        min-height: inherit;
        padding: 120px 20px 80px;
        background-size: cover;
        background-position: center
    }

    .MobileAppArea .MobileAppShape {
        display: none;
    }

    .MissonArea ul {
        margin: 50px 0 0 0
    }

    .EngagementArea .EngagementLeft {
        padding: 50px 20px
    }

    .EngagementArea .EngagementRight {
        padding: 50px
    }

    .EngagementBox h3 {
        font-size: 22px
    }

    .EngagementBox p {
        line-height: 29px
    }

    .ShappingArea .ShappingLeft ul {
        display: flex;
        flex-wrap: wrap
    }

    .ShappingArea .ShappingLeft ul li {
        width: 50%;
        float: left
    }

    .ExpertArea .ExpertBody ul.nav-tabs {
        padding: 15px
    }

    .ExpertArea .ExpertBody ul.nav-tabs li a {
        padding: 5px 10px;
        font-size: 15px
    }

    .ExpertArea .ExpertBody ul.nav-tabs li a span {
        width: 45px;
        height: 50px
    }

    .ExpertArea .ExpertBody .tab-content {
        padding-top: 100px
    }

    .ExpertArea .ExpertBody .tab-content .tab-pane .ExpertContent p {
        font-size: 14px;
        line-height: 30px
    }

    .TransformArea .TransformLeft #myCarousel {
        bottom: 10px;
        left: 10px;
        right: 10px;
        height: 280px
    }

    .TransformArea .TransformRight h1 {
        font-size: 26px;
        line-height: 35px
    }

    .TransformArea .TransformRight p {
        font-size: 15px;
        line-height: 27px
    }

    .TransformArea .TransformRight ul {
        display: flex;
        flex-wrap: wrap
    }

    .TransformArea .TransformRight ul li {
        width: 50%;
        float: left
    }

    .InfuseArea .InfuseBox {
        min-height: 300px
    }

    .Ai_ServiceArea .Ai_ServiceHead {
        padding: 20px 20px 20px 20px
    }

    .MobileAppArea .MobileAppShape {
        display: none
    }

    .MobileSuccessArea ul {
        flex-wrap: wrap
    }

    .MobileSuccessArea ul li {
        width: 33.33%
    }

    .MobileServiceArea .col-sm-4 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .StatisticsWorkArea .StatisticsWorkBox ul {
        flex-wrap: wrap
    }

    .StatisticsWorkArea .StatisticsWorkBox ul li {
        width: 33%
    }

    .ValuableArea .ValuableBox {
        width: 100%
    }

    .CustomizedArea .CustomizedSlider .CustomizedBox figure {
        height: auto
    }

    .CustomizedArea .CustomizedSlider .CustomizedBox figcaption a {
        font-size: 16px
    }

    .ContactArea .container-fluid {
        padding: 0 30px
    }

    .ContactArea .ContactBody .ContactRight .ContactMiddle article {
        display: block
    }

    .ContactArea .ContactBody .ContactRight .ContactMiddle article aside {
        width: 100%
    }

    .WedoArea .WedoFooter .WedoTop ul li span {
        width: 50px
    }

    .TitleBox p br {
        display: none
    }

    .PortfolioBox figure {
        height: 270px
    }

    .DetailsArea .DetailsBody .DetailsRight {
        padding: 0
    }

    .LeaderBox {
        display: block;
    }

    .LeaderBox figure {
        margin: 0 0 15px 0;
        border: 5px solid #fff;
        width: 155px;
        height: 155px;
    }

    .LeaderBox figcaption {
        padding: 0;
        width: 100%;
    }

    .MembersArea .MembersMiddle aside h4 {
        font-size: 36px;
    }

    .MembersArea .MembersMiddle aside {
        height: 165px;
    }

    .MembersArea .MembersMiddle article {
        height: 45%;
        padding: 20px;
    }

    .TeamSlider .TeamSliderBody {
        display: block;
    }

    .TeamSlider .TeamSliderBody .Slide {
        height: auto;
    }

    .TeamSlider .TeamSliderBody .Slide.Slide1 {
        width: 50%;
        height: 650px;
        float: left;
    }

    .TeamSlider .TeamSliderBody .Slide.Slide2 {
        width: 100%;
        height: 650px;
    }

    .TechnologyArea .TechnologyBody ul li {
        width: 50%;
    }

    .ServiceArea .col-sm-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .ServiceAppArea .ServiceAppBody .ServiceImages {
        height: 100%;
    }

    .ServiceAppArea .ServiceAppBody .ServiceImages img {
        height: 100%;
    }

    .ServiceAppArea .ServiceAppBody .ServiceContent {
        padding: 15px;
    }

    .ServiceAppArea .ServiceAppBody .ServiceContent h3 {
        font-size: 20px;
        margin: 0 0 5px;
    }

    .ServiceAppArea .ServiceAppBody .ServiceContent p {
        font-size: 13px;
        line-height: 25px;
        margin: 0 0 5px 0;
    }

    .ServiceAppArea .ServiceAppBody .ServiceContent a {
        font-size: 13px;
        padding: 7px 15px;
    }

    .GalleryArea .GalleryHead ul {
        width: 100%;
    }

    .GalleryArea .GalleryHead ul li {
        padding: 10px;
    }

    .SoftwareToolArea h1 {
        font-size: 60px;
    }

    .SoftwareToolArea p {
        font-size: 18px;
        line-height: 35px;
    }

    .SoftwareVideoBox h3 {
        font-size: 30px;
    }

    .SoftwareVideoBox .SoftwareVideoHead ul li {
        width: 50%;
        font-size: 16px;
    }

    .SoftwareMoveArea .col-sm-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .Tree1 {
        left: -50px !important;
        width: 150px !important;
    }

    .Tree2 {
        right: -50px !important;
        width: 150px !important;
    }

    .RatingBox {
        margin: 0;
    }


}

@media screen and (max-width: 500px) {

    .SliderText aside {
        padding: 0 15px;
        top: 80px
    }

    .SliderText aside h1 {
        font-size: 18px
    }

    .SliderText aside p {
        font-size: 24px
    }

    .SliderText article {
        padding: 0 10px
    }

    .SliderText article p.Apps {
        font-size: 15px;
        line-height: 20px;
        margin: 0 0 5px 0
    }

    .SliderText article p.Founded {
        font-size: 13px;
        line-height: 23px;
        margin: 0 0 10px
    }

    .SliderText article p.Founded br {
        display: none;
    }

    .SliderText article ul {
        margin: 0
    }

    .SliderText article ul li {
        margin: 0 2px 8px;
        min-width: 150px;
        text-align: center;
    }

    .SliderText article ul li a {
        padding: 4px 10px;
        font-size: 12px;
    }

    .ProjectArea .ProjectRight ul {
        display: block;
    }

    .ProjectArea .ProjectRight ul li {
        padding: 0 5px
    }

    .ProjectArea .ProjectRight ul li aside {
        min-height: 250px;
        margin: 0 0 30px 0 !important;
    }

    /*.ProjectArea .ProjectRight ul li aside+aside{margin-top:10px}*/
    .ProjectArea .ProjectRight ul li aside h3 br {
        display: none
    }

    .LandscapeArea ul li {
        width: calc(100% / 2)
    }

    .LandscapeArea ul li:nth-last-child(-n+4) {
        margin: 0 0 50px
    }

    .LandscapeArea ul li:nth-last-child(-n+3) {
        margin: 0 0 0
    }

    .LandscapeArea ol li {
        width: calc(100% / 2)
    }

    .BrandArea {
        padding: 50px 0
    }

    .BrandBody ul {
        float: left;
        width: 50%
    }

    .BrandBody ul:after {
        top: 0;
        height: 100%;
        width: 2px;
        right: 0;
        left: auto
    }

    .BrandBody ul li {
        width: 100%;
        margin: 10px 0 10px 0;
        padding: 0 30px 0 0
    }

    .BrandBody ul li .Line {
        position: absolute;
        top: 20px;
        right: 0;
        width: 20px;
        height: 2px
    }

    .BrandBody ol li .Line {
        position: absolute;
        top: 20px;
        left: 0;
        width: 20px;
        height: 2px
    }

    .BrandBody ul li .Dots {
        top: 16px;
        right: 20px;
        left: auto
    }

    .BrandBody ol li .Dots {
        top: 16px;
        left: 20px;
        right: auto
    }

    .BrandBody ol {
        float: left;
        width: 50%;
        margin: 40px 0 0 0
    }

    .BrandBody ol li {
        width: 100%;
        position: relative;
        margin: 10px 0 10px 0;
        padding: 0 0 0 20px
    }

    .FeedbackArea .FeedbackLeft {
        padding: 0 15px
    }

    .Footer .FooterRight {
        display: block
    }

    .Footer .FooterRight .Foots+.Foots {
        margin-top: 20px
    }

    .MissonArea .MissonBox {
        width: 100%;
        margin: 0 0 30px 0
    }

    .MissonArea .MissonBox .MissonContent {
        margin: 0;
        min-height: inherit;
        padding: 30px
    }

    .MissonArea .MissonBox+.MissonBox .MissonContent {
        margin: 0
    }

    .MissonArea ul {
        margin: 20px 0 0 0;
        display: block
    }

    .MissonArea ul li {
        width: 100%;
        padding: 0
    }

    .MissonArea ul li picture {
        margin: 0 0 10px 0 !important
    }

    .FeaturedArea h2 {
        padding: 0 15px 15px;
        font-size: 35px
    }

    .ShappingArea .ShappingLeft ul {
        margin-bottom: 20px
    }

    .TitleBox h2 {
        font-size: 35px;
        line-height: 40px
    }

    .TransformArea .TransformLeft {
        min-height: 600px
    }

    .TransformArea .TransformRight {
        padding: 20px 0;
        margin: 0 -15px
    }

    .Ai_ServiceArea .Ai_ServiceHead .nav-tabs li {
        width: 50%;
        padding: 0 10px
    }

    .DevelopArea ul {
        width: 100%
    }

    .MobileSuccessArea ul li {
        width: 100%
    }

    .MobileServiceArea .MobileServiceBox a {
        min-height: inherit
    }

    .MobileServiceArea .col-sm-4 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .CustomizedArea .CustomizedSlider .owl-nav .owl-prev {
        left: 10px
    }

    .CustomizedArea .CustomizedSlider .owl-nav .owl-next {
        right: 10px
    }

    .TalkArea .TalkBox {
        max-width: 90%;
        margin: auto;
        display: block
    }

    .TalkArea .TalkBox figure {
        margin: auto
    }

    .TalkArea .TalkBox figcaption {
        padding: 40px 0 0 0;
        width: 100%
    }

    .StatisticsWorkArea .StatisticsWorkBox ul li {
        width: 100%
    }

    .IndustriesArea .IndustriesBox figure {
        height: auto
    }

    .ProcessArea article {
        flex-wrap: wrap
    }

    .WedoArea .WedoBody .WedoBodyRight h1 {
        font-size: 60px
    }

    .WedoArea .WedoBody .WedoBodyRight {
        padding: 40px 0 0
    }

    .WedoArea .WedoFooter .WedoTop ul {
        flex-wrap: wrap
    }

    .WedoArea .WedoFooter .WedoTop ul li {
        width: 25%
    }

    .PortfolioBox figure {
        height: auto
    }

    .DetailsArea .DetailsBody .DetailsRight {
        padding: 30px 0 0 0
    }

    .MembersArea .MembersMiddle {
        margin: 0 0 20px 0;
    }

    .MembersArea .MembersMiddle aside {
        margin: 0 0 20px 0;
    }

    .MembersArea .MembersMiddle article {
        height: auto;
    }

    .TeamSlider .TeamSliderBody .Slide.Slide1 {
        width: 100%;
    }

    .TeamSlider .TeamSliderBody .Slide.Slide2 {
        height: 300px;
    }

    .TechnologyArea .TechnologyBody ul li {
        width: 100%;
    }

    .ServiceArea .col-sm-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .ServiceArea .ServiceBox {
        margin: 0 auto 50px !important;
    }

    .ServiceAppArea .ServiceAppBody {
        border-top: 1px solid #ddd;
    }

    .ServiceAppArea .ServiceAppBody .ServiceImages {
        height: auto;
    }

    .ServiceAppArea .ServiceAppBody .ServiceContent {
        padding: 50px;
    }

    .BannerArea h3 {
        font-size: 30px;
        line-height: 40px
    }

    .BannerArea h5 {
        font-size: 15px;
        line-height: 30px
    }

    .BannerArea h5 br {
        display: none
    }

    .FeatureSlide {
        background-size: contain;
        min-height: 100%;
        background-attachment: fixed;
        background-position: 20px 0;
        background-repeat-x: no-repeat
    }

    .FeatureSlide .FeatureContent {
        padding: 30px 0 0;
        width: 57%
    }

    .FeatureSlide .FeatureContent .FeatureHead {
        display: flex
    }

    .FeatureSlide .FeatureContent .FeatureHead .Icon {
        width: 50px;
        margin: 0 10px 0 0;
        display: inline-block
    }

    .FeatureSlide .FeatureContent .FeatureHead h3 {
        font-size: 25px;
        display: inline-block;
        width: calc(100% - 70px);
        line-height: 26px
    }

    .FeatureSlide .FeatureContent .FeatureHead h3 span {
        font-size: 15px
    }

    .FeatureSlide .FeatureContent .FeatureBody p {
        font-size: 15px;
        line-height: 28px
    }

    .FeatureSlide .FeatureContent .FeatureBody article h4 {
        font-size: 25px;
        line-height: 20px
    }

    .FeatureSlide .FeatureContent .FeatureBody article ul li {
        width: 140px;
        display: block
    }

    .ModalBox #ContactModal .modal-dialog {
        width: 85%;
    }

    .WedoArea .WedoFooter .WedoBottom .Tabs li {
        margin-bottom: 10px;
    }

    .SoftwareMoveArea .col-sm-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .SoftwareToolArea h1 {
        font-size: 45px;
    }

    .SoftwareToolArea p {
        font-size: 18px;
        line-height: 34px;
    }

    .SoftwareToolArea p br {
        display: none;
    }

    .SoftwareVideoBox h3 {
        font-size: 35px;
    }

    .SoftwareVideoBox .SoftwareVideoHead ul li {
        width: 100%;
    }

    .SoftwareMoveArea .SoftwareMoveBox {
        min-height: inherit;
    }

    .HeaderAppArea,
    .HeaderHireArea {
        padding: 100px 0;
    }

    .WebsiteIcon .WebsiteIcon1 {
        top: 60px;
        left: 10%
    }

    .WebsiteIcon .WebsiteIcon2 {
        right: 40px;
        top: 30%;
        bottom: auto
    }

    .WebsiteIcon .WebsiteIcon3 {
        width: auto;
        left: 45%
    }

    .WebsiteIcon .WebsiteIcon4 {
        bottom: 4%
    }

    .MobileLayer {
        height: 60px
    }

    .MobileLayer span.Layer1 {
        bottom: 0
    }

    .MobileLayer span.Layer2 {
        display: none
    }

    .MobileLayer span.Layer3 {
        bottom: 10px;
        left: 40%;
        top: auto
    }

    .MobileLayer span.Layer4 {
        display: none
    }

    .MobileLayer span.Layer5 {
        bottom: 0
    }

    .MobileAppIcon .MobileIcon1 {
        top: 60px;
        left: 25%
    }

    .MobileAppIcon .MobileIcon2 {
        top: 29%;
        left: 80%
    }

    .MobileAppIcon .MobileIcon3 {
        top: 38%;
        left: 10%
    }

    .MobileAppIcon .MobileIcon4 {
        top: 55%;
        left: 80%
    }

    .MobileAppIcon .MobileIcon5 {
        top: 66%;
        left: 1%
    }

    .MobileAppIcon .MobileIcon6 {
        top: 73%;
        left: 80%
    }

    .HeaderAppArea .HeaderAppRight {
        padding: 30px 0 0 0 !important;
        margin: 0
    }

    .IOSShape1 {
        left: -47%;
        top: 10%
    }

    .IOSShape2 {
        left: -10%;
        width: 200px;
        bottom: -70px
    }

    .IOSShape3 {
        right: 0;
        left: auto;
        top: 2%
    }

    .IOSShape4 {
        bottom: 18%
    }

    .IOSShape5 {
        top: 45%;
        right: 20px
    }

    .IOSIcons .IOSIcon1 {
        top: 25%;
        right: 20%
    }

    .IOSIcons .IOSIcon2 {
        top: 51%
    }

    .IOSIcons .IOSIcon3 {
        bottom: auto;
        top: 70%;
        left: 19px
    }

    .IOSIcons .IOSIcon4 {
        right: 12%
    }

    .SkillsIcon1 {
        top: 10%;
        left: 60%
    }

    .SkillsIcon2 {
        top: 46%;
        bottom: auto;
        left: 30%
    }

    .SkillsIcon3 {
        bottom: 30px;
        left: 80%
    }

    .DevelopmentIcon1 {
        top: 4%;
        left: 3%;
        width: 80px
    }

    .DevelopmentIcon2 {
        top: 47%;
        left: 75%
    }

    .DevelopmentIcon3 {
        bottom: 10px;
        left: 20%;
        width: 90px
    }

    .HeaderHireArea .HireShape1 {
        top: 60%;
        width: 250px;
        left: -30%
    }

    .HeaderHireArea .HireShape2 {
        top: 20%;
        left: 0;
        z-index: 9
    }

    .HeaderHireArea .HireShape3 {
        width: 250px;
        right: -30%;
        top: 33%
    }

    .HireIcons .HireIcon2 {
        top: 13%;
        left: 4%
    }

    .HireIcons .HireIcon3 {
        left: 60%;
        top: 53%
    }

    .HireIcons .HireIcon5 {
        right: 10px
    }

    .HireIcons .HireIcon6 {
        right: 20px;
        bottom: 20px
    }

    .HeaderAppArea.HeaderHireArea .HeaderAppLeft ul li a,
    .HeaderAppArea.HeaderHireArea .HeaderAppLeft h1,
    .HeaderAppArea.HeaderHireArea .HeaderAppLeft p,
    .HeaderAppArea.HeaderHireArea .HeaderAppLeft ul li+li:before {
        color: #000;
    }

    .AddonAppArea {
        padding: 100px 0 100px;
    }

    @keyframes IconRotate {
        0% {
            left: 0;
            bottom: -5px;
            transform: rotate(0deg)
        }

        100% {
            left: 100%;
            bottom: 50px;
            transform: rotate(360deg);
        }
    }


}