* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  /* font-family: sofia-pro, sans-serif; */
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #444;
  text-align: left;
  /* background-color: #f8f8f8; */
  background-color: #fff;
}


.toast-container {
  z-index: 9999999999; /* Ensure this is higher than other z-index values in your app */
}

a{
  color: inherit;
  text-decoration: none;
}
a:hover{
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}



/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */