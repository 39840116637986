.row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.text-center {
    text-align: center !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.container,
.container-lg,
.container-md,
.container-ml,
.container-sm,
.container-xl {
    max-width: 1315px;
}

.cardBox {
    margin-top: 5rem;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.text-gray-medium {
    color: #444 !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
    padding: 10px;
}

.h1,
h1 {
    font-size: 2.5rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 900;
    line-height: 1.2;
    color: #0d4b49;
}

.cardBox {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    /* background-color: #fff; */
    background-clip: border-box;
    /* border: 1px solid rgba(0, 0, 0, .125); */
    border-radius: .25rem;
    width: 199%;
    justify-content: center;
    padding: 10px;
}

.text-sm-left {
    padding-left: 10px;
}

.img-fluid {
    border-radius: 20px;
    max-width: 100%;
    height: auto;
}

.card {
    padding: 10px;
    border-radius: 15px;
}

.text-16 {
    font-size: 1rem;
}

.text-hulkpeach {
    color: #e76f51 !important;
}

.text-hulkdarkpurple {
    color: #39237b !important;
}

.text-hulkgrassy {
    color: #80b918 !important;
}

.text-hulkgreen {
    color: #00a9a2 !important;
}

.text-hulkindigoblue {
    color: #5a49f8 !important;
}

.text-hulkskyblue {
    color: #00b4da !important;
}

i {
    font-style: italic;
}

.flex-column a i {
    text-transform: uppercase;
}

.h3,
h3 {
    font-size: 1.5rem;
}

.text-sm-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.font-weight-bold {
    font-weight: 700 !important;
}

a,
a:hover {
    text-decoration: none;
}

.text-12 {
    font-size: .75rem;
}

.card p {
    font-family: 'Kanit', sans-serif;


}

.card-footer .arrow-icon i {
    font-size: 22px;
    padding-right: 1rem;
}

.moveIcon {
    width: 10rem;
    height: 10rem;
    background-position: center;
    background-size: cover;
    border-radius: 42% 56% 72% 28% / 42% 42% 56% 48%;
    animation: moveIcons 3.75s linear infinite;
    overflow: hidden;
}

@keyframes moveIcons {

    0%,
    100% {
        border-radius: 42% 56% 72% 28% / 42% 42% 56% 48%;
    }

    33% {
        border-radius: 72% 28% 48% 48% / 28% 28% 72% 72%;
    }

    66% {
        border-radius: 100% 56% 56% 100% / 100% 100% 56% 56%;
    }
}


@media (max-width: 536px) {
    .pSmall {
        font-size: 12px;
        letter-spacing: 0.6px;
    }

}


@media (max-width: 390px) {
    .cardBox {
        margin-top: -2rem;
    }
}


@media (max-width: 315px) {
   .h3{
       font-size: 16px;
   }
   .card-body p{
       font-size: 13px;
   }
   .card-footer a{
       font-size: 12px;
   }
}