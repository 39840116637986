.project{
    margin-top: 4rem;
    margin-bottom: 10rem;
}

.ggg{
    margin-top: 2rem;
    column-gap: 15px;
    justify-content: center;
}

.WebLink{
    text-decoration: underline;
    color: #2088c9;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 12px;
}

.WebLink:hover{
   color: rgb(3, 122, 122);
}