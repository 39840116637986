/* Ensure you have Source Sans Pro loaded in your HTML */

.start-project {
  font-family: "Source Sans Pro", sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  color: #333; /* Default text color */
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 2em;
  margin-bottom: 10px;
  color: #18665c; /* Brand color */
}

.header p {
  font-size: 1em;
  /* color: #e07b12; Brand color */
}

.form-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd; /* Light border for form */
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #18665c; /* Brand color */
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
}

.custom-budget {
  display: flex;
  gap: 10px;
}

.custom-budget label {
  margin-right: 5px;
  color: #18665c; /* Brand color */
}

.custom-budget input {
  flex: 1;
}

.buttonSubmitStartProject {
  padding: 10px 15px;
  background-color: #e07b12; /* Brand color */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  font-family: "Source Sans Pro", sans-serif; /* Apply font-family to button */
}

.buttonSubmitStartProject:hover {
  background-color: #d05a0e; /* Darker shade for hover effect */
}

.company-info {
  margin-top: 30px;
}

.company-info h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #18665c; /* Brand color */
  text-align: center;
}

.company-info p {
  margin: 5px 0;
  text-align: center;
}

.company-info a {
  color: #e07b12; /* Brand color */
  text-decoration: none;
}

.company-info a:hover {
  text-decoration: underline;
}

/* Hide the budget field if "Other" is selected */
.budget-message {
  margin-top: 10px;
  font-style: italic;
  color: #333;
}

.custom-budget {
  display: flex;
  gap: 10px;
}

.custom-budget label {
  margin-right: 5px;
}

.error-message {
  color: #d9534f; /* Bootstrap's red color for error messages */
  font-size: 0.875rem; /* Slightly smaller font size for error messages */
  margin-top: 0.25rem; /* Space above the error message */
  display: block; /* Ensure error messages are block-level */
}
