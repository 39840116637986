.about-right .right-img img {
    width: 480px;
    border-radius: 15px;
    /* border-radius: 42% 56% 72% 28% / 38% 38% 62% 62%; */
}

.about-left, .about-right {
    margin-top: 7rem;
}

.about-container {
    margin-left: 2rem;
    margin-right: 2rem;
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5rem;
    place-items: center;
    margin-bottom: 5rem;
}

.about-left .socialItems i {
    padding: 8px;
    flex-wrap: wrap;
    /* background-color: antiquewhite; */
    border-radius: 42% 56% 72% 28% / 42% 42% 56% 48%;
}

.socialItems{
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 20px;
    color: inherit;
    cursor: pointer;
}



.about-left .sub-title {
    font-size: 15px;
    font-weight: 500;
}

.about-left .title {
    font-weight: bold;
}

.about-left .about-line p {
    font-weight: 200;
    color: #444;
    letter-spacing: 1px;
    line-height: 1.5;
    font-size: 16px;
}

.about-review .row {
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(5, 1fr);
    text-align: center;
}

.about-review {
    background-color: #d8f7f7 !important;
    width: 100%;
    margin-bottom: 10rem;
}

.about-review .banner-content {
    padding: 15px;
}

.about-review .blue-circle img {
    max-width: 100%;
    height: auto;
    border: 2px solid #000;
    border-radius: 50%;
}

.blue-circle {
    /* width: 20%; */
    float: left;
    padding: 0px 15px;
    color: #000;
    text-align: center;
}

.blue-circle h2 {
    font-weight: 600;
    margin-bottom: 0px;
    padding-top: 10px;
}

h2, .h2 {
    font-size: 2rem;
}


@media (max-width: 972px) {
    .about-left, .about-right {
        margin-top: 2rem;
    }

    .about-right {
        margin-top: -4rem;
    }
}

@media (max-width: 760px) {
    .about-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .about-right {
        margin-top: 8rem;
    }
}

@media (max-width: 515px) {
    .about-review p {
        font-size: 10px
    }
}

@media (max-width: 400px) {
    .about-review .row {
        display: grid;
        flex-direction: column;
        grid-template-columns: repeat(1, 1fr);
        text-align: center;
        column-gap: 15px;
    }

    .about-review p {
        font-size: 15px
    }
}

@media (max-width: 285px) {
    .about-left .about-line p {
        font-size: 13px;
    }

    .about-right {
        margin-top: 3rem;
    }

    .about-left .title {
        font-size: 29px;
    }

    .about-left .sub-title {
        font-size: 12px;
    }

    .socialItems {
        margin-bottom: 18px;
        margin-top: 15px;
    }
}

.oglitze-meaning{
    margin-top: 1rem;
}