/* src/AddDesignPackageForm.css */

.add-design-package-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .add-design-package-form h2 {
    margin-bottom: 20px;
  }
  
  .add-design-package-form div {
    margin-bottom: 15px;
  }
  
  .add-design-package-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .add-design-package-form input,
  .add-design-package-form textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-design-package-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .add-design-package-form button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
  
  .add-design-package-form ul {
    list-style: none;
    padding: 0;
  }
  
  .add-design-package-form li {
    padding: 5px 0;
  }
  