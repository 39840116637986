.not-found-container {
  margin: 100px auto;
  padding: 20px;
  max-width: 800px;
  background: linear-gradient(135deg, #fff3f3, #ffffff);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  overflow: hidden;
  background-image: radial-gradient(
      circle,
      rgba(240, 245, 250, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    linear-gradient(135deg, #fff3f3, #ffffff);
}

.not-found-content {
  padding: 20px;
  position: relative;
  z-index: 1;
}

.title404NotFound {
  font-size: 100px;
  color: #f08614; /* Replaced $color-primary with the hex value */
  margin: 0;
}

.message {
  font-size: 18px;
  color: #333;
  margin: 20px 0;
}

.buttons {
  margin-top: 20px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin: 5px;
  transition: background-color 0.3s ease;
}

.home-button {
  background-color: #fff;
  color: #f08614; /* Replaced $color-primary with the hex value */
  border: 1px solid #f08614; /* Replaced $color-primary with the hex value */
}

.official-button {
  background-color: #62caca; /* Replaced $color-secondary-two with the hex value */
}

.home-button:hover {
  opacity: 0.7;
  color: #f08614;
}

.official-button:hover {
  background-color: #4fa4a4; /* Darkened $color-secondary-two manually for hover effect */
}

.footer {
  text-align: center;
  font-size: 14px;
  color: #666;
}


.primaryButton {
  background-color: #f08614; /* Replaced $color-primary with the hex value */
  color: white; /* Assuming white text for better contrast */
  transition: background-color 0.3s ease, opacity 0.3s ease; /* Smooth transition */

  /* Hover state styles */
  /* Removed the &:hover SCSS syntax and replaced it with the CSS :hover pseudo-class */
}

.primaryButton:hover {
  opacity: 0.8; /* Adjusted to 0.8 for better visibility on hover */
  background-color: #c66b11; /* Manually darkened #f08614 for hover effect */
  /* color: inherit; */
   color: white;
}

/* Focus state styles */
/* Removed the &:focus SCSS syntax and replaced it with the CSS :focus pseudo-class */
.primaryButton:focus {
  outline: 2px solid #f08614; /* Replaced $color-primary with the hex value */
  outline-offset: 2px;
}
