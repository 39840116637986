.company {
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 15px;
}

.company p {
  font-family: inherit;
}

.company h2 {
  font-size: 50px;
  font-weight: bold;
  font-family: cursive;
  line-height: 1.2;
}

.bg-gray-light {
  background-color: #d8f7f7 !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: 65px;
}

.text-lg-left {
  text-align: left !important;
}

.text-gray-medium {
  color: #444 !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.h1,
h1 {
  font-size: 2.5rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 900;
  line-height: 1.2;
}

.text-lg-left {
  text-align: left !important;
  margin-right: -0px;
}

.projectBtn2 i {
  text-align: center;
  align-items: center;
  padding-left: 10px;
}

.btn-outline-primary {
  color: #00a9a2;
  border-color: #00a9a2;
  font-family: monospace;
}

.projectBtn2 {
  font-weight: 600;
  text-align: center;
  padding: 0.85rem 1.875rem;
  font-size: 1.125rem;
  line-height: 1.4;
  border-radius: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.projectBtn2:hover {
  color: #fff;
  background-color: #028984;
  border-color: #028984;
  box-shadow: none;
}

.projectBtn2:hover i {
  animation: btnArrow infinite 1s linear;
}

@keyframes btnArrow {
  from {
    transform: translateX(5px);
  }

  to {
    transform: translateX(10px);
  }
}

.btn-primary {
  color: #fff;
  background-color: #00a9a2;
  border-color: #00a9a2;
  box-shadow: none;
}

.projectBtn i {
  text-align: center;
  align-items: center;
  padding-left: 10px;
}

.projectBtn {
  font-weight: 600;

  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 2px solid transparent;
  padding: 0.85rem 1.875rem;
  font-size: 1.125rem;
  line-height: 1.4;
  border-radius: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.projectBtn:hover {
  color: #fff;
  background-color: #028984;
  border-color: #028984;
  box-shadow: none;
}

.projectBtn:hover i {
  animation: btnArrow infinite 1s linear;
}

@keyframes btnArrow {
  from {
    transform: translateX(5px);
  }

  to {
    transform: translateX(10px);
  }
}

.moreD {
  /* border-bottom: 1px dotted rgb(172, 170, 170); */
  padding-bottom: 150px;
}

.moreD .text-lg-right {
  text-align: right !important;
}

.moreD .text-gray-medium {
  color: #444 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.display-4 {
  font-size: 3.5rem;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.h3,
h3 {
  font-size: 1.5rem;
}

.mitDiv {
  overflow: hidden;
  width: 25rem;
  height: 25rem;
  border-radius: 42% 56% 72% 28% / 42% 42% 56% 48%;
  animation: move 3.75s linear infinite;
}

@keyframes move {

  0%,
  100% {
    border-radius: 42% 56% 72% 28% / 42% 42% 56% 48%;
  }

  33% {
    border-radius: 72% 28% 48% 48% / 28% 28% 72% 72%;
  }

  66% {
    border-radius: 100% 56% 56% 100% / 100% 100% 56% 56%;
  }
}

.mitDivs img {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  border-radius: 42% 56% 72% 28% / 42% 42% 56% 48%;
  animation: move 3.75s linear infinite;
  overflow: hidden;
}

@keyframes moves {

  0%,
  100% {
    border-radius: 42% 56% 72% 28% / 42% 42% 56% 48%;
  }

  33% {
    border-radius: 72% 28% 48% 48% / 28% 28% 72% 72%;
  }

  66% {
    border-radius: 100% 56% 56% 100% / 100% 100% 56% 56%;
  }
}


@media (max-width: 995px) {
  .mitDivs {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .company .text-lg-left {
    text-align: center !important;
  }

  .company p {
    width: 80%;
    margin: auto;
    margin-bottom: 13px;
  }

  .projectBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: auto;
  }

  .moreD .text-gray-medium {
    color: #444 !important;
    text-align: center;
  }

  .moreD p {
    font-size: 22px;
  }

  .moreD {
    border-bottom: 1px dotted rgb(172, 170, 170);
    padding-bottom: 60px;
  }
}

@media (max-width: 315px) {
    .company h2{
      font-size: 24px;
    }
    .company p{
      font-size: 13px;
    }
    .projectBtn2{
      font-size: 14px;
    }
    .moreD h2{
      font-size: 25px;
    }
    .moreD h3{
      font-size: 15px;
    }
}