.navbar {
  background-color: #fff;
  height: 65px;
  display: flex;
  font-family: monospace;
  align-items: center;
  justify-content: space-between;
  /* margin-left: 2rem; */
  /* width: 100%; */
  /* margin-right: 2rem; */
  padding: 10px 50px 0 50px;
}

.navbar-logo h2 {
  font-size: 20px;
  font-weight: bold;
  border: 1px solid #444;
  background: rgb(34, 193, 195);
  background: linear-gradient(
    0deg,
    rgba(34, 192, 195, 0.251) 0%,
    rgba(253, 187, 45, 0.251) 100%
  );
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
}

.shadow,
.shadow-sm {
  box-shadow: 0 0 22px #0000001a !important;
}

.text-gray-medium {
  color: #444 !important;
}

.navbar-logo img {
  /* width: 250px; */
  width: 110px;
}

.navbar-item ul li {
  list-style: none;
  cursor: pointer;
  margin-left: 15px;
  font-size: 20px;
}

.navbar-item ul {
  display: flex;
}

.navbar-item ul li a:hover {
  color: #18665c;
}

.navbar-item ul li a {
  color: inherit;
  transition: 0.3s;
}

.fixedNav {
  position: sticky;
  width: 100%;
  z-index: 9999;
  top: 0;
}

.navbar-item ul li a.active {
  color: #f18613;
  /* border-bottom: 2px solid #18665c; */
  width: 100%;
  border-radius: 2px;
  transition: 0.5s ease;
}

.navbar-item ul li a {
  transition: 0.5s ease;
  display: inline-flex;
}

.navbar-item ul li a {
  position: relative;
  padding: 0 10px;
}

.navbar-item ul li a::after {
  content: "";
  position: absolute;
  background-color: #f18613;
  height: 3px;
  width: 0;
  left: 0;
  bottom: -10px;
  transition: 0.3s;
}

.navbar-item ul li a:hover {
  color: #f18613;
}

.navbar-item ul li a:hover::after {
  width: 100%;
}

.hamburget-menu {
  display: none;
}

/* responsive css style */

@media (max-width: 980px) {
  .navbar-item {
    display: none;
  }

  .hamburget-menu {
    display: flex;
    font-size: 2.5rem;
    align-items: center;
    top: 0;
    position: absolute;
    right: 1rem;
    transition: 0.3s;
  }

  .hamburget-menu div:hover {
    color: #f18613;
  }

  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }

  .mobile-menu-link {
    background-color: white;
    height: 20rem;
    display: grid;
    grid-column: 2/5;
    align-items: center;
    padding-left: 3rem;
    transition: all 2s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile-menu-link ul {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start;
  }

  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

  .navbar-item {
    width: 100%;
    border-radius: 15px;
  }
}

@media (max-width: 390px) {
  .mobile-menu-link {
    padding-left: 0;
  }
}
