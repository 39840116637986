.unique-design-page {
    /* padding: 20px; */
}

.unique-packages-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 2rem;
}

.unique-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.unique-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.unique-close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5rem;
    color: #007bff;
    transition: color 0.3s ease;
}

.unique-close-modal:hover {
    color: #0056b3;
}

.unique-modal-content h2 {
    margin-bottom: 20px;
    font-size: 1.8rem;
    font-weight: bold;
}

.unique-modal-content form label {
    display: block;
    margin-bottom: 10px;
    font-size: 1rem;
}

.unique-modal-content form input,
.unique-modal-content form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.unique-modal-content button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.unique-modal-content button:hover {
    background-color: #0056b3;
}


.unique-package-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    margin: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
}

.unique-package-card:hover {
    transform: scale(1.01);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.unique-package-logo {
    max-width: 100px;
    margin-bottom: 15px;
    transition: transform 0.3s ease;
    border-radius: 8px;
}

.unique-package-card:hover .unique-package-logo {
    transform: scale(0.9);
}

.unique-package-card h3 {
    font-size: 1.5rem;
    margin: 10px 0;
    font-weight: bold;
}

.unique-package-card .unique-price,
.unique-package-card .unique-duration,
.unique-package-card .unique-benefits,
.unique-package-card .unique-description {
    margin: 5px 0;
    font-size: 1rem;
}

.unique-package-card button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.unique-package-card button:hover {
    background-color: #0056b3;
}

.unique-header {
    text-align: center;
    padding: 20px;
    background: linear-gradient(to right, #e07b12, #f8c6a0);
    color: white;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.unique-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: bold;
    color: #ffffff; /* Dark color for heading */
}

.unique-header p {
    font-size: 1.2rem;
    margin-bottom: 0;
    color: #e0e0e0; /* Light gray for paragraph */
}


/* modalStyles.css */
.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.ReactModal__Content {
    padding: 20px;
    max-width: 30%;
    margin: 5rem auto;
    background: #fff;
    border-radius: 8px;
    z-index: 9999999991991119;
    height: fit-content;
}


/* customStyles.css */

/* Card styling */
.unique-package-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    margin: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    width: 300px; /* Fixed width */
    height: 400px; /* Fixed height */
    overflow: hidden; /* To ensure content doesn't overflow */
}

.unique-package-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.unique-package-logo {
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.unique-card-header {
    font-size: 1.5rem;
    font-weight: bold;
    color: #18665c; /* Brand color */
}

.unique-card-meta {
    font-size: 1.1rem;
    color: #e07b12; /* Brand color */
}

.unique-card-description {
    font-size: 1rem;
    margin: 10px 0;
    text-align: left;
}

.unique-card-extra {
    font-size: 0.9rem;
    margin: 10px 0;
    text-align: left;
}

.unique-package-card {
    width: 300px; /* Fixed width */
    height: auto; /* Allow height to adjust based on content */
    overflow: hidden; /* Ensure content doesn't overflow */
    /* Other styles */
}


.unique-package-card {
    width: 100%; /* Full width */
    max-width: 400px; /* Fixed width */
    /* margin: 20px auto; Center the card */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Ensure content doesn't overflow */
}

.unique-package-logo {
    width: 100%; /* Full width */
    height: auto; /* Maintain aspect ratio */
    display: block;
    margin: 0 auto; /* Center the image */

}

.unique-card-header {
    font-size: 1.8rem;
    font-weight: bold;
}

.unique-card-meta {
    font-size: 1.2rem;
    color: #333;
}

.unique-card-description, .unique-card-extra {
    font-size: 1rem;
    margin-top: 10px;
}

.unique-card-button {
    background-color: #e07b12 !important;
    color: #fff;
    border: none;
    /* padding: 10px 20px; */
    padding: 10px 28px !important;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: opacity 0.3s ease;
    width: fit-content;
}
.button-container button{
    flex: none !important;
}

.unique-card-button:hover {
    opacity: 0.5;
}

.unique-link-button {
    background: none;
    color: #18665c;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1rem;
    margin-right: 10px;
}

.unique-link-button:hover {
    color: #004d40;
}

.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-container button {
    flex: 1;
    margin: 0 5px;
}

.ModalButtonStyle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}
.submitButtonStyle{
    background: #18665c !important;
}


/* Base styles */

/* Mobile devices in portrait and landscape mode */
@media only screen and (max-width: 600px) {
    /* Styles for mobile devices */
    .ReactModal__Content {
        max-width: 100%;
    }
    .unique-header h1 {
        font-size: 1.2rem;
    }
    
    .unique-header p {
        font-size: 0.9rem;
    }
}

/* Mobile devices in portrait mode */
@media only screen and (max-width: 480px) {
    /* Styles for mobile portrait mode */
    .ReactModal__Content {
        max-width: 100%;
    }
    .unique-header h1 {
        font-size: 1.2rem;
    }
    
    .unique-header p {
        font-size: 0.9rem;
    }
}

/* Mobile devices in landscape mode */
@media only screen and (max-width: 768px) and (orientation: landscape) {
    /* Styles for mobile landscape mode */
    .ReactModal__Content {
        max-width: 100%;
    }
    .unique-header h1 {
        font-size: 1.2rem;
    }
    
    .unique-header p {
        font-size: 0.9rem;
    }
}

/* Tablets and large mobile devices in portrait mode */
@media only screen and (max-width: 768px) {
    /* Styles for large mobile devices and small tablets */
    .ReactModal__Content {
        max-width: 100%;
    }
    .unique-header h1 {
        font-size: 1.2rem;
    }
    
    .unique-header p {
        font-size: 0.9rem;
    }
}

/* Very small mobile devices */
@media only screen and (max-width: 320px) {
    /* Styles for very small mobile devices */
    .ReactModal__Content {
        max-width: 100%;
    }
    .unique-header h1 {
        font-size: 1.2rem;
    }
    
    .unique-header p {
        font-size: 0.9rem;
    }
}

/* Retina displays */
@media only screen and (-webkit-min-device-pixel-ratio: 2), 
       screen and (min--moz-device-pixel-ratio: 2), 
       screen and (-o-min-device-pixel-ratio: 2/1), 
       screen and (min-device-pixel-ratio: 2) {
    /* Styles for high-resolution displays */
}

/* Example Styles */

/* Hiding Elements on Mobile */
@media only screen and (max-width: 600px) {
  
}

/* Adjusting Font Size for Mobile */
@media only screen and (max-width: 480px) {
 
}

/* Making Layout Adjustments */
@media only screen and (max-width: 768px) {

}

/* Changing Navigation Styles */
@media only screen and (max-width: 600px) {
 
}