.demo-page-container {
    /* margin: 20px; */
}

.header-segment {
    border-radius: 4px;
    margin-bottom: 20px;
    margin-top: 2rem;
}

.title-header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0;
}

.request-button-column {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.image-segment {
    margin-bottom: 20px;
}

/* .package-image {
    border-radius: 8px;
    width: 350px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.package-image {
    border-radius: 8px;
    /* width: 350px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 250px;
    background-size: 100% 100%;
    background-position: center;
    object-fit: contain;
    background-color: #f8f9fa;
}

.details-segment {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 20px;
}

.deomunique-card-button {
    background-color: #e07b12 !important;
    color: #fff;
    border: none;
    padding: 10px 28px !important;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: opacity 0.3s ease;
    width: fit-content;
}

.request-button-column button {
    flex: none !important;
}

.deomunique-card-button:hover {
    opacity: 0.7;
}

.DemoImagesStyle {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.whats-included-segment {
    margin-top: 20px;
}

.whats-included-segmentHeading {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.ui.table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
}

.ui.table thead th {
    background-color: #f4f4f4;
    border-bottom: 2px solid #ddd;
    padding: 10px;
    text-align: left;
    font-weight: bold;
}

.ui.table tbody td {
    border-bottom: 1px solid #ddd;
    padding: 10px;
}

.ui.table tbody tr:hover {
    background-color: #f9f9f9;
}

.ui.table tbody td:first-child {
    font-weight: bold;
}

.ui.table tbody td:last-child {
    font-style: italic;
}

.ui.table tbody tr:last-child td {
    border-bottom: none;
}

.whats-included-segmentHeading{
    text-align: start;
}

.share-button {
    background: transparent;
    border: none;
    color: #18665c;
    font-size: 1rem;
}

.share-button:hover {
    opacity: 0.5;
}

.share-options {
    display: none;
    position: absolute;
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 10px;
    border-radius: 4px;
    top: 50px; /* Adjust according to your layout */
    right: 0;
    width: fit-content;
    font-size: 11px;
}

.share-options.visible {
    display: block;
}

.share-icon {
    font-size: 1.5rem;
    margin: 0 5px;
    color: #333;
}

.share-icon:hover {
    color: #18665c;
}

.share-buttonStyleBox{
    position: relative;
}
.headingStyleDemo{
    display: flex;
    align-items: center;
    gap: 16px;
}
.backIconStyleDemo{
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;
    width: fit-content;
}

.details-segment p{
    margin-bottom: 5px;
}