.footers h5 {
    margin-bottom: 12px;
    color: #f18613;
}

.footers p {
    margin-bottom: 7px;
}

.footers .text-muted {
    color: #232f2d !important;
}

.footers .smLink {
    font-size: 22px;
    color: #f18613;
    cursor: pointer;
}

@media (max-width: 800px) {
    
}