.left {
    display: flex;
    width: 80%;
    margin: auto;
    margin-top: 8rem;
    flex: 1;

}

.bg-home {
    /* background-image: url("../img/bglogo2.png");
    background-repeat: no-repeat;
    width: 100%;
    object-fit: cover; */
    /* animation: rotation 2s infinite linear; */
}

/* .left p span {
    color: #f18613;
    font-weight: bold;
    z-index: 99999999;
    font-size: 20px;
} */

/* .img-bg-logo { */
/* position: absolute; */
/* z-index: -1; */
/* display: flex; */
/* width: 80%; */
/* justify-content: flex-end; */
/* align-self: flex-start; */
/* overflow-x: hidden; */
/* justify-content: center;
    align-items: center; */
/* float: right; */
/* right: 0; */
/* top: 15rem; */
/* left: 20%; */

/* animation: rotation 20s infinite linear; */
/* } */

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}


.home {
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
}

.right {
    display: flex;
    width: 80%;
    margin: auto;
    margin-top: 8rem;
    flex: 1;

}

.right .imgOver {
    width: 200px;
    object-fit: cover;
    position: absolute;
    bottom: 5rem;

}

.right img {
    width: 500px;
    border-radius: 15px;
}

.projectBtn i {
    text-align: center;
    align-items: center;
    padding-left: 10px;
}

.projectBtn {

    font-weight: 600;

    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 2px solid transparent;
    padding: .85rem 1.875rem;
    font-size: 1.125rem;
    line-height: 1.4;
    border-radius: 15px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}

.projectBtn:hover {
    color: #fff;
    background-color: #028984;
    border-color: #028984;
    box-shadow: none;
}

.projectBtn:hover i {
    animation: btnArrow infinite 1s linear;

}

@keyframes btnArrow {
    from {
        transform: translateX(5px);
    }

    to {
        transform: translateX(10px);
    }
}

.btn-primary {
    color: #fff;
    background-color: #00a9a2;
    border-color: #00a9a2;
    box-shadow: none;
}

.font-weight-black {
    font-weight: 900;
}

.text-20 {
    font-size: 1.25rem;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.text-gray-medium {
    color: #444 !important;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.text-20 {
    font-size: 20px;
    color: gray;
}

.mb-4 {
    margin: 0 0 24px;
}

.text-lg-left {
    text-align: left !important;
}

.display-4 {
    font-size: 3.5rem;
}

.mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
}

.justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}



@media (max-width: 995px) {

    .left,
    .right {
        margin-top: 5rem;
    }

    .left h1 {
        font-size: 2rem;
    }

    .left p {
        font-size: .9rem;
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 1rem !important;
    }

    .moreImg img {
        width: 257px;

    }

    .right img {
        width: 280px;
        height: 280px;
    }

    .mitDiv {
        animation: none;
        border-radius: 0;
    }

}

@media (max-width: 536px) {
    .right img {
        width: 220px;
        height: 220px;
    }

    .left h1 {
        font-size: 1.8rem;
    }

    .left p {
        font-size: .8rem;
    }

    .left .moreImg {
        margin-bottom: 2rem;
        width: 150px;
    }
}

@media (max-width: 390px) {


    .left {
        display: flex;
        width: 100%;
        margin: auto;
        margin-top: 4rem;
    }

    .right {
        display: flex;
        width: 100%;
        margin: auto;
        margin-top: 4rem;

    }

    .right img {
        width: 150px;
        height: 150px;
    }

    .left h1 {
        font-size: 1.2rem;
    }

    .left p {
        font-size: .6rem;
        margin-left: 5px;
    }

    .projectBtn {
        font-weight: 600;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        user-select: none;
        border: 2px solid transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
        padding: .8rem 1rem;
        font-size: 1rem;
        line-height: 0.4;
        border-radius: 15px;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        margin-left: 10px;
    }

    .moreImg img {
        margin-left: 10px;
    }


    .cardBox {
        margin-top: -2rem;
    }

    .left .moreImg img {
        margin-bottom: 2rem;
        width: 120px;
    }
}


@media (max-width: 340px) {
    .projectBtn {
        font-size: .6rem;
    }

    .left .moreImg img {
        margin-bottom: 2rem;
        width: 120px;
    }
}


.left .moreImg {
    width: 180px;
    cursor: pointer;
}


.ads-videoFor-web {
    padding: .10% 0 0 0;
    border-radius: 15px;
    position: relative;
    width: 100%;
    height: 100%;
   
}
.right{
    margin-top: 3rem;
}
.right iframe{
    border-radius: 15px;
}
/* .ads-videoFor-web iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
} */