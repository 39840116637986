/* body {
    background: linear-gradient(90deg, #0e3959 0%, #0e3959 30%, #03a9f5 30%, #03a9f5 100%);
} */

.contactFull {
    position: relative;
    width: 100%;
    padding: 40px 100px;
}

.map2 iframe{
    width: 100%;
    height: 400px;
}

.contactFull .title {
    display: flex;
    justify-content: center;
    font-size: 2em;
}

.title h2 {
    font-weight: bold;
    color: #18665c;
}

.form {
    grid-area: form;
}

.info {
    grid-area: info;
}

.map {
    grid-area: map;
}

.contact {
    padding: 40px;
    background-color: #fff;
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
}

.box {
    position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 5fr 4fr;
    grid-template-areas:
        "form info"
        "form map";
    grid-gap: 20px;
    margin-top: 20px;
}

.contact h3 {
    color: #18665c;
    font-weight: 500;
    font-size: 1.4em;
    margin-bottom: 10px;
}

.formBox {
    width: 100%;
    position: relative;
}

.formBox .formRow {
    display: flex;
    gap: 20px;
}

.inputBox {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 10px;
}

.formBox .formRow100 .inputBox {
    width: 100%;
}

.inputBox span {
    color: #f18613;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
}

.inputBox input {
    padding: 10px;
    font-size: 1.1em;
    outline: none;
    border: 1px solid #333;
    border-radius: 8px;
}

.inputBox textarea {
    padding: 10px;
    font-size: 1.1em;
    outline: none;
    border: 1px solid #333;
    resize: none;
    min-height: 220px;
    margin-bottom: 10px;
    border-radius: 8px;
}

.inputBox button[type="submit"] {
    background-color: #f18613;
    color: #fff;
    border: none;
    font-weight: bold;
    font-size: 1.2em;
    max-width: 120px;
    cursor: pointer;
    padding: 14px 15px;
    border-radius: 15px;
}

.inputBox ::placeholder {
    color: #999;
}

/* Info css */

.info {
    background-color: #0e3959;
}

.info h3 {
    color: #fff;
    margin-bottom: 15px;
}

.info .infoBox div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.info .infoBox div span {
    min-width: 40px;
    height: 40px;
    background-color: #f18613;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    border-radius: 50%;
    margin-right: 15px;
}

.info .infoBox div p {
    color: #fff;
    font-size: 1.1em;
    display: contents;
}

.info .infoBox div a {
    color: #fff;
    font-size: 1.1em;
    text-decoration: none;
}

.smi {
    margin-top: 40px;
    display: flex;
}

.smi li {
    list-style: none;
    margin-right: 15px;
}

.smi li a {
    color: #fef2e4;
    font-size: 2em;
}

.smi li a:hover {
    color: #f18613;
}

.map {
    padding: 0;

}

.map iframe {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

@media (max-width: 991px) {
    .contactFull {
        padding: 20px;
    }

    .box {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "form"
            "info"
            "map";
        grid-gap: 20px;
        margin-top: 20px;
    }

    .formBox .formRow {
        display: flex;
        gap: 0;
        flex-direction: column;
    }

    .inputBox {
        width: 100%;
    }

    .contact {
        padding: 30px;
    }

    .map {
        min-height: 300px;
        padding: 0;
    }
}



.contact-about {
    font-size: 1.2em;
}